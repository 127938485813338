import * as Yup from "yup";

export default Yup.object().shape({
    businessName: Yup.string()
        .required("Please enter a business name"),
    ein: Yup.string()
        .required("Please enter your EIN number"),
    primaryContactName: Yup.string()
        .required("Primay contact name is required"),
    secondaryContactName: Yup.string()
        .nullable(),
    phoneNumber: Yup.string()
        .required("Please enter your phone number"),
    emailAddress: Yup.string()
        .email('Please enter a valid email')
        .required("Email address is required"),
    // password: Yup.string().required("Please enter your password")
    // .min(8, "Minimum 8 characters")
});