// DEPENDENCIES
import React from 'react';
// import '@brainhubeu/react-carousel/lib/style.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// COMPONENTS
// import Carousel, { autoplayPlugin } from '@brainhubeu/react-carousel';
import { Carousel } from 'react-responsive-carousel';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const CarouselBlock = (props) => {

    return (
        <Carousel {...props}>
            {props.children}
        </Carousel>
    );
}

export default CarouselBlock;