// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import {Link} from 'react-router-dom';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentBanner from '../../components/ContentBanner';
import FloatingCard from '../../components/FloatingCard';
import IFrame from '../../components/IFrame';
import Image from '../../components/Image';
import Button from '../../components/Button';
// ASSETS
import StaffPhoto from '../../assets/img/content/photo_daryl-and-sons.jpg';
import TigersSeasonPhoto from '../../assets/img/content/photo_tigers-season-2020.jpg';
import FramedShelfPhoto from '../../assets/img/content/photo_framed-shelf.jpg';
import ShopDisplayFramesPhoto from '../../assets/img/content/photo_shop-display-frames.jpg';

const ServicesPage = (props) => {

    const sampleFramesVideo = "https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F688863458322798%2F&show_text=false&width=560";
    const shopPreviewVideo = "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F924675037962183%2F&show_text=false&width=560";

    const services = [
        {
            id: 1,
            name: 'Affordable Frames',
            image: 'https://images.pexels.com/photos/5797991/pexels-photo-5797991.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            description: 'Come visit us or ask us about our high quality, affordable frames!',
            buttonText: 'Visit Us',
            to: '/contact'
        },
        {
            id: 2,
            name: 'Custom Frames',
            image: 'https://images.pexels.com/photos/3975569/pexels-photo-3975569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            description: 'Looking to build a custom frame? We got you! Click the button below to get started.',
            buttonText: 'Get Started',
            to: '/get-started'
        },
        {
            id: 3,
            name: 'Latest Trends',
            image: 'https://images.pexels.com/photos/4490130/pexels-photo-4490130.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
            description: 'Ask us about the latest trends and newest styles of frame currently in the market!',
            buttonText: 'Contact Us',
            to: '/contact'
        },
    ];

    return (
        <CustomBlock className="">

            <ContentBanner
                title="Picture Framing Services"
                description="Our experienced professional framers can create beautiful wood frames for your photos, posters, and artwork. From natural wood to a wide variety of stains, antique metal corners to modern details, we frame your images of any size."
                image="banner-3.jpg"
                contentXPosition="right"
                buttonOneText="Get Started"
                buttonTwoText="View Our Pinterest"
                buttonOneClassName="primary--bg"
                buttonTwoClassName="secondary--bg"
                buttonOneTo="/get-started"
                buttonTwoOnClick={() => {
                  window.open('https://www.pinterest.com/darylandsonsbr/', '_blank');
                }}
                
            />

            <CustomBlock className="services-card-container">
                {
                    services.map((service, index) =>
                        <CustomBlock key={index} className="service-card">
                            <Link to={service.to}>
                                <CustomBlock
                                    className="image-block"
                                    style={{ background: `url(${service.image})` }}
                                >
                                </CustomBlock>
                            </Link>

                            <CustomBlock className="text-block">
                                <h3 className="title">
                                    {service.name}
                                </h3>
                                <p className="description">
                                    {service.description}
                                </p>

                                <Button
                                    text={service.buttonText}
                                    className="secondary--bg"
                                    to={service.to}
                                    size="xs"
                                />
                            </CustomBlock>
                        </CustomBlock>
                    )
                }

            </CustomBlock>

            <FloatingCard />

        </CustomBlock>
    );
}

export default ServicesPage;