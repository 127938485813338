// DEPENDENCIES
import React from 'react';

// COMPONENTS
import { Link } from 'react-router-dom';

// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

// ASSETS
import Logo from '../assets/svg/logo.svg';
import LogoIcon from '../assets/svg/icon.svg';

const LogoBlock = (props) => {

    return (
        <CustomBlock
            className={`logo-block-container ${props.className ? props.className : ""}`}
        >
            <img
                className={`logo ${props.imageClassName ? props.imageClassName : ""}`}
                src={props.type === "icon" ? LogoIcon : Logo}
                style={{ height: props.height ? props.height : 200, }}
            />
        </CustomBlock>
    );
}

export default LogoBlock;
