// DEPENDECIES
import React from 'react';
// COMPONENTS
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';


const ShippingInfoPage = (props) => {

    return (
        <Section removeContainer>
            <ContentHeader
                title="Shipping Information"
            />
            <ContentBlock>
                <InputBlock
                    inputLabel="Business Name"
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="First Name"
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="Last Name "
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="Address"
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="Address 2"
                />
            </ContentBlock>

            <ContentBlock cols={6}>
                <InputBlock
                    inputLabel="Zip Code"
                    mask="99999"
                />
            </ContentBlock>

            <ContentBlock cols={6}>
                <InputBlock
                    inputLabel="City"
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="State"
                />
            </ContentBlock>

            <ContentBlock>
                <InputBlock
                    inputLabel="Phone Number"
                    mask="(999) 999-9999"
                />
            </ContentBlock>
        </Section>
    );
}

export default ShippingInfoPage;