import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "MatColor/";

const getMatColors = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetMatColors', constants.REQUIRE_INTERCEPTORS);

const getMatColorById = async (id) => await axiosInstance.get(ENDPOINTORIGIN +'GetMatColorById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const saveMatColor = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveMatColor', model, constants.REQUIRE_INTERCEPTORS);

const deleteMatColor = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeleteMatColor?id='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    getMatColors,
    getMatColorById,
    saveMatColor,
    deleteMatColor
}