// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
// COMPONENTS
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
import { BlockPicker } from 'react-color'
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ModalBlock from '../../../../components/ModalBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
import FormBlock from '../../../../components/FormBlock';
// SERVICES AND HELPERS
import * as matColorService from '../../../../../services/product/matColorService';
import MatColorValidator from '../../../../../helpers/validators/MatColorValidator';
import * as helper from '../../../../../helpers/helper';
import constants from '../../../../../constants/constants';

const initialValues = {
    id: 0,
    name: '',
    number: '',
    color: '#000000'
}

const MatColorOptionsPage = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [matColorDeleteId, setMatColorDeleteId] = useState(null);
    const [matColorModel, setMatColorModel] = useState(initialValues);

    useEffect(() => {
        fetchMatColors();
    }, [])

    const columns = [
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                text="Edit"
                                className="light-purple--bg fit-content ml-0"
                                size="xs"
                                onClick={() => {
                                    setMatColorModel(data[dataIndex]);
                                    setModalVisible(true);
                                }}
                            />

                            <Button
                                text="Delete"
                                className="danger--bg"
                                size="xs"
                                onClick={() => {
                                    setConfirmDeleteModalVisible(true);
                                    setMatColorDeleteId(data[dataIndex].id)
                                }}
                            />
                        </CustomBlock>
                    )
                }
            }

        },
        {
            name: "name",
            label: "Mat Color",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false
            }
        },
        {
            name: "number",
            label: "Color Number",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false
            }
        },
        {
            name: "color",
            label: "Color",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <span style={{
                            height: 25,
                            width: 25,
                            backgroundColor: data[dataIndex].color,
                            borderRadius: '50%',
                            display: 'inline-block'
                        }}>

                        </span>
                    )
                }
            }
        }
    ];

    const formik = useFormik({
        initialValues: matColorModel,
        enableReinitialize: true,
        validationSchema: MatColorValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            saveMatColor(values, setSubmitting)
        },
    });

    const fetchMatColors = () => {
        setLoading(true);
        matColorService.getMatColors().then(res => {
            setData(res);
            setLoading(false);
        });
    }

    const saveMatColor = (values, setSubmitting) => {
        matColorService.saveMatColor(values).then(res => {
            fetchMatColors();
            swal("Success!", res.message, "success");
            handleModalClose();
        });
    }

    const deleteMatColor = () => {
        matColorService.deleteMatColor(matColorDeleteId).then(res => {
            fetchMatColors();
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(ex => {
            setConfirmDeleteModalVisible(false);
            setMatColorDeleteId(null);
        })
    }

    const handleModalClose = () => {
        setModalVisible(false);
        setMatColorModel(initialValues);
        formik.resetForm();
    }

    return (
        <>
            <CustomBlock className="content-container--padded light--bg">
                <Section>
                    <CustomBlock className="content-container--card-style">
                        <ContentHeader
                            title="Mat Color Options"
                            headerSize="lg"
                            primaryButtonText="Add New"
                            primaryButtonType="button"
                            primaryButtonOnClick={() => setModalVisible(true)}
                        />
                        <CustomBlock>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={{
                                    selectableRows: "none",
                                    download: false,
                                    print: false,
                                }}
                            />
                        </CustomBlock>
                    </CustomBlock>
                </Section>
            </CustomBlock>

            {/* CONFIRM DELETE MODAL */}
            <ModalBlock
                show={confirmDeleteModalVisible}
                contentHeader="Delete Mat Color"
                onHide={() => {
                    setConfirmDeleteModalVisible(false);
                    setMatColorDeleteId(null);
                }}
                closeAction
                centered
                contentDescription="Are you sure you want to delete this mat color option?"
                primaryModalActionOnClick={deleteMatColor}
            />

            {/* CREATE NEW ITEM MODAL */}
            <ModalBlock
                show={modalVisible}
                contentHeader={(matColorModel.id > 0 ? "Edit" : "Add New") + " Mat Color"}
                onHide={handleModalClose}
                backdrop="static"
                size="md"
                closeAction
                centered
            >
                <FormBlock className="test form" onSubmit={formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock>
                            <InputBlock
                                inputLabel="Mat Color Name"
                                errorMessage={formik.errors.name}
                                inputState={`${helper.getInputClasses(formik, 'name')}`}
                                {...formik.getFieldProps("name")}
                            />
                        </ContentBlock>
                        <ContentBlock>
                            <InputBlock
                                inputLabel="Color Number"
                                errorMessage={formik.errors.number}
                                inputState={`${helper.getInputClasses(formik, 'number')}`}
                                {...formik.getFieldProps("number")}
                            />
                        </ContentBlock>
                        <ContentBlock>
                            <BlockPicker
                                width="438px"
                                colors={constants.MAT_COLORS}
                                color={formik.values.color}
                                onChange={(val) => {
                                    formik.setFieldValue('color', val.hex);
                                }}
                            />
                        </ContentBlock>
                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={handleModalClose}
                            />
                            <Button
                                type="submit"
                                text={(matColorModel.id > 0 ? "Save Changes" : "Add ")}
                                className="primary--bg"
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
        </>
    );
}

export default MatColorOptionsPage;
