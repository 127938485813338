// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../components/CustomBlock';
import Image from '../components/Image';
import Button from '../components/Button';
// ASSETS

const ContentBanner = (props) => {

    const contentXPosition = {
        left: "left-content",
        right: "right-content"
    }

    const backgroundPosition = {
        top: "top",
        center: "center",
        bottom: "bottom",
        left: "left",
        right: "right"
    }

    return (
        <>
            <Fade
                triggerOnce
                duration={800}
                delay={400}
            >
                <CustomBlock className={`content-banner-container ${props.contentXPosition ? contentXPosition[props.contentXPosition] : ""}`}>
                    <CustomBlock
                        className="image-block"
                        style={{ background: `url(/content/${props.image})`, backgroundPosition: props.backgroundPosition ? backgroundPosition[props.backgroundPosition] : "center" }}
                    >
                    </CustomBlock>

                    <CustomBlock className="content-block">
                        <CustomBlock className="content-banner-text">
                            <h3 className={`title ${props.contentXPosition ? contentXPosition[props.contentXPosition] : ""}`}>
                                {props.title}
                            </h3>
                            <h4 className={`description ${props.contentXPosition ? contentXPosition[props.contentXPosition] : ""}`}>
                                {props.description}
                            </h4>
                        </CustomBlock>
                        {
                            props.buttonOneText || props.buttonTwoText ?
                                <CustomBlock className={`content-banner-buttons ${props.contentXPosition ? contentXPosition[props.contentXPosition] : ""}`}>

                                    {
                                        (props.buttonOneText !== null || '')
                                        &&
                                            <Button
                                                text={props.buttonOneText}
                                                className={props.buttonOneClassName}
                                                to={props.buttonOneTo}
                                                href={props.buttonOneHref}
                                                target={props.buttonOneTarget}
                                                onClick={props.buttonOneOnClick}
                                            />
                                    }
                                    {
                                        props.buttonTwoText ?
                                            <Button
                                                text={props.buttonTwoText}
                                                className={props.buttonTwoClassName}
                                                to={props.buttonTwoTo}
                                                href={props.buttonTwoHref}
                                                target={props.buttonTwoTarget}
                                                onClick={props.buttonTwoOnClick}
                                            /> : <></>
                                    }
                                </CustomBlock> : <></>
                        }
                    </CustomBlock>
                </CustomBlock>
            </Fade>
        </>
    );
}

export default ContentBanner;