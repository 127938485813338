// DEPENDENCIES
import React from 'react';
// COMPONENT
import { Link } from 'react-router-dom';

const ListItem = (props) => {

    return (
        <>
            <li className={`${props.className ? props.className : ""}`} style={props.style}>
                {props.iconleft}
                {
                    props.title ?
                        <Link
                            className={props.linkClassName ? props.linkClassName : ""}
                            to={props.to ? props.to : "#"}
                            onClick={props.onClick ? props.onClick : () => { }}
                        >
                            {props.title}
                        </Link> : <></>
                }

                {props.iconRight}

                {
                    props.icon ?
                        <Link
                            className={props.linkClassName ? props.linkClassName : ""}
                            to={props.to ? props.to : "#"}
                            onClick={props.onClick ? props.onClick : () => { }}
                        >
                            {props.icon}
                        </Link> : <></>
                }
            </li>
        </>
    );
}

export default ListItem;