// DEPENDECIES
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import { PrivateRoute } from './components/PrivateRoute';
import { PublicRoute } from './components/PublicRoute';
import { SharedRoute } from './components/SharedRoute';
import AdminRoute from './components/AdminRoute';
// AUTHENTICATE PAGES
import LoginPage from './pages/authenticate/LoginPage';
import RegisterPage from './pages/authenticate/RegisterPage';
import ForgotPasswordPage from './pages/authenticate/ForgotPasswordPage';
import SetupUserPasswordPage from './pages/authenticate/SetupUserPasswordPage';
// ADMIN USER PAGES
import UserListingPage from './pages/admin/user/UserListingPage';
import UserManagementPage from './pages/admin/user/UserManagementPage';
import AdminListingPage from './pages/admin/user/AdminListingPage';
// ADMIN SETTINGS PAGES
import SettingsPage from './pages/admin/settings/SettingsPage';
// ADMIN PRODUCT LISTING PAGES
import ProductListingPage from './pages/admin/product/ProductListingPage';
import ProductManagementPage from './pages/admin/product/ProductManagementPage';
import ProductOptions from './pages/admin/product/ProductOptions';
// PRODUCT MANAGEMENT
import MatColorOptionsPage from './pages/admin/product/_partials/MatColorOptionsPage';
import FrameOptionsPage from './pages/admin/product/_partials/FrameOptionsPage';
import PromoCodesPage from './pages/admin/product/_partials/PromoCodesPage';
// WEBSITE PAGES
import HomePage from './pages/website/Homepage';
import ServicesPage from './pages/website/ServicesPage';
import AboutPage from './pages/website/AboutPage';
import FAQPage from './pages/website/FAQPage';
import ContactPage from './pages/website/ContactPage';
import GetStartedPage from './pages/website/GetStartedPage';
// USER PAGES
import MyAccountPage from './pages/user/MyAccountPage';
import MyOrdersPage from './pages/user/MyOrdersPage';
// ECOMMERCE PAGES
import ProductsPage from './pages/ecommerce/ProductsPage';
import ProductDetailsPage from './pages/ecommerce/ProductDetailsPage';
import CartPage from './pages/ecommerce/CartPage';
import CheckOutPage from './pages/ecommerce/CheckOutPage';
import OrderDetailPage from './pages/ecommerce/OrderDetailPage';

const Routes = (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Switch>
            {/* PUBLIC ROUTES */}
            <Route
                path="/login"
                component={LoginPage}
                title="Login"
            />

            <Route
                path="/setup-password/:id?/:token?"
                component={SetupUserPasswordPage}
                title="Setup Password"
            />

            <Route
                path="/forgot-password"
                component={ForgotPasswordPage}
                title="Forgot Password"
            />

            <PublicRoute
                path="/register"
                component={RegisterPage}
                title="Register"
            />

            <PublicRoute
                path="/about"
                component={AboutPage}
                title="About"
            />

            <PublicRoute
                exact
                path="/"
                component={HomePage}
                title="Home"
            />

            <PublicRoute
                path="/frequently-asked-questions"
                component={FAQPage}
                title="FAQPage"
            />

            <PublicRoute
                path="/contact"
                component={ContactPage}
                title="Home"
            />

            <PublicRoute
                path="/services"
                component={ServicesPage}
                title="Services"
            />

            <PublicRoute
                path="/get-started"
                component={GetStartedPage}
                title="Get Started"
            />

            <PublicRoute
                path="/my-account/:id"
                component={MyAccountPage}
                title="Home"
            />

            {/* ADMIN ROUTE */}
            <AdminRoute
                path="/user-listing"
                component={UserListingPage}
                title="User Lising"
            />

            <AdminRoute
                path="/user-management/:id"
                component={UserManagementPage}
                title="User Manage"
            />

            <AdminRoute
                path="/admin-listing"
                component={AdminListingPage}
                title="User Lising"
            />

            <AdminRoute
                path="/user-management/:id"
                component={UserManagementPage}
                title="User Manage"
            />

            <AdminRoute
                path="/product-listing"
                component={ProductListingPage}
                title="Product Listing"
            />

            <AdminRoute
                path="/product-management/:id"
                component={ProductManagementPage}
                title="Product Management"
            />

            <AdminRoute
                path="/product-options"
                component={ProductOptions}
                title="Product Options"
            />

            <AdminRoute
                path="/mat-color-options"
                component={MatColorOptionsPage}
                title="Mat Colors"
            />

            <AdminRoute
                path="/frame-options"
                component={FrameOptionsPage}
                title="Frames"
            />

            <AdminRoute
                path="/promo-codes"
                component={PromoCodesPage}
                title="Promo Codes"
            />

            <AdminRoute
                path="/settings"
                component={SettingsPage}
                title="Settings page"
            />

            <AdminRoute
                path="/orders"
                component={MyOrdersPage}
                title="All Orders"
            />

            <PrivateRoute
                path="/order/detail/:orderNumber"
                component={OrderDetailPage}
                title="Order Detail"
            />

            {/* PRIVATE ROUTES */}

            <PrivateRoute
                path="/my-orders"
                component={MyOrdersPage}
                title="My Orders"
            />

            <PrivateRoute
                path="/my-order/detail/:orderNumber"
                component={OrderDetailPage}
                title="Order Detail"
            />

            <PublicRoute
                path="/frames"
                component={ProductsPage}
                title="Frames"
            />

            <PublicRoute
                path="/product/details/:id"
                component={ProductDetailsPage}
                title="Product Details"
            />

            <PublicRoute
                path="/cart"
                component={CartPage}
                title="Shopping Cart"
            />

            <PublicRoute
                path="/checkout"
                component={CheckOutPage}
                title="Check Out"
            />

            {/* SHARED ROUTES */}

            <SharedRoute />

        </Switch>
    );
}

export default Routes;
