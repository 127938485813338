export const actionTypes = {
    SetCounter: "SET_COUNTER",
    ResetCounter: "RESET_COUNTER"
};

const initialAlertState = {
    count: 0
};

export const reducer = (state = initialAlertState, action) => {
    switch (action.type) {
        case actionTypes.SetCounter: {
            return {
                count: action.count
            };
        }

        case actionTypes.ResetCounter: {
            return initialAlertState;
        }

        default:
            return state;
    }
};

export const actions = {
    setCounter: (count) => ({ type: actionTypes.SetCounter, count: count }),
    resetCounter: () => ({ type: actionTypes.ResetCounter }),
};
