import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Users/";

const login = async (user) => axiosInstance.post(ENDPOINTORIGIN + 'Authenticate', user, constants.REQUIRE_INTERCEPTORS);

const approveOrDenyUser = async (userId, approveUser) =>  axiosInstance.post(ENDPOINTORIGIN + 'ApproveOrDenyUser?userId='+ userId + '&isApproved=' + approveUser, constants.REQUIRE_INTERCEPTORS);

const getAllUsers = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetAllUser', constants.REQUIRE_INTERCEPTORS);

const getAllAdmins = async () => await axiosInstance.get(ENDPOINTORIGIN + 'GetAllAdmins', constants.REQUIRE_INTERCEPTORS);

const registerUser = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'Register', user, constants.REQUIRE_INTERCEPTORS);

const registerAdmin = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'RegisterAdmins', user, constants.REQUIRE_INTERCEPTORS);

const getUserById = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'GetUserById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const updateUser = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'Edit', user, constants.REQUIRE_INTERCEPTORS);

const updateAdmin = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'Edit', user, constants.REQUIRE_INTERCEPTORS);

const setupPassword = async (user) => await axiosInstance.post(ENDPOINTORIGIN + 'SetupPassword', user, constants.REQUIRE_INTERCEPTORS);

const changeUserPassword = async (userId, pwd) => await axiosInstance.post(ENDPOINTORIGIN + `ChangePassword/${userId}/${pwd}`, {}, constants.REQUIRE_INTERCEPTORS);

const deleteUser = async (userId) => await axiosInstance.get(ENDPOINTORIGIN + 'DeleteUser/' + userId, constants.REQUIRE_INTERCEPTORS);

const denyUser = async (user) => await axiosInstance.get(ENDPOINTORIGIN + 'ApproveOrDenyUser' + user, constants.REQUIRE_INTERCEPTORS);

const deleteUserByIsDelete = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteUserByIsDeletedFlag', model, constants.REQUIRE_INTERCEPTORS);

export {
    login,
    registerAdmin,
    approveOrDenyUser,
    getAllUsers,
    getAllAdmins,
    registerUser,
    setupPassword,
    getUserById,
    changeUserPassword,
    deleteUser,
    updateUser,
    updateAdmin,
    denyUser,
    deleteUserByIsDelete
}
