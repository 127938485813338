import * as Yup from "yup";

export default Yup.object().shape({
    businessName: Yup.string()
    .nullable(),
    ein: Yup.string()
    .nullable(),
    primaryContactName: Yup.string()
    .nullable(),
    secondaryContactName: Yup.string()
    .nullable(),
    phoneNumber: Yup.string()
    .nullable(),
    emailAddress: Yup.string()
    .email('Please enter a valid email')
    .required("Email address is required"),
    // password: Yup.string().required("Please enter your password")
    // .min(8, "Minimum 8 characters")
});