// DEPENDENCIES
import React, { useState, useEffect, useRef } from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { CgClose } from "react-icons/cg";
//COMPONENTS
import Select from 'react-select';
import { FilePond, File, registerPlugin } from 'react-filepond';

// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
import Image from "./Image";
import ModalBlock from "./ModalBlock";
import LogoTextAlt from '../assets/svg/logo-text-alt.svg';
import SelectBlock from './SelectBlock'
//STYLES
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileEncode
);


const FramePreviewModule = (props) => {
    const { frameOptions, matOptions } = props;
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [selectedMat, setSelectedMat] = useState(null);
    const [uploadedFile, setUploadedFile] = useState([]);

    const canvasRef = useRef(null);
    const frameImageRef = useRef(null);
    const uploadedImage = useRef(null);

    useEffect(() => {
        if (selectedMat) {
            let canvas = canvasRef.current;
            let frame = frameImageRef.current;
            var imgWidth = (canvas.width = frame.width);
            var imgHeight = (canvas.height = frame.height);
            var imgXOffset = (imgWidth / 100) * 18;
            var imgYOffset = (imgHeight / 100) * 18;

            let context = canvas.getContext('2d');
            context.clearRect(0.9, 0.9, frame.width, frame.height);
            context.drawImage(frame, 0, 0, frame.width, frame.height);

            context.globalCompositeOperation = 'destination-atop';
            context.fillStyle = selectedMat.value;
            context.fillRect(0.9, 0.9, frame.width, frame.height);

            context.fillStyle = '#dfdfdf';
            context.globalCompositeOperation = 'source-atop';
            context.fillRect(
                imgXOffset,
                imgYOffset,
                (frame.width / 100) * 64,
                (frame.height / 100) * 64
            );
        }
    }, [selectedMat]);

    useEffect(() => {
        let canvas = canvasRef.current;
        let frame = frameImageRef.current;
        var imgWidth = (canvas.width = frame.width);
        var imgHeight = (canvas.height = frame.height);
        var imgXOffset = (imgWidth / 100) * 18;
        var imgYOffset = (imgHeight / 100) * 18;

        if (uploadedFile.length > 0) {
            var img = uploadedImage.current;
            let tempFile = uploadedFile[0].file;
            let baseSixtyFour = uploadedFile[0].getFileEncodeBase64String();
            img.src = 'data:' + tempFile.type + ';base64,' + baseSixtyFour;
            let context = canvas.getContext('2d');
            context.drawImage(frame, 0, 0, frame.width, frame.height);

            context.globalCompositeOperation = 'destination-atop';
            context.fillStyle = selectedMat.value;
            context.fillRect(0.9, 0.9, frame.width, frame.height);

            context.globalCompositeOperation = 'source-atop';

            img.onload = function () {
                context.drawImage(
                    img,
                    imgXOffset,
                    imgYOffset,
                    (frame.width / 100) * 64,
                    (frame.height / 100) * 64
                );
            };
        }
    }, [uploadedFile]);

    const loadFrameImage = () => {
        let frame = frameImageRef.current;
        let canvas = canvasRef.current;

        var imgWidth = (canvas.width = frame.width);
        var imgHeight = (canvas.height = frame.height);

        let context = canvas.getContext('2d');
        context.globalCompositeOperation = 'destination-atop';
        context.drawImage(frame, 0, 0, frame.width, frame.height);
    };

    return (
        <CustomBlock style={{ display: 'flex', justifyContent: 'center', padding: 30 }} cols={8}>
            <CustomBlock>
                <img
                    src={selectedFrame ? selectedFrame.value : ''}
                    ref={frameImageRef}
                    style={{ display: 'none' }}
                    id="frame"
                    onLoad={loadFrameImage}
                />
                <div style={{ padding: 15 }}>
                    <img style={{ display: 'none' }} ref={uploadedImage} />
                    <canvas ref={canvasRef} id="canvas-frame-preview-result" />
                </div>
            </CustomBlock>
            <CustomBlock cols={4} style={{ width: 250 }}>
                <SelectBlock
                    inputLabel="Select Frame"
                    placeholder="Select an Option"
                    options={frameOptions}
                    onChange={setSelectedFrame}
                />

                <SelectBlock
                    inputLabel="Select Mat Color"
                    placeholder="Select an Option"
                    options={matOptions}
                    disabled={!selectedFrame}
                    onChange={setSelectedMat}
                />

                <FilePond
                    file={uploadedFile}
                    disabled={!selectedMat}
                    onupdatefiles={setUploadedFile}
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                    onremovefile={() => {
                        console.log("File has been removed")
                    }}
                />

            </CustomBlock>
        </CustomBlock>
    );
}

export default FramePreviewModule;