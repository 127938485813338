// DEPENDENCIES
import React from 'react';
// COMPONENTS
import IframeResizer from 'iframe-resizer-react';

const IFrame = (props) => {

    const iframeSize = {
        md: "--md",
        sm: "--sm"
    }

    return (
        <IframeResizer
            {...props}
            src={props.source}
            className={`iframe-component${props.size ? iframeSize[props.size] : ""} ${props.className ? props.className : ""}`}
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
    );
}

export default IFrame;