import store from "../../redux/store";

const isEnabled = (config, property) => config.hasOwnProperty(property) && config[property];

//handles requests going from app to server
const requestHandler = async (request) => {
    if (isEnabled(request, 'requestHandlerEnabled')) {
        const {
            auth: { authToken }
        } = store.getState();
        request.headers['Authorization'] = 'Bearer ' + authToken;
    }

    request.headers['Content-Type'] = !isEnabled(request, 'fileHandler') ? 'application/json' : 'multipart/form-data';
    request.headers['Access-Control-Allow-Methods'] = 'POST, GET, OPTIONS, DELETE';
    return request;
}

//handles responses coming from server to app
const successHandler = (response) => {
    if (isEnabled(response.config, 'responseHandlerEnabled')) {
        return response.data;
    }

    return response;
}

const errorHandler = async (response) => {
    if (isEnabled(response.config, 'responseHandlerEnabled')) {
        // console.log("I am handling error responses");
    }

    var message = "Something went wrong!";

    await Promise.resolve(response.response).then(res => {
        message = res ? res.data.Message : "Something went wrong on the server. Please contact the system administrator";

        if (res) {
            //if 401 redirect to login page
            if (res.status === 401) {
                localStorage.removeItem('persist:auth-token');
                window.location = window.origin + '/login';
                return;
            }
            //if not authorized redirect to dashboard
            if (res.status === 403) {
                window.location = window.origin + '/';
                return;
            }
        }

    });

    throw Error(message);
}

export {
    requestHandler,
    successHandler,
    errorHandler
}