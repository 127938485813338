// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// COMPONENTS
import { Route, Redirect } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { FaRegUser } from 'react-icons/fa';
import { BiShoppingBag } from 'react-icons/bi';
import { FiPackage, FiPhone } from 'react-icons/fi';
import { FaRegHeart, FaRegImages } from 'react-icons/fa';
import { GrCircleQuestion } from 'react-icons/gr';
import { HiOutlinePhone } from 'react-icons/hi';
// CUSTOM COMPONENTS
import NavBlock from '../components/NavBlock';
import FooterBlock from '../components/FooterBlock';
import CustomBlock from '../components/CustomBlock';
import Drawer from '../components/Drawer';
import ListContainer from '../components/ListContainer';
import ListItem from '../components/ListItem';
import Button from '../components/Button';
//HELPERS
import { primaryLinks, adminPrimaryLinks, secondaryLinks } from '../../helpers/helper';

export const PublicRoute = ({ component: Component, ...rest }) => {
    const {  user, isAdmin } = useSelector(
        ({ auth }) => ({
            user: auth.user,
            isAdmin: auth.isAdmin
        }),
        shallowEqual
    );

    const [drawerOpen, setDrawerOpen] = useState(false);
    const body = document.querySelector('body');


    const closeDrawer = () => {
        setTimeout(() => {
            setDrawerOpen(false);
            enableBodyScroll(body);
        }, 250)
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        if (!drawerOpen) {
            disableBodyScroll(body);
        } else {
            setTimeout(() => {
                enableBodyScroll(body);
            }, 500);
        }
    }

    return (
        <>
            <Route {...rest} render={props => {
                return (
                    <>
                        <NavBlock onClick={() => toggleDrawer()} />
                        <Drawer
                            title="Navigation"
                            position="left"
                            open={drawerOpen}
                            onClick={() => toggleDrawer()}
                            closeOnMaskPress
                        >

                            {/* <ListContainer className="drawer-links-container">
                                {
                                    isAdmin ?
                                        adminPrimaryLinks.map((userLink, index) =>
                                            <ListItem
                                                key={index}
                                                title={userLink.name}
                                                to={userLink.name === 'My Account' ? user && `${userLink.navigation}/${user.id}` : userLink.navigation}
                                                onClick={() => closeDrawer()}
                                                iconleft={userLink.iconleft}
                                                className="drawer-links"
                                            />
                                        )
                                        :
                                        primaryLinks.map((userLink, index) =>
                                            <ListItem
                                                key={index}
                                                title={userLink.name}
                                                to={userLink.name === 'My Account' ? user && `${userLink.navigation}/${user.id}` : userLink.navigation}
                                                onClick={() => closeDrawer()}
                                                iconleft={userLink.iconleft}
                                                className="drawer-links"
                                            />
                                        )
                                }
                            </ListContainer> */}

                            <ListContainer className="drawer-links-container main-links">
                                {
                                    secondaryLinks.map((mainLink, index) =>
                                        <ListItem
                                            key={index}
                                            title={mainLink.name}
                                            to={mainLink.navigation}
                                            onClick={() => closeDrawer()}
                                            iconleft={mainLink.iconleft}
                                            className="drawer-links"
                                        />
                                    )
                                }
                            </ListContainer>

                            <CustomBlock className="padding-horizontal">
                                <Button
                                    text="Sign In"
                                    to="/login"
                                    className="drawer-button"
                                />
                            </CustomBlock>
                        </Drawer>

                        <CustomBlock className="main-container">
                            <Component {...props} />
                            <FooterBlock />
                        </CustomBlock>
                    </>
                )
            }} />
        </>
    )
}