// DEPENDENCIES
import React, { useEffect, useState } from 'react';
// COMPONENTS
import { useFormik } from "formik";
import swal from "sweetalert";
import { connect } from "react-redux";
// ICONS
// CUSTOM COMPONENTS
import * as auth from '../../modules/Auth/_redux/authRedux';
import * as alert from '../../../redux/alertSnackBarRedux';
import RegistrationValidator from '../../../helpers/validators/RegistrationValidator';
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import FormBlock from '../../components/FormBlock';
import InputBlock from '../../components/InputBlock';
import Button from '../../components/Button';
//HELPERS & SERVICES
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';

const RegisterPage = (props) => {
    const { showAlert, history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [registrationModel, setRegistrationModel] = useState({
        businessName: '',
        ein: '',
        primaryContactName: '',
        secondaryContactName: '',
        phoneNumber: '',
        emailAddress: '',
        isApproved: false,
        isAdmin: false
    })

    const registerUser = (model, setSubmitting) => {
        userService.registerUser(model).then(res => {
            formik.resetForm();
            swal("Success!", res.message, "success");
            history.push('/');
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }

    const formik = useFormik({
        initialValues: registrationModel,
        validationSchema: RegistrationValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setIsLoading(true);
            registerUser({ ...values, isApproved: false, isAdmin: false }, setSubmitting);
        },
    });

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-padding light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Create Account"
                                    headerSize="md"
                                />
                                <FormBlock onSubmit={formik.handleSubmit}>
                                    <Section removeContainer>
                                        <ContentBlock cols={6}>
                                            <InputBlock
                                                id="businessName"
                                                inputLabel="Business Name"
                                                errorMessage={formik.errors.businessName}
                                                inputState={`${helper.getInputClasses(formik, 'businessName')}`}
                                                {...formik.getFieldProps("businessName")}
                                            />
                                        </ContentBlock>
                                        <ContentBlock cols={6}>
                                            <InputBlock
                                                id="ein"
                                                inputLabel="EIN Number"
                                                mask="99-9999999"
                                                errorMessage={formik.errors.ein}
                                                inputState={`${helper.getInputClasses(formik, 'ein')}`}
                                                {...formik.getFieldProps("ein")}
                                            />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                            <InputBlock
                                                id="primaryContactName"
                                                inputLabel="Primary Contact Name"
                                                errorMessage={formik.errors.primaryContactName}
                                                inputState={`${helper.getInputClasses(formik, 'primaryContactName')}`}
                                                {...formik.getFieldProps("primaryContactName")}
                                            />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                            <InputBlock
                                                id="secondaryContactName"
                                                inputLabel="Secondary Contact Name"
                                                placeholder="Optional"
                                                errorMessage={formik.errors.secondaryContactName}
                                                inputState={`${helper.getInputClasses(formik, 'secondaryContactName')}`}
                                                {...formik.getFieldProps("secondaryContactName")}

                                            />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                            <InputBlock
                                                id="emailAddress"
                                                inputLabel="Email Address"
                                                errorMessage={formik.errors.emailAddress}
                                                inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                                                {...formik.getFieldProps("emailAddress")}
                                            />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                            <InputBlock
                                                id="phoneNumber"
                                                inputLabel="Phone Number"
                                                mask="(999) 999-9999"
                                                errorMessage={formik.errors.phoneNumber}
                                                inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                                                {...formik.getFieldProps("phoneNumber")}
                                            />
                                        </ContentBlock>

                                        <CustomBlock className="content-container--actions">
                                            <Button
                                                type="submit"
                                                text="Submit"
                                                className="primary--bg"
                                            />
                                        </CustomBlock>
                                    </Section>
                                </FormBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
        </>
    );
}

export default connect(null, null)(RegisterPage);