// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { RiInstagramLine, RiFacebookCircleFill } from 'react-icons/ri';
// CUSTOM COMPONENTS
import Section from './Section';
import ContentBlock from './ContentBlock';
import CustomBlock from './CustomBlock';
import Button from './Button';
import ListContainer from './ListContainer';
import ListItem from './ListItem';
import Image from './Image';
import Logo from '../assets/svg/logo.svg';
// SERVICES AND HELPERS
import constants from '../../constants/constants';


const FooterBlock = (props) => {

  const helpfulLinks = [
    // { id: 1, name: 'Shop All', url: '/frames' },
    { id: 2, name: 'About', url: '/about' },
    { id: 2, name: 'Services', url: '/services' },
    // { id: 3, name: 'Ready-Made Frames', url: '/services' },
    // { id: 4, name: 'Custom Framing', url: '/services' },
    // { id: 5, name: 'Latest Trends', url: '/services' },
    // { id: 6, name: 'Returns', url: '/frequently-asked-questions' },
    { id: 7, name: 'Get Started', url: '/get-started' },
    { id: 7, name: 'Contact', url: '/contact' },
    // { id: 8, name: 'FAQs', url: '/frequently-asked-questions' },
  ]

  const customerServiceLinks = [
    // { id: 1, name: 'My Account', url: '/my-account' },
    // { id: 2, name: 'My Orders', url: '/my-orders' },
    { id: 3, name: 'Sign In', url: '/login' },
    // { id: 4, name: 'Register', url: '/register' },

  ];

  return (
    <CustomBlock className="footer-block-container">
      <Section removeContainer className="main-footer">
        <ContentBlock
          cols={9}
          className="footer-links-block"
        >
          <CustomBlock className="links-block footer-logo">
            <Image
              source={Logo}
              to="/"
            />
          </CustomBlock>

          <CustomBlock className="links-block hours-and-directions">
            <h4 className="footer-header">Directions</h4>
            <ListContainer>
              <ListItem
                title={constants.LOCATION}
                // to={{ pathname: constants.GOOGLEMAPS_URL }}
                // target="_blank"
                onClick={() => {
                  window.open(constants.GOOGLEMAPS_URL, 'blank');
                }}
              />
            </ListContainer>

            <h4 className="footer-header">Hours</h4>
            <ListContainer>
              <ListItem
                title="Monday-Friday: 8AM - 6PM"
              />

              <ListItem
                title="Saturday-Sunday: Closed"
              />
            </ListContainer>
          </CustomBlock>

          <CustomBlock className="links-block">
            <h4 className="footer-header">Links</h4>
            <ListContainer>
              {
                helpfulLinks.map((helpfulLink, index) =>
                  <ListItem
                    key={index}
                    title={helpfulLink.name}
                    to={helpfulLink.url}
                  />
                )
              }
            </ListContainer>
          </CustomBlock>

          <CustomBlock className="links-block">
            <h4 className="footer-header">Account</h4>
            <ListContainer>
              {
                customerServiceLinks.map((customerServiceLink, index) =>
                  <ListItem
                    key={index}
                    title={customerServiceLink.name}
                    to={customerServiceLink.url}
                  />
                )
              }
            </ListContainer>
          </CustomBlock>

        </ContentBlock>
        <ContentBlock
          cols={3}
          className="footer-contact-block"
        >
          <CustomBlock className="button-block">
            <h4 className="footer-header">Contact Us</h4>
            <CustomBlock>
              <Button
                text="Message Us"
                className="bordered-button"
                onClick={(e) => {
                  window.location.href = `mailto:${constants.EMAIL_ADDRESS}`;
                  e.preventDefault();
                }}
              />

              <Button
                text="Call Us"
                className="secondary--bg"
                onClick={(e) => {
                  window.location.href = `tel:${constants.PHONE_NUMBER}`;
                  e.preventDefault();
                }}
              />
            </CustomBlock>
          </CustomBlock>

          <CustomBlock className="social-media-block">
            <h4 className="footer-header">Follow Us</h4>
            <CustomBlock>
              <Link to={{ pathname: constants.INSTAGRAM_URL }} target="_blank">
                <RiInstagramLine />
              </Link>

              <Link to={{ pathname: constants.FACEBOOK_URL }} target="_blank">
                <RiFacebookCircleFill />
              </Link>
            </CustomBlock>
          </CustomBlock>
        </ContentBlock>
      </Section>

      <CustomBlock className="bottom-footer">
        <Link to="/">
          © 2024 Daryl and Sons Picture Framing
        </Link>

        <Link to={{ pathname: constants.DEVELOPER_URL }} target="_blank">
          Developed by Primtek
        </Link>
      </CustomBlock>
    </CustomBlock>
  );
}

export default FooterBlock;