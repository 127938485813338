// DEPENDENCIES
import React from 'react';

const Section = (props) => {

    return (
        <div
            className={`${props.removeContainer ? "" : "container"} ${props.fullWidth ? "container-fluid" : ""} ${props.className ? props.className : ""}`}
            style={props.containerStyle}
        >
            <div
                className={`row ${props.rowClassName ? props.rowClassName : ""}`}
                style={props.rowStyle}
            >
                {props.children}
            </div>
        </div>
    );
}

export default Section;