// ICONS
import { FaRegUser } from 'react-icons/fa';
import { BiShoppingBag } from 'react-icons/bi';
import { FiPackage, FiPhone, FiUsers, FiSettings, FiLayers } from 'react-icons/fi';
import { FaRegHeart, FaRegImages } from 'react-icons/fa';
import { GrCircleQuestion } from 'react-icons/gr';
import { HiOutlinePhone } from 'react-icons/hi'; 
import { CgKeyhole } from 'react-icons/cg';


const getInputClasses = (formik, fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
        return "error";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        return "success";
    }

    return "";
};

const convertJsonToFormData = (json) => {
    var form_data = new FormData();
    for (var key in json) {
        if (Array.isArray(json[key]))
            json[key].forEach(item => form_data.append(key, item));
        else
            form_data.append(key, (json[key] === null) ? "" : json[key]);
    }

    return form_data;
};

const primaryLinks = [

    // { id: 1, name: "My Account", iconleft: <FaRegUser size={25} />, navigation: `/my-account` },
    // { id: 2, name: "My Orders", iconleft: <FiPackage size={25} />, navigation: "/my-orders" },
]

const secondaryLinks = [
    { id: 1, name: "User Orders", iconleft: <BiShoppingBag size={25} />, navigation: "/user-orders" },
    { id: 1, name: "Shop All", iconleft: <BiShoppingBag size={25} />, navigation: "/frames" },
    { id: 1, name: "Services", iconleft: <FaRegImages size={25} />, navigation: "/services" },
    // { id: 4, name: "FAQs", iconleft: <GrCircleQuestion size={25} />, navigation: "/frequently-asked-questions" },
    { id: 2, name: "About Daryl and Sons", iconleft: <FaRegHeart size={25} />, navigation: "/about" },
    { id: 5, name: "Contact Us", iconleft: <HiOutlinePhone size={25} />, navigation: "/contact" },
]

const adminPrimaryLinks = [
    { id: 1, name: "My Account", iconleft: <FaRegUser size={23} />, navigation: `/my-account` },
    { id: 1, name: "Admins", iconleft: <CgKeyhole size={25} />, navigation: "/admin-listing" },
    { id: 1, name: "Users", iconleft: <FiUsers size={25} />, navigation: "/user-listing" },
    { id: 2, name: "Product Listing", iconleft: <FiPackage size={25} />, navigation: "/product-listing" },
    { id: 2, name: "Product Options", iconleft: <FiLayers size={25} />, navigation: "/product-options" },
    { id: 2, name: "Settings", iconleft: <FiSettings size={25} />, navigation: "/settings" },
]

export {
    getInputClasses,
    convertJsonToFormData,
    primaryLinks,
    secondaryLinks,
    adminPrimaryLinks
}