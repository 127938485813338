// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
// COMPONENTS
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { useFormik } from "formik";
import { connect } from "react-redux";
import swal from "sweetalert";
import { shallowEqual, useSelector } from "react-redux";
// ICONS
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
// CUSTOM COMPONENTS
import CustomBlock from '../../components/CustomBlock';
import LoginValidator from '../../../helpers/validators/LoginValidator'
import Logo from '../../assets/svg/logo.svg'
import Image from '../../components/Image';
import FormBlock from '../../components/FormBlock';
import LoadingScreen from '../../components/LoadingScreen';
import InputBlock from '../../components/InputBlock';
import * as alert from '../../../redux/alertSnackBarRedux';
import * as auth from '../../modules/Auth/_redux/authRedux';
import Button from '../../components/Button';
//SERVICES & HELPERS
import * as userService from '../../../services/management/userService'
import * as helper from '../../../helpers/helper';


const LoginPage = (props) => {
    const { isAuthorized, isAdmin } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user ? true : false,
            isAdmin: auth.isAdmin
        }),
        shallowEqual
    );

    const { login } = props;
    const [loading, setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    useEffect(() => {
        isAuthorized &&
            props.history.push('/')
    }, []);

    const loginUser = (model, setSubmitting) => {
        setLoading(true);
        userService.login(model).then(res => {
            login(res);
            props.history.push(res.isAdmin ? '/admin-listing' : '/');

        }).catch((ex) => {
            swal("Error!", ex.message, "error");
            setSubmitting(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    const formik = useFormik({
        initialValues: {
            emailAddress: "",
            password: "",
        },
        validationSchema: LoginValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            loginUser(values, setSubmitting)
        },
    });

    return (
        loading ? <LoadingScreen /> :
            <CustomBlock className="authenticate-page">
                <CustomBlock className="form-container">
                    <Fade
                        delay={800}
                        duration={800}
                    >
                        <CustomBlock className="logo-container">
                            <Link to="/">
                                <Image
                                    source={Logo}
                                />
                            </Link>
                        </CustomBlock>
                    </Fade>
                    <FormBlock onSubmit={formik.handleSubmit}>
                        <Fade
                            cascade
                            direction="up"
                            delay={1200}
                            duration={800}
                        >
                            <InputBlock
                                id="emailAddress"
                                placeholder="Email Address"
                                errorMessage={formik.errors.emailAddress}
                                inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                                {...formik.getFieldProps("emailAddress")}
                                iconLeft={
                                    <HiOutlineMail size={20} className="mid-grey--clr" />
                                }
                            />

                            <InputBlock
                                id="password"
                                type={passwordVisible ? "text" : "password"}
                                placeholder="Password"
                                errorMessage={formik.errors.password}
                                inputState={`${helper.getInputClasses(formik, 'password')}`}
                                {...formik.getFieldProps("password")}
                                iconLeft={
                                    <FiLock size={20} className="mid-grey--clr" />
                                }
                                iconRight={
                                    passwordVisible ? <FiEye className="light-purple--clr" /> : <FiEyeOff className="light-purple--clr" />
                                }
                                iconRightOnClick={() => setPasswordVisible(!passwordVisible)}
                            />

                            <CustomBlock className="button-container">
                                <Button
                                    text="Sign In"
                                    type="submit"
                                    size="sm"
                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                                    className={formik.isSubmitting || (formik.touched && !formik.isValid) ? "grey--bg w-100" : "secondary--bg w-100"}
                                />
                            </CustomBlock>
                        </Fade>
                    </FormBlock>

                    <Fade
                        delay={2800}
                        duration={800}
                        className="w-100"
                    >
                        <CustomBlock className="links-container">
                            <Link to="/">
                                Back to Homepage
                            </Link>

                            <Link to="/forgot-password">
                                Forgot Password?
                            </Link>
                        </CustomBlock>
                    </Fade>
{/* 
                    <Fade
                        delay={3400}
                        duration={800}
                        className="w-100"
                    >
                        <CustomBlock className="links-container register">
                            <Link to="/register" className="register-link">
                                No Account Yet? Sign up
                            </Link>
                        </CustomBlock>
                    </Fade> */}
                </CustomBlock>
            </CustomBlock>
    );
}

export default connect(null, { ...auth.actions, ...alert.actions })(LoginPage);