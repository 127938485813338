import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "ProductImage/";

const saveProductImages = async (product) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProductImages', product, {
    ...constants.REQUIRE_INTERCEPTORS,
    fileHandler: true
});

const deleteProductImage = async (id) => await axiosInstance.post(ENDPOINTORIGIN + 'DeleteProductImage?productImageId='+ id, {}, constants.REQUIRE_INTERCEPTORS)

const toggleImagePreview = async (id) => await axiosInstance.get(ENDPOINTORIGIN + 'ToggleImagePreview?imageId='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    saveProductImages,
    deleteProductImage,
    toggleImagePreview
}