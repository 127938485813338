// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
// COMPONENTS
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ModalBlock from '../../../../components/ModalBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FormBlock from '../../../../components/FormBlock';
import Image from '../../../../components/Image';
import Button from '../../../../components/Button';
// SERVICES AND HELPERS
import FrameValidator from '../../../../../helpers/validators/FrameValidator';
import * as frameService from '../../../../../services/product/frameService';
import * as helper from '../../../../../helpers/helper';
import constants from '../../../../../constants/constants';

const initialValues = {
    id: 0,
    name: '',
    filePath: '',
    file: [],
}

const FrameOptionsPage = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [frame, setFrame] = useState(initialValues)
    const [frames, setFrames] = useState([]);
    const [file, setFile] = useState([]);
    const [loading, setLoading] = useState(false);
    const [frameIdToDelete, setFrameIdTodelete] = useState(null);

    useEffect(() => {
        fetchFrames();
    }, [])

    const fetchFrames = () => {
        frameService.getFrames().then(res => {
            setFrames(res);
        });
    }

    const fetchFrame = (id) => {
        frameService.getFrameById(id).then(res => {
            setFrame(res);
            setModalVisible(true);
        })
    }

    const formik = useFormik({
        initialValues: frame,
        enableReinitialize: true,
        validationSchema: FrameValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (!frame.frameUrl) {
                if (file.length === 0) {
                    swal("Error!", "Please upload a png file", "error");
                    return;
                }

                if (file[0].fileExtension.toLowerCase() !== "png") {
                    swal("Error!", "Please upload a png file", "error");
                    return;
                }
            }

            setLoading(true);
            saveFrame(values);
        },
    });

    const saveFrame = (values) => {
        frameService.saveFrame(helper.convertJsonToFormData({ ...values, file: file.length > 0 ? file[0].file : null })).then(res => {
            fetchFrames();
            swal("Success!", res.message, "success");
            handleModalClose();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setLoading(false);
        })
    }

    const deleteFrame = () => {
        frameService.deleteFrame(frameIdToDelete).then(res => {
            fetchFrames();
            swal("Success!", res.message, "success");
            handleModalClose();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            handleDeleteModalClose();
            setLoading(false);
        })
    }

    const columns = [
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                text="Edit"
                                className="light-purple--bg fit-content ml-0"
                                size="xs"
                                onClick={() => fetchFrame(frames[dataIndex].id)}
                            />

                            <Button
                                text="Delete"
                                className="danger--bg"
                                size="xs"
                                onClick={() => {
                                    setFrameIdTodelete(frames[dataIndex].id);
                                    setConfirmDeleteModalVisible(true);
                                }}
                            />
                        </CustomBlock>
                    )
                }
            }

        },
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false
            }
        },
        {
            name: "frameUrl",
            label: "Frame Image",
            options: {
                print: true,
                download: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock style={{
                            height: 60,
                            width: 60,
                            background: `url(${constants.API_URL}Attachments/${frames[dataIndex].frameUrl})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center'
                        }}>
                        </CustomBlock>
                    )
                }
            }
        }
    ];

    const handleModalClose = () => {
        setFrame(initialValues);
        setFile([]);
        formik.resetForm();
        setModalVisible(false);
    }

    const handleDeleteModalClose = () => {
        setConfirmDeleteModalVisible(false);
        setFrameIdTodelete(null);
    }

    return (
        <>
            <CustomBlock className="content-container--padded light--bg">
                <Section>
                    <CustomBlock className="content-container--card-style">
                        <ContentHeader
                            title="Frame Options"
                            headerSize="lg"
                            primaryButtonText="Add New"
                            primaryButtonType="button"
                            primaryButtonOnClick={() => setModalVisible(true)}
                        />
                        <CustomBlock>
                            <MUIDataTable
                                data={frames}
                                columns={columns}
                                options={{
                                    selectableRows: "none",
                                    download: false,
                                    print: false,
                                }}
                            />
                        </CustomBlock>
                    </CustomBlock>
                </Section>
            </CustomBlock>

            {/* CONFIRM DELETE MODAL */}
            <ModalBlock
                show={confirmDeleteModalVisible}
                contentHeader="Delete Frame"
                onHide={handleDeleteModalClose}
                closeAction
                centered
                contentDescription="Are you sure you want to delete this frame color option?"
                primaryModalActionOnClick={deleteFrame}
            />

            {/* CREATE NEW ITEM MODAL */}
            <ModalBlock
                show={modalVisible}
                contentHeader={frame.id > 0 ? "Edit Frame" : "Add New Frame"}
                onHide={handleModalClose}
                backdrop="static"
                size="md"
                closeAction
                centered
            >
                <FormBlock className="test form" onSubmit={formik.handleSubmit}>
                    <Section removeContainer>
                        {
                            frame.frameUrl ?
                                <CustomBlock className="flex w-100 flex-column" styles={{ justifyContent: 'flex-end' }}>
                                    <CustomBlock className="flex w-100">
                                        <CustomBlock
                                            style={{
                                                height: 200,
                                                width: 250,
                                                background: `url(${constants.API_URL}Attachments/${frame.frameUrl})`,
                                                backgroundSize: 'contain',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center'
                                            }}>
                                        </CustomBlock>
                                    </CustomBlock>
                                    <CustomBlock className="flex w-100">
                                        <Button
                                            type="button"
                                            text={"Replace Image"}
                                            className="info--bg"
                                            onClick={() => {
                                                setFrame({ ...frame, frameUrl: '' });
                                            }}
                                        />
                                    </CustomBlock>
                                </CustomBlock>
                                :
                                <ContentBlock>
                                    <FileUploadBlock
                                        allowMultiple={false}
                                        acceptedFileTypes={['image/png']}
                                        labelIdle={'Drag & Drop PNG Image' + ' or <span class="filepond--label-action">Browse.</span>'}
                                        onupdatefiles={setFile}
                                    />
                                </ContentBlock>
                        }
                        <ContentBlock>
                            <InputBlock
                                inputLabel="Frame Name"
                                errorMessage={formik.errors.name}
                                inputState={`${helper.getInputClasses(formik, 'name')}`}
                                {...formik.getFieldProps("name")}
                            />
                        </ContentBlock>


                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={handleModalClose}
                            />

                            <Button
                                type="submit"
                                text={(frame.id > 0 ? "Save Changes" : "Add ")}
                                className="primary--bg"
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
        </>
    );
}

export default FrameOptionsPage;
