// DEPENDENCIES
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
// ICONS
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentBanner from '../../components/ContentBanner';
import FloatingCard from '../../components/FloatingCard';
import IFrame from '../../components/IFrame';
import Image from '../../components/Image';
import Button from '../../components/Button';
import FormBlock from '../../components/FormBlock';
import InputBlock from '../../components/InputBlock';
import TextAreaBlock from '../../components/TextAreaBlock';
import LoadingScreen from '../../components/LoadingScreen';
// ASSETS
import StaffPhoto from '../../assets/img/content/photo_daryl-and-sons.jpg';
import TigersSeasonPhoto from '../../assets/img/content/photo_tigers-season-2020.jpg';
import FramedShelfPhoto from '../../assets/img/content/photo_framed-shelf.jpg';
import ShopDisplayFramesPhoto from '../../assets/img/content/photo_shop-display-frames.jpg';
// SERVICES
import * as helper from '../../../helpers/helper';
import * as emailService from '../../../services/email/emailService';
import Overlay from '../../components/Overlay';

const initialform = {
  artworkOpeningDimensions: '',
  frame: '',
  mat: '',
  glazing: '',
  outerDimensions: '',
  name: '',
  phoneNumber: '',
  emailAddress: '',
  frameName: '',
};

const GetStartedPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orderForm, setOrderForm] = useState(initialform);

  const sampleFramesVideo = "https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F688863458322798%2F&show_text=false&width=560";
  const shopPreviewVideo = "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F924675037962183%2F&show_text=false&width=560";

  const services = [
    {
      id: 1,
      name: 'Affordable Frames',
      image: 'https://images.pexels.com/photos/5797991/pexels-photo-5797991.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
      description: 'Come visit us or ask us about our high quality, affordable frames!',
      buttonText: 'Visit Us',
      to: '/contact'
    },
    {
      id: 2,
      name: 'Custom Frames',
      image: 'https://images.pexels.com/photos/3975569/pexels-photo-3975569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
      description: 'Looking to build a custom frame? We got you! Click the button below to get started.',
      buttonText: 'Get Started',
      to: '/get-started'
    },
    {
      id: 3,
      name: 'Latest Trends',
      image: 'https://images.pexels.com/photos/4490130/pexels-photo-4490130.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
      description: 'Ask us about the latest trends and newest styles of frame currently in the market!',
      buttonText: 'Contact Us',
      to: '/contact'
    },
  ];

  const formik = useFormik({
    initialValues: orderForm,
    validationSchema: Yup.object().shape({
      artworkOpeningDimensions: Yup.string().required('Artwork Opening Dimensions is required').max(250),
      frame: Yup.string().required('Frame is required').max(250),
      mat: Yup.string().required('Mat is required').max(250),
      glazing: Yup.string().required('Mat is required').max(250),
      outerDimensions: Yup.string().required('Outer Dimensions is required').max(250),
      name: Yup.string().required('Name is required').max(250),
      phoneNumber: Yup.string().required('Phone Number is required').max(20),
      emailAddress: Yup.string().email().required('Email is required').max(250),
      frameName: Yup.string().max(500),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      sendEmail(values);
    },
  });

  const sendEmail = (model) => {
    setIsLoading(true);

    emailService.sendOrderEmail(model).then((res) => {
      setOrderForm(initialform);
      formik.resetForm();
      swal('Request Sent', "Your request has been successfully submitted", 'success');
    }).catch((ex) => {
      swal('Error!', "Something went wrong. Please try again.", 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <CustomBlock className="">
      {isLoading && <Overlay hasLoader />}
      <ContentBanner
        title="Get Started"
        description={
          <>
            <p><strong>1.</strong> Get started by using the design studio below.</p>
            <p><strong>2.</strong> Enter the opening dimensions for the width and height.</p>
            <p><strong>3.</strong> Browse the image you would like to use and start designing.</p>
            <p><strong>4.</strong> Click "Print" and save the document. Copy the information and enter all the details including your contact on the form below.</p>
            <p><strong>5.</strong> Alternatively, you may email us with the document at <span className="secondary--clr">darylandsons.br@gmail.com</span>.</p>
            {/* <p><strong>1.</strong> Click "Design Your Frame" to head to our supplier and design your frame.</p>
            <p><strong>2.</strong> Fill in the frame details below by using the design details of your frame.</p>
            <p><strong>3.</strong> Copy and paste each detail in the input fields.</p>
            <p><strong>4.</strong> Enter your contact information to complete the form and click "Send Request".</p> */}
            <br />
            <p>Once we receive your request, someone from our team will be in touch to finalize some details and discuss pricing. After that, we'll start building your frame!</p>
          </>
        }
        image="banner-5.jpg"
        contentXPosition="right"
        buttonOneText="Design Your Frame"
        buttonOneClassName="primary--bg"
        // buttonOneHref="https://www.larsonjuhl.com/en-US/lj-design-studio#maincontent"
        buttonOneOnClick={() => {
          window.open('https://www.larsonjuhl.com/en-US/lj-design-studio#maincontent', '_blank');
        }}
      />

      <CustomBlock>
        <iframe
          src="https://dev-designstudio.larsonjuhl.com//?site=en-us&createurl"
          width='100%'
          height='920'
        />
      </CustomBlock>

      <CustomBlock className="contact-form-container">
        <Fade
          triggerOnce
          direction="up"
          duration={800}
          className="contact-form-block"
        >
          <CustomBlock className="">
            <FormBlock>
              <h3 className="form-header" style={{ paddingTop: 20 }}>
                Frame Details
              </h3>

              <InputBlock
                inputLabel="Artwork Opening Dimensions"
                placeholder="Enter Artwork Opening Dimensions"
                containerClassName="input"
                backgroundColor="lighter--bg"
                bordered
                errorMessage={formik.errors.artworkOpeningDimensions}
                inputState={helper.getInputClasses(formik, 'artworkOpeningDimensions')}
                {...formik.getFieldProps('artworkOpeningDimensions')}
              />

              <TextAreaBlock
                id="frame"
                inputLabel="Frame"
                placeholder="Enter Frame"
                containerClassName="input"
                backgroundColor="lighter--bg"
                bordered
                errorMessage={formik.errors.frame}
                inputState={helper.getInputClasses(formik, 'frame')}
                {...formik.getFieldProps('frame')}
              />

              <CustomBlock className="contact-input-group">
                <TextAreaBlock
                  id="mat"
                  inputLabel="Mat"
                  placeholder="Enter Mat"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.mat}
                  inputState={helper.getInputClasses(formik, 'mat')}
                  {...formik.getFieldProps('mat')}
                />

                <TextAreaBlock
                  id="glazing"
                  inputLabel="Glazing"
                  placeholder="Enter Glazing"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.glazing}
                  inputState={helper.getInputClasses(formik, 'glazing')}
                  {...formik.getFieldProps('glazing')}
                />
              </CustomBlock>

              <CustomBlock className="contact-input-group">
                <TextAreaBlock
                  id="outerDimensions"
                  inputLabel="Finished Outer Dimensions"
                  placeholder="Enter Finished Outer Dimensions"
                  containerClassName="textarea"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.outerDimensions}
                  inputState={helper.getInputClasses(formik, 'outerDimensions')}
                  {...formik.getFieldProps('outerDimensions')}
                />
              </CustomBlock>

              <h2 className="form-header">
                Contact Information
              </h2>
              <CustomBlock className="contact-input-group">
                <InputBlock
                  inputLabel="Full Name"
                  placeholder="Your Full Name"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.name}
                  inputState={helper.getInputClasses(formik, 'name')}
                  {...formik.getFieldProps('name')}
                />
                <InputBlock
                  inputLabel="Phone Number"
                  placeholder="Your Phone Number"
                  mask="(999) 999-9999"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.phoneNumber}
                  inputState={helper.getInputClasses(formik, 'phoneNumber')}
                  {...formik.getFieldProps('phoneNumber')}
                />
              </CustomBlock>

              <CustomBlock className="contact-input-group">
                <InputBlock
                  inputLabel="Email Address"
                  placeholder="Your Email Address"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.emailAddress}
                  inputState={helper.getInputClasses(formik, 'emailAddress')}
                  {...formik.getFieldProps('emailAddress')}
                />
                <InputBlock
                  inputLabel="Frame Name"
                  placeholder="Frame Name"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.frameName}
                  inputState={helper.getInputClasses(formik, 'frameName')}
                  {...formik.getFieldProps('frameName')}
                />
              </CustomBlock>
              <CustomBlock className="button-container--flex-end">
                <Button
                  text="Send Request"
                  className="secondary--bg mr-1"
                  size="xs"
                  onClick={formik.handleSubmit}
                />
              </CustomBlock>
            </FormBlock>
          </CustomBlock>
        </Fade>
      </CustomBlock>

      {/* SERVICES BLOCK */}
      < CustomBlock className="services-card-container" >
        {
          services.map((service, index) =>
            <CustomBlock key={index} className="service-card">
              <Link to={service.to}>
                <CustomBlock
                  className="image-block"
                  style={{ background: `url(${service.image})` }}
                >
                </CustomBlock>
              </Link>

              <CustomBlock className="text-block">
                <h3 className="title">
                  {service.name}
                </h3>
                <p className="description">
                  {service.description}
                </p>

                {/* <Button
                  text="Shop Frames"
                  className="secondary--bg"
                  to={service.to}
                  size="xs"
                /> */}
              </CustomBlock>
            </CustomBlock>
          )
        }
      </CustomBlock>
    </CustomBlock>
  );
}

export default GetStartedPage;