// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import { useParams } from "react-router";
import swal from "sweetalert";
import jwtDecode from 'jwt-decode';
import * as auth from '../../../modules/Auth/_redux/authRedux';
// CUSTOM COMPONENTS
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import FormBlock from '../../../components/FormBlock';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import SelectBlock from '../../../components/SelectBlock';
import CheckboxBlock from '../../../components/CheckboxBlock';
import Button from '../../../components/Button';
// SERVICES AND HELPERS
import RegistrationValidator from '../../../../helpers/validators/RegistrationValidator';
import * as userService from '../../../../services/management/userService';
import UpdateAdminValidator from '../../../../helpers/validators/UpdateAdminValidator';
// ASSETS

const UserManagementPage = (props) => {
    const { isAdmin } = useSelector(
        ({ auth }) => ({
            isAdmin: auth.isAdmin,
        }),
        shallowEqual
    );

    const { id } = useParams();
    const { history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [userModel, setUserModel] = useState({});
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [userId, setUserId] = useState({});

    useEffect(() => {
        setUserId(id);
        userService.getUserById(id).then(res => {
            delete res.password;
            delete res.passwordSalt;
            delete res.userName;
            setUserModel(res);
        }).catch(err => {
        })
    }, []);

    const handleApproveOrDenyUser = (userId, isApproved, setSubmitting) => {
        setIsLoading(true);
        userService.approveOrDenyUser(userId, isApproved).then(res => {
            swal("Success!", res.data.message, "success");
            history.push({
                pathname: '/user-listing',
            });
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }

    const updateUser = (model, setSubmitting) => {
        userService.updateUser(model).then(res => {
            swal("Success!", res.message, "success");
            history.push({
                pathname: '/user-listing',
            });
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }
    const updateAdmin = (model, setSubmitting) => {
        userService.updateAdmin(model).then(res => {
            swal("Success!", res.message, "success");
            history.push({
                pathname: '/admin-listing',
            });
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: userModel,
        validationSchema: UpdateAdminValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            if (!userModel.isApproved) {
                handleApproveOrDenyUser(userId, true, setSubmitting);
            }
            else {
                if (userModel.isAdmin) {
                    updateAdmin({ ...values, isApproved: true, isAdmin: true }, setSubmitting);
                }
                else {
                    updateUser({ ...values, isApproved: true, isAdmin: false }, setSubmitting);
                }
            }

        }
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "error";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "success";
        }

        return "";
    };
    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-padding light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title={!userModel.isApproved ? isAdmin ? 'Account Information' : 'Approve User' : 'Account Information'}
                                    headerSize="lg"
                                />

                                <FormBlock onSubmit={formik.handleSubmit}>
                                    <Section removeContainer>
                                        {
                                            !userModel.isAdmin &&
                                            <>
                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        id="businessName"
                                                        inputLabel="Business Name"
                                                        errorMessage={formik.errors.businessName}
                                                        inputState={!isAdmin ? "disabled" : ""}
                                                        className="mid-grey--clr"
                                                        {...formik.getFieldProps("businessName")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        id="ein"
                                                        inputLabel="EIN Number"
                                                        mask="99-9999999"
                                                        errorMessage={formik.errors.ein}
                                                        inputState={!isAdmin ? "disabled" : ""}
                                                        className="mid-grey--clr"
                                                        {...formik.getFieldProps("ein")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={3}>
                                                    <InputBlock
                                                        id="primaryContactName"
                                                        inputLabel="Primary Contact Name"
                                                        errorMessage={formik.errors.primaryContactName}
                                                        inputState={`${getInputClasses('primaryContactName')}`}
                                                        {...formik.getFieldProps("primaryContactName")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={3}>
                                                    <InputBlock
                                                        id="secondaryContactName"
                                                        inputLabel="Secondary Contact Name"
                                                        placeholder="Optional"
                                                        errorMessage={formik.errors.secondaryContactName}
                                                        inputState={`${getInputClasses('secondaryContactName')}`}
                                                        {...formik.getFieldProps("secondaryContactName")}

                                                    />
                                                </ContentBlock>
                                            </>
                                        }

                                        <ContentBlock cols={userModel.isAdmin ? 6 : 3}>
                                            <InputBlock
                                                id="emailAddress"
                                                inputLabel="Email Address"
                                                errorMessage={formik.errors.emailAddress}
                                                inputState={`${getInputClasses('emailAddress')}`}
                                                {...formik.getFieldProps("emailAddress")}
                                            />
                                        </ContentBlock>

                                        {
                                            !userModel.isAdmin &&
                                            <ContentBlock cols={3}>
                                                <InputBlock
                                                    id="phoneNumber"
                                                    inputLabel="Phone Number"
                                                    mask="(999) 999-9999"
                                                    errorMessage={formik.errors.phoneNumber}
                                                    inputState={`${getInputClasses('phoneNumber')}`}
                                                    {...formik.getFieldProps("phoneNumber")}
                                                />
                                            </ContentBlock>
                                        }

                                        <CustomBlock className="button-container--flex-end w-100">
                                            <Button
                                                text={isAdmin ? !userModel.isApproved ? 'Deny' : 'Cancel' : 'Deny'}
                                                onClick={() => isAdmin ? !userModel.isApproved ?
                                                    handleApproveOrDenyUser(userId, false, formik.setSubmitting) :
                                                    history.goBack() :
                                                    handleApproveOrDenyUser(userId, false, formik.setSubmitting)
                                                }
                                                className="danger--bg fit-content"
                                            />
                                            <Button
                                                type="submit"
                                                text={isAdmin ? !userModel.isApproved ? 'Approve' : 'Save Changes' : 'Approve'}
                                                className="primary--bg mr-1"
                                            />

                                        </CustomBlock>
                                    </Section>
                                </FormBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
        </>
    );
}

export default connect(null, { ...auth.actions })(UserManagementPage);