// DEPENDENCIES
import React, { useEffect, useState, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { shallowEqual, useSelector } from "react-redux";
// COMPONENTS
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { FaRegUser } from 'react-icons/fa';
import { BiShoppingBag } from 'react-icons/bi';
import { FiPackage, FiPhone } from 'react-icons/fi';
import { FaRegHeart, FaRegImages } from 'react-icons/fa';
import { GrCircleQuestion } from 'react-icons/gr';
import { HiOutlinePhone } from 'react-icons/hi';
// CUSTOM COMPONENTS
import NavBlock from '../../components/NavBlock';
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import Banner from '../../components/Banner';
import ContentBanner from '../../components/ContentBanner';
import FloatingCard from '../../components/FloatingCard';
import Button from '../../components/Button';
import Drawer from '../../components/Drawer';
import ListContainer from '../../components/ListContainer';
import ListItem from '../../components/ListItem';
import Image from '../../components/Image';
import CardBlock from '../../components/CardBlock';
import CarouselBlock from '../../components/CarouselBlock';
// ASSETS
import Frame1 from '../../assets/img/content/frame-1.jpg';
import Frame2 from '../../assets/img/content/frame-2.jpg';
import Frame3 from '../../assets/img/content/frame-3.jpg';
import Frame4 from '../../assets/img/content/frame-4.jpg';
import MapLocationImage from '../../assets/img/content/map-location.png';
// SERVICES AND HELPERS
import constants from '../../../constants/constants';

const HomePage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [previewImageIndex, setPreviewImageIndex] = useState(-1);
  const body = document.querySelector('body');
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (isLoading) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
  }, []);

  const bestsellers = [
    {
      id: 1,
      // name: 'Dagny Frame',
      // price: '39.00',
      image: Frame1,
      previewImage: Frame1,
    },
    {
      id: 2,
      // name: 'Eliza Frame',
      // price: '68.00',
      image: Frame2,
      previewImage: Frame2,
    },
    {
      id: 3,
      // name: 'Zoe Frame',
      // price: '64.00',
      image: Frame3,
      previewImage: Frame3,
    },
    {
      id: 4,
      // name: 'Lizzy Frame',
      // price: '49.00',
      image: Frame4,
      previewImage: Frame4,
    },
  ]

  const reviews = [
    { id: 1, author: 'Brianna R.', review: 'They are always super helpful and knowledgeable. They have guided me through several framing projects at this point and have been thrilled with the results every single time.' },
    { id: 2, author: 'Masy C.', review: 'Very friendly staff and am super happy with their work! Will definitely be back!' },
    { id: 3, author: 'Rick N.', review: 'Great Family business. Really great work!' },
  ]

  return (
    <>
      {
        isLoading ? <LoadingScreen /> :
          <>
            <CustomBlock>
              <Fade
                triggerOnce
                duration={1000}
                delay={800}
              >
                <Banner
                  title="Expertly Crafted, High Quality, Affordable Frames."
                  titleSize="lg"
                  image="frame-bg.jpg"
                  contentXPosition="right"
                  bannerContentClassName="with-background"
                  buttonOneText="Get Started"
                  buttonOneClassName="secondary--bg"
                  buttonOneTo="/get-started"
                  buttonTwoText="Contact Us"
                  buttonTwoClassName="primary--bg"
                  buttonTwoTo="/contact"
                />
              </Fade>
            </CustomBlock>

            <CustomBlock className="frame-container">
              <CustomBlock className="inner-frame">
                <Section>
                  <Fade
                    triggerOnce
                    direction="up"
                    className="w-100"
                  >
                    <h3 className="section-quote--lg">
                      We frame your most treasured keepsakes and artworks with expert craftmanship and high quality materials.
                    </h3>
                  </Fade>

                  <Fade
                    triggerOnce
                    delay={800}
                    className="w-100"
                  >
                    <CustomBlock className="button-container">
                      <Button
                        text="Get Started"
                        to="/get-started"
                        className="primary--bg"
                        size="sm"
                      />

                      <Button
                        text="View Our Pinterest"
                        className="secondary--bg"
                        size="sm"
                        onClick={() => {
                          window.open('https://www.pinterest.com/darylandsonsbr/', '_blank');
                        }}
                      />
                    </CustomBlock>
                  </Fade>
                </Section>
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="content-section product-showcase">
              <Fade
                triggerOnce
                duration={800}
                delay={1000}
              >
                {/* <CustomBlock className="section-text-container">
                  <h3 className="section-text--lg header">
                    Our Bestsellers
                  </h3>
                </CustomBlock> */}
                <CustomBlock className="card-block-container">
                  {
                    bestsellers.map((bestseller, index) =>
                      <CardBlock
                        to="/frames"
                        imageSource={index === previewImageIndex ? bestseller.previewImage : bestseller.image}
                        onMouseEnter={() => setPreviewImageIndex(index)}
                        onMouseLeave={() => setPreviewImageIndex(-1)}
                      />
                    )
                  }
                </CustomBlock>
              </Fade>
            </CustomBlock>


            <ContentBanner
              title="Expertly Crafted Frames at Affordable Prices"
              description="Our experienced professional framers can create beautiful wood frames for your photos, posters, and artwork. From natural wood to a wide variety of stains, antique metal corners to modern details, we frame your images of any size at an affordable cost."
              image="banner-3.jpg"
              contentXPosition="left"
              buttonOneText="Read Story"
              buttonOneClassName="primary--bg"
              buttonOneTo="/about"
              buttonTwoText="View Services"
              buttonTwoClassName="secondary--bg"
              buttonTwoTo="/services"
            />


            {/* <CustomBlock style={{
              height: 600,
              width: '100%',
              backgroundImage: `url(${MapLocationImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',

            }} /> */}



            <ContentBanner
              title="Visit Us Today"
              description="Click 'Get Directions' to start navigating to our store or give us a call if you have any questions about our location, services or hours of operation."
              image="map-location.png"
              contentXPosition="right"
              buttonOneText="Get Directions"
              buttonOneClassName="primary--bg"
              buttonOneOnClick={() => {
                window.open('https://www.google.com/maps/dir//daryl+and+sons+framing/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x8626a44fa8ab550b:0x8d5b9cfeaaf46735?sa=X&ved=2ahUKEwiWrcyB09yBAxXqlWoFHTKoChIQ9Rd6BAhMEAA&ved=2ahUKEwiWrcyB09yBAxXqlWoFHTKoChIQ9Rd6BAhWEAU', '_blank');
              }}
              buttonTwoText="Give Us A Call"
              buttonTwoClassName="secondary--bg"
              buttonTwoOnClick={(e) => {
                window.location.href = `tel:${constants.PHONE_NUMBER}`;
                e.preventDefault();
              }}
            />



            <CustomBlock className="testimonials-container">
              <CustomBlock className="testimonials">
                <h2>
                  Our Reviews
                </h2>
                <CustomBlock>
                  <CarouselBlock
                    autoPlay
                    infiniteLoop
                    interval={4000}
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}

                  >
                    {
                      reviews.map((review, index) =>
                        <CustomBlock key={index}>
                          <p className="review">
                            {review.review}
                          </p>
                          <p className="author">
                            - {review.author}
                          </p>
                        </CustomBlock>
                      )
                    }
                  </CarouselBlock>
                  <CarouselBlock />
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>

            <FloatingCard />
          </>
      }
    </>
  );
}

export default HomePage;