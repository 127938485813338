import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Email/";

const sendContactEmail = async (model) => axiosInstance.post(ENDPOINTORIGIN + 'SendContactEmail', model, constants.REQUIRE_INTERCEPTORS);

const sendOrderEmail = async (model) => axiosInstance.post(ENDPOINTORIGIN + 'SendOrderEmail', model, constants.REQUIRE_INTERCEPTORS);

export {
    sendContactEmail,
    sendOrderEmail
}
