// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useFormik } from "formik";
// COMPONENTS
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
// ICONS
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import Button from '../../components/Button';
import SelectBlock from '../../components/SelectBlock';
import InputBlock from '../../components/InputBlock';
import Image from '../../components/Image';
import AlertModal from '../../components/AlertModal';
import FormBlock from '../../components/FormBlock';
import TotalsBlock from '../../components/TotalsBlock';
import ModalBlock from '../../components/ModalBlock';
// ASSETS
import SampleImage from '../../assets/img/content/banner-1.jpg';
import SampleImageTwo from '../../assets/img/content/banner-2.jpg';
import SampleImageThree from '../../assets/img/content/banner-3.jpg';
// SERVICES
import * as productService from '../../../services/product/productService';
import * as orderService from '../../../services/ecommerce/orderService';
// HELPERS
import * as cartCounter from '../../../redux/cartCounterRedux';
import constants from '../../../constants/constants';
import AddToCartValidator from '../../../helpers/validators/AddToCartValidator';
import * as helper from '../../../helpers/helper';
import FramePreviewModule from '../../components/FramePreviewModule';

const OrderDetailPage = () => {
    const [loading, setLoading] = useState(false);
    const { orderNumber } = useParams()
    const [order, setOrder] = useState({
        cart: {
            productCartItems: []
        }
    });

    useEffect(() => {
        fetchOrderData()
    }, [])

    const fetchOrderData = () => {
        setLoading(true);
        orderService.getOrderDetails(orderNumber).then(res => {
            setOrder(res.orderDetails);
        }).finally(x => {
            setLoading(false)
        });
    }

    return (
        loading ? <LoadingScreen /> :

            <CustomBlock className="content-container--padded light--bg">
                <Section>
                    <CustomBlock>
                        <ContentHeader
                            title={"Order: #" + orderNumber}
                            className="cart-header pb-0"
                        />
                    </CustomBlock>

                    {
                        order.cart.productCartItems.map((item, index) => (
                            <CustomBlock key={item.id} className="content-container--card-style mt-4">
                                <CustomBlock className="cart-item-container">

                                    {/* ITEM DETAILS */}
                                    <CustomBlock className="item-details-block">

                                        <CustomBlock className="image-block">
                                            <Image
                                                source={constants.API_URL + 'Attachments' + item.frameUrl}
                                            />
                                        </CustomBlock>

                                        <CustomBlock className="item-details">
                                            <CustomBlock>
                                                <ContentHeader
                                                    title={item.name}
                                                    headerSize="md"
                                                    className="pb-2"
                                                />
                                            </CustomBlock>

                                            <CustomBlock>
                                                <ContentHeader
                                                    title={'$' + item.price}
                                                    headerSize="md"
                                                />
                                            </CustomBlock>

                                            <CustomBlock className="mb-3">
                                                <ContentHeader
                                                    title="Mat Style"
                                                    headerSize="sm"
                                                    subtitle={item.matColor}
                                                    subtitleClassName="pt-2"
                                                />
                                            </CustomBlock>

                                            <CustomBlock className="mb-3">
                                                <ContentHeader
                                                    title="Frame Size"
                                                    headerSize="sm"
                                                    subtitle={item.size}
                                                    subtitleClassName="pt-2"
                                                />
                                            </CustomBlock>

                                            <CustomBlock>
                                                <ContentHeader
                                                    title="Additional Instructions"
                                                    headerSize="sm"
                                                    subtitle={item.additionalInstructions ? item.additionalInstructions : "-"}
                                                    subtitleClassName="pt-2"
                                                />
                                            </CustomBlock>
                                        </CustomBlock>

                                    </CustomBlock>
                                </CustomBlock>

                            </CustomBlock>
                        ))
                    }
                    {
                        order.cart.promoCode &&
                        <CustomBlock className="content-container--actions">
                            <InputBlock
                                backgroundColor="white--bg"
                                placeholder="Enter Promo Code"
                                value={order.cart.promoCode}
                                disabled={true}
                            />
                        </CustomBlock>
                    }
                    {
                        order.cart && <TotalsBlock
                            lineOneCost={order.cart.productCartItems.reduce((a, v) => a + v.quantity, 0)}
                            lineTwoCost={"$" + order.totalAmountPaid}
                            lineThreeTitle={"Discounts"}
                            lineThreeCost={order.cart.discountApplied ? " - $" + ((order.cart.discountApplied / 100) * order.cart.productCartItems.reduce((a, v) => a + v.product.quantity * v.product.price, 0)).toFixed(2) : "-"}
                            mainLineCost={"$" + order.totalAmountPaid}
                        />
                    }
                </Section>
            </CustomBlock>
    )
}

export default OrderDetailPage;