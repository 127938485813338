// DEPENDECIES
import React from 'react';

const ListContainer = (props) => {

    return (
        <>
            {
                props.orderedList ?
                    <ol className={`list-container ${props.className ? props.className : ""}`}>
                        {props.children}
                    </ol>
                    :
                    <ul className={`list-container ${props.className ? props.className : ""}`}>
                        {props.children}
                    </ul>
            }
        </>
    )
}

export default ListContainer;