// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const QAndABlock = (props) => {
    const {questions} = props;
    const {question, answer, buttonText, action} = questions;

    return (
        <>
            <CustomBlock className="question-and-answer-block">
                <CustomBlock className="content-container--card-style">
                    <h3 className="question">
                        {question}
                    </h3>
                    <p className="answer">
                        {answer}
                    </p>
                    {
                        buttonText &&
                        <CustomBlock className="button-container">
                            <Button
                                {...props}
                                text={buttonText}
                                className={`fit-content ${props.buttonClassName}`}
                                to={action ? action : '#'}
                            />
                        </CustomBlock>
                    }
                </CustomBlock>
            </CustomBlock>
        </>
    )
}

export default QAndABlock;