// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// COMPONENTS
import { Route, Redirect } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// ICONS
import { FaRegUser } from 'react-icons/fa';
import { BiShoppingBag } from 'react-icons/bi';
import { FiPackage, FiPhone } from 'react-icons/fi';
import { FaRegHeart, FaRegImages } from 'react-icons/fa';
import { GrCircleQuestion } from 'react-icons/gr';
import { HiOutlinePhone } from 'react-icons/hi';
// CUSTOM COMPONENTS
import NavBlock from '../components/NavBlock';
import FooterBlock from '../components/FooterBlock';
import CustomBlock from '../components/CustomBlock';
import Drawer from '../components/Drawer';
import ListContainer from '../components/ListContainer';
import ListItem from '../components/ListItem';
import Button from '../components/Button';

export const SharedRoute = ({ component: Component, ...rest }) => {
    const { isAuthorized, isAdmin, user } = useSelector(
        ({ auth }) => ({
            user: auth.user,
            isAuthorized: auth.user ? true : false,
            isAdmin: auth.isAdmin
        }),
        shallowEqual
    );

    const [drawerOpen, setDrawerOpen] = useState(false);
    const body = document.querySelector('body');

    const userLinks = [
        { id: 1, name: "My Account", iconleft: <FaRegUser size={25} />, navigation: `/my-account/${user.id}`  },
        { id: 2, name: "My Orders", iconleft: <FiPackage size={25} />, navigation: "/my-orders" },
    ]

    const mainLinks = [
        { id: 1, name: "Shop All", iconleft: <BiShoppingBag size={25} />, navigation: "/frames" },
        { id: 2, name: "Services", iconleft: <FaRegImages size={25} />, navigation: "/services" },
        { id: 3, name: "About Daryl and Sons", iconleft: <FaRegHeart size={25} />, navigation: "/about" },
        { id: 4, name: "FAQs", iconleft: <GrCircleQuestion size={25} />, navigation: "/frequently-asked-questions" },
        { id: 5, name: "Contact Us", iconleft: <HiOutlinePhone size={25} />, navigation: "/contact" },
    ]

    const closeDrawer = () => {
        setTimeout(() => {
            setDrawerOpen(false);
            enableBodyScroll(body);
        }, 250)
    }

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
        if (!drawerOpen) {
            disableBodyScroll(body);
        } else {
            setTimeout(() => {
                enableBodyScroll(body);
            }, 500);
        }
    }


    return (
        <>
            {isAuthorized ?
                <Route {...rest} render={props => {

                    return (
                        <>
                            <NavBlock onClick={() => toggleDrawer()} />
                            {  console.log('here', userLinks)}
                            <Drawer
                                title="Navigation"
                                position="left"
                                open={drawerOpen}
                                onClick={() => toggleDrawer()}
                                closeOnMaskPress
                            >
                                       
                                <ListContainer className="drawer-links-container">
                                    {
                                        userLinks.map((userLink, index) =>

                                      
                                            <ListItem
                                                key={index}
                                                title={userLink.name}
                                                to={userLink.navigation}
                                                onClick={() => closeDrawer()}
                                                iconleft={userLink.iconleft}
                                                className="drawer-links"
                                            />
                                    
                                        )
                                    }
                                </ListContainer>

                                <ListContainer className="drawer-links-container main-links">
                                    {
                                        mainLinks.map((mainLink, index) =>
                                            <ListItem
                                                key={index}
                                                title={mainLink.name}
                                                to={mainLink.navigation}
                                                onClick={() => closeDrawer()}
                                                iconleft={mainLink.iconleft}
                                                className="drawer-links"
                                            />
                                        )
                                    }
                                </ListContainer>

                                <CustomBlock className="padding-horizontal">
                                    <Button
                                        text="Sign In"
                                        to="/login"
                                        className="drawer-button"
                                    />
                                </CustomBlock>
                            </Drawer>

                            <CustomBlock className="main-container">
                                <Component {...props} />
                                <FooterBlock />
                            </CustomBlock>
                        </>
                    )
                }} />
               : <Redirect to="/login" />
              }
        </>
    )
}