// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { connect } from "react-redux";
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { FiMenu } from 'react-icons/fi';
import { IoBagOutline } from 'react-icons/io5'
// CUSTOM COMPONENTS
import CustomBlock from '../components/CustomBlock';
import ListContainer from '../components/ListContainer';
import ListItem from '../components/ListItem';
import Image from '../components/Image';
// ASSETS
import LogoTextAlt from '../assets/svg/logo-text-alt.svg';
// SERVICES AND HELPERS
import * as auth from '../modules/Auth/_redux/authRedux';
import * as cartCounter from '../../redux/cartCounterRedux';
import * as cartService from '../../services/ecommerce/cartService';

const NavBlock = (props) => {
  const { isAuthorized, isAdmin } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user ? true : false,
      isAdmin: auth.isAdmin,
      user: auth.user

    }),
    shallowEqual
  );
  const { logout, cartCounter, setCounter } = props;

  useEffect(() => {
    if (isAuthorized) {
      let tempArr = [...links];
      let signInBtn = tempArr.find(x => x.name == "Sign In");
      if (signInBtn) {
        signInBtn.name = "Sign Out";
        signInBtn.navigation = "/login";
        signInBtn.btnClick = () => {
          logout();
          localStorage.removeItem('persist:auth-token');

        }
        setLinks(tempArr);
      }
      getCartCounter();
    }
  }, [isAuthorized])

  const getCartCounter = () => {
    cartService.getNumberOfItemsInCart().then(res => {
      setCounter(res)
    })
  }

  const [links, setLinks] = useState([
    // { id: 1, name: "Shop All", navigation: "/frames" },
    // { id: 2, name: "Get Started", navigation: "/services", btnClick: () => window.open('https://www.larsonjuhl.com/en-US/lj-design-studio#maincontent', '_blank') },
    { id: 2, name: "Get Started", navigation: "/get-started" },
    // { id: 3, name: "Sign In", navigation: "/login" },
  ])

  return (
    <>

      <CustomBlock className="nav-block-container">
        <Link
          to={props.to ? props.to : "#"}
          onClick={props.onClick ? props.onClick : () => { }}
          className="menu-block"
        >
          <FiMenu size={25} className="light-gold--clr" />
          <p className="menu-text">Menu</p>
        </Link>

        <Link
          to={props.to ? props.to : "/"}
          className="logo-block"
        >
          <Image
            source={LogoTextAlt}
          />
        </Link>

        <CustomBlock className="links-block">
          <ListContainer>
            {
              links.map((link, index) =>
                <ListItem
                  key={index}
                  title={link.name}
                  to={link.navigation}
                  className="links"
                  onClick={() => link.btnClick ? link.btnClick() : {}}
                />
              )
            }
            {/* <ListItem
                            to="/cart"
                            icon={
                                <>
                                    <IoBagOutline size={20} />
                                    {cartCounter.count > 0 && <span>{cartCounter.count}</span>}
                                </>
                            }
                        /> */}
          </ListContainer>
        </CustomBlock>

      </CustomBlock>
    </>
  );
}

const mapStateToProps = (state) => ({
  cartCounter: state.cartCounter
});

export default connect(mapStateToProps, { ...auth.actions, ...cartCounter.actions })(NavBlock);