import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Settings/";

const getSettings = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetSettings', constants.REQUIRE_INTERCEPTORS);

const getSettingById = async (id) => await axiosInstance.get(ENDPOINTORIGIN +'GetSettingById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const getSettingByKey = async (key) => await axiosInstance.get(ENDPOINTORIGIN +'GetSettingByKey?key=' + key, constants.REQUIRE_INTERCEPTORS);

const saveSetting = async (setting) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveSetting', setting, constants.REQUIRE_INTERCEPTORS);

const deleteSetting = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeleteSetting?id='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    getSettings,
    getSettingById,
    getSettingByKey,
    saveSetting,
    deleteSetting
}