// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
import { HiCheck, HiOutlineMail } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
// CUSTOM COMPONENTS
import * as auth from '../../../modules/Auth/_redux/authRedux';
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentHeader from '../../../components/ContentHeader';
import Button from '../../../components/Button';
import ModalBlock from '../../../components/ModalBlock';
import InputBlock from '../../../components/InputBlock';
// SERVICES AND HELPERS
import * as userService from '../../../../services/management/userService';

const UserListingPage = (props) => {
    const { showAlert, history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        userService.getAllUsers().then(res => {
            setUsers(res);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }, [history]);

    const columns = [
        {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>

                            {/* <Button
                                className="light-purple--bg fit-content ml-0"
                                text="Edit"
                                size="xs"
                                to={"/user-management/" + users[dataIndex].id}
                            /> */}

                            <Button
                                className="light-purple--bg fit-content ml-0"
                                text="View"
                                size="xs"
                                to={"/user-management/" + users[dataIndex].id}
                            />
                            
                            {/* <Button
                                className="danger--bg fit-content"
                                text="Delete"
                                size="xs"
                                onClick={handleDeleteUser}
                                to={"/user-management/" + users[dataIndex].id}
                            /> */}


                        </CustomBlock>

                    );
                }
            }
        },

        {
            name: "businessName",
            label: "Business Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "emailAddress",
            label: "Email Address",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "isApproved",
            label: "Approved?",
            options: {
                filter: false,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {users[dataIndex].isApproved ? <HiCheck size={25} className="success--clr" /> : <CgClose size={25} className="danger--clr" />}
                        </>
                    );
                }
            }
        },
    ];

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Businesses"
                                    headerSize="lg"
                                    // primaryButtonText="Add User"
                                    primaryButtonOnClick={() => setModalVisible(true)}
                                />
                                <ContentBlock>
                                    <MUIDataTable
                                        data={users}
                                        columns={columns}
                                        options={{
                                            selectableRows: "none"
                                        }}
                                    />
                                </ContentBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
            <ModalBlock
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                contentHeader="Add New User"
                closeAction
                centered
            >
                <Section removeContainer>
                    <ContentBlock>
                        <InputBlock
                            id="email"
                            inputLabel="Email Address"
                            // errorMessage={formik.errors.emailAddress}
                            // inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                            // {...formik.getFieldProps("emailAddress")}
                            iconLeft={
                                <HiOutlineMail size={20} className="mid-grey--clr" />
                            }
                        />
                    </ContentBlock>

                    <ContentBlock>
                        <InputBlock
                            id="password"
                            type={passwordVisible ? "text" : "password"}
                            inputLabel="Password"
                            iconLeft={
                                <FiLock size={20} className="mid-grey--clr" />
                            }
                            iconRight={
                                passwordVisible ? <FiEye className="light-purple--clr" /> : <FiEyeOff className="light-purple--clr" />
                            }
                            iconRightOnClick={() => setPasswordVisible(!passwordVisible)}
                        // errorMessage={formik.errors.password}
                        // inputState={`${helper.getInputClasses(formik, 'password')}`}
                        // {...formik.getFieldProps("password")}
                        />
                    </ContentBlock>

                    <ContentBlock>
                        <InputBlock
                            id="confirmPassword"
                            type={confirmPasswordVisible ? "text" : "password"}
                            inputLabel="Confirm Password"
                            iconLeft={
                                <FiLock size={20} className="mid-grey--clr" />
                            }
                            iconRight={
                                confirmPasswordVisible ? <FiEye className="light-purple--clr" /> : <FiEyeOff className="light-purple--clr" />
                            }
                            iconRightOnClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                        // errorMessage={formik.errors.confirmPassword}
                        // inputState={`${helper.getInputClasses(formik, 'confirmPassword')}`}
                        // {...formik.getFieldProps("confirmPassword")}

                        />
                    </ContentBlock>

                    <CustomBlock className="content-container--actions">
                        <Button
                            text="Cancel"
                            className="danger--bg"
                        />

                        <Button
                            type="submit"
                            text="Submit"
                            className="primary--bg"
                        />
                    </CustomBlock>
                </Section>
            </ModalBlock>
        </>
    );
}

export default connect(null, { ...alert.actions, ...auth.actions })(UserListingPage);