// DEPENDENCIES
import React, { useState } from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { HiCheck } from 'react-icons/hi';
import { IoClose, IoWarningOutline, IoInformation } from 'react-icons/io5';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
// SERVICES AND HELPERS

const MagnificentToast = (props) => {

    const iconBackgroundColors = {
        "success": "lighter-green--bg",
        "error": "lighter-red--bg",
        "warning": "lighter-yellow--bg",
        "info": "lighter-blue--bg",
    }

    const headers = {
        "success": "Success!",
        "error": "Error!",
        "warning": "Warning!",
        "info": "Info",
    }

    const headerColors = {
        "success": "success--clr",
        "error": "danger--clr",
        "warning": "warning--clr",
        "info": "info--clr",
    }

    return (
        <>
            <CustomBlock className="magnificent-toast-container">
                <CustomBlock className={`magnificent-toast ${props.visible ? "visible" : "hidden"}`}>
                    {
                        props.dismissable &&
                        <CustomBlock className="toast-crust">
                            <Link
                                onClick={props.onDismiss ? props.onDismiss : {}}
                            >
                                <IoClose size={20} className="mid-grey--clr" />
                            </Link>
                        </CustomBlock>
                    }

                    <CustomBlock className="toast-body">
                        <CustomBlock className="icon-container">
                            <CustomBlock className={`icon ${props.state ? iconBackgroundColors[props.state] : "light-grey--bg"}`}>
                                {
                                    props.state == "success" &&
                                    <HiCheck size={30} className={`success--clr`} />
                                }
                                {
                                    props.state == "error" &&
                                    <IoClose size={35} className={`light-red--clr`} />
                                }
                                {
                                    props.state == "warning" &&
                                    <IoWarningOutline size={30} className={`warning--clr`} />
                                }
                                {
                                    props.state == "info" &&
                                    <IoInformation size={35} className={`info--clr`} />
                                }
                            </CustomBlock>
                        </CustomBlock>

                        <CustomBlock className="text-container">
                            {   
                                props.hasHeader &&
                                <p className={`header ${props.headerColor ? props.headerColor : headerColors[props.state]}`}>
                                    {props.header ? props.header : headers[props.state]}
                                </p>
                            }
                            <p className="text">
                                {props.text}
                            </p>
                        </CustomBlock>

                        <CustomBlock>

                        </CustomBlock>
                    </CustomBlock>
                </CustomBlock>
            </CustomBlock>
        </>
    );
}

export default MagnificentToast;