// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
import { HiCheck, HiDesktopComputer, HiOutlineMail } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
// CUSTOM COMPONENTS
import * as auth from '../../../modules/Auth/_redux/authRedux';
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import Button from '../../../components/Button';
import ModalBlock from '../../../components/ModalBlock';
import InputBlock from '../../../components/InputBlock';
// SERVICES 
import * as userService from '../../../../services/management/userService';
//HELPERS
import AddUserModelValidator from '../../../../helpers/validators/AddUserModelValidator';
import * as helper from '../../../../helpers/helper';


const AdminListingPage = (props) => {
    const { showAlert, history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        userService.getAllAdmins().then(res => {
            setUsers(res);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }, [refresh]);

    const [adminModel, setAdminModel] = useState({});

    const sendInvite = (model, setSubmitting) => {
        userService.registerAdmin({
            ...model, isApproved: true, isAdmin: true,
            businessName: 'DarylAndSons',
            primaryContactName: '',
            secondaryContactName: '',
            ein: '',
            phoneNumber: ''
        }).then(res => {
            setRefresh(!refresh);
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => { 

            setIsLoading(false);
            setSubmitting(false);
        })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: adminModel,
        validationSchema: AddUserModelValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleNewAdminModalClose();
            setIsLoading(true);
            sendInvite(values, setSubmitting);
        }
    });

    const columns = [
        {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>

                            <Button
                                className="light-purple--bg fit-content ml-0"
                                text="View"
                                size="xs"
                                to={"/user-management/" + users[dataIndex].id}
                            />

                            {/* <Button
                                className="danger--bg fit-content"
                                text="Delete"
                                size="xs"
                                to={"/user-management/" + users[dataIndex].id}
                            /> */}


                        </CustomBlock>

                    );
                }
            }
        },

        // {
        //     name: "businessName",
        //     label: "BusinessName",
        //     options: {
        //         filter: true,
        //         sort: true
        //     }
        // },
        {
            name: "emailAddress",
            label: "Email Address",
            options: {
                filter: true,
                sort: true
            }
        },
    ];

    const handleNewAdminModalClose = () => {
        formik.resetForm();
        setModalVisible(false);
    }

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Admins"
                                    headerSize="lg"
                                    primaryButtonText="Add New"
                                    primaryButtonOnClick={() => setModalVisible(true)}
                                />
                                <ContentBlock>
                                    <MUIDataTable
                                        data={users}
                                        columns={columns}
                                        options={{
                                            selectableRows: "none"
                                        }}
                                    />
                                </ContentBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
            <ModalBlock
                show={modalVisible}
                onHide={handleNewAdminModalClose}
                contentHeader="Add New Admin"
                closeAction
                centered
            >
                <FormBlock onSubmit={formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock>
                            <InputBlock
                                id="email"
                                inputLabel="Email Address"
                                errorMessage={formik.errors.emailAddress}
                                inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                                {...formik.getFieldProps("emailAddress")}
                                iconLeft={
                                    <HiOutlineMail size={20} className="mid-grey--clr" />
                                }
                            />
                        </ContentBlock>

                        <CustomBlock className="content-container--actions mt-0">
                            <Button
                                text="Cancel"
                                className="grey--bg"
                                onClick={handleNewAdminModalClose}
                            />

                            <Button
                                type="submit"
                                text="Send Invite"
                                className="primary--bg"
                                disabled={formik.isSubmitting || !formik.isValid}
                            // onClick={() => setModalVisible(false)}
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
        </>
    );
}

export default connect(null, { ...alert.actions, ...auth.actions, })(AdminListingPage);