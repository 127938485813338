// DEPENDENCIES
import React, { useState } from 'react';
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import _ from 'lodash';
// COMPONENTS
import { FilePond, registerPlugin } from "react-filepond";
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import MediaBlock from './MediaBlock';
import ContentBlock from './ContentBlock';
// SERVICES AND HELPERS
import Section from './Section';
import Button from './Button';
import InputBlock from './InputBlock';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileUploadBlock = (props) => {

    const [method, setMethod] = useState('upload');

    const [media, setMedia] = useState(props.path);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl);

    const clearSources = () => {
        setMedia(null);
        setVideoUrl(null);
    }

    return (
        <CustomBlock className={`${props.containerClassName ? props.containerClassName : ""}`} style={{ width: 'inherit' }}>
            {
                media || videoUrl ?
                    props.allowMultiple == true ?
                        <>
                            <FilePond
                                {...props}
                                labelIdle={props.labelIdle ? props.labelIdle : 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'}
                            />
                            <Section removeContainer>
                                {
                                    media.map((item, index) =>
                                        item &&
                                        <ContentBlock cols={4}>
                                            <MediaBlock
                                                source={item.imagePath}
                                                bordered
                                                onClick={props.clearActionOnClick}
                                                clearSources={clearSources}
                                            />
                                        </ContentBlock>
                                    )
                                }
                            </Section>
                        </>
                        :
                        <MediaBlock
                            onClick={props.clearActionOnClick}
                            source={media}
                            videoUrl={videoUrl}
                            bordered
                            clearSources={clearSources}
                        />
                    :
                    <>
                        {
                            props.type == 'video' ?
                                <>
                                    <Button
                                        text={method == 'upload' ? "Use Link" : "Use Uploader"}
                                        className="primary--bg fit-content"
                                        style={{ marginLeft: 5 }}
                                        size="xs"
                                        onClick={() => {
                                            setMethod(method == 'upload' ? 'link' : 'upload');
                                            props.setVideoPath && props.setVideoPath(null);
                                        }}
                                    />
                                    {
                                        method == 'upload' ?
                                            <FilePond
                                                {...props}
                                                labelIdle={props.labelIdle ? props.labelIdle : 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'}
                                            /> :
                                            <InputBlock
                                                inputLabel={"Video Link"}
                                                bordered
                                                onChange={(e) => {
                                                    console.log(e.target.value);
                                                    props.setVideoPath && props.setVideoPath(e.target.value);
                                                }}
                                            />
                                    }
                                </> :
                                <FilePond
                                    {...props}
                                    labelIdle={props.labelIdle ? props.labelIdle : 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>'}
                                />
                        }
                    </>
            }
        </CustomBlock>
    );
}

export default FileUploadBlock;