// DEPENDENCIES
import React from 'react';
import { useSpring, animated } from 'react-spring';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const TextAreaBlock = (props) => {

    const errorMessageSuffix = "is Required";
    const inputStates = {
        error: "error",
        warning: "warning",
        success: "success",
        disabled: "disabled"
    }

    return (
        <>
            <CustomBlock className={`input-block-container ${props.containerClassName ? props.containerClassName : ""}`}>
                {
                    props.inputLabel ?
                        <span className={`static-floating-label ${props.inputState}`}>
                            {props.inputLabel}
                        </span> : <></>
                }
                <textarea
                    className={`input-block textarea ${props.className ? props.className : ""} ${props.backgroundColor ? props.backgroundColor : "light--bg"} ${props.inputState ? inputStates[props.inputState] : ""} ${props.bordered ? "bordered" : ""} ${!props.inputLabel ? "padded" : ""}`}
                    placeholder={props.placeholder}
                    autoFocus={props.autoFocus ? props.autoFocus : false}
                    disabled={props.disabled || props.inputState === "disabled" ? true : false}
                    value={props.value}
                    onClick={props.onClick ? props.onClick : () => { }}
                    onChange={props.onChange ? props.onChange : () => { }}
                    onFocus={props.onFocus ? props.onFocus : () => { }}
                    onBlur={props.onBlur ? props.onBlur : () => { }}
                    id={props.id}
                />
                {
                    props.inputState === 'error' ?
                        <span className={`input-state-text error-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.errorMessage ? props.errorMessage : props.inputLabel ? (props.inputLabel + " " + errorMessageSuffix) : "Error message undefined"}
                        </span> : <></>
                }
                {
                    props.inputState === 'warning' ?
                        <span className={`input-state-text warning-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.warningMessage}
                        </span> : <></>
                }
                {
                    props.inputState === 'success' ?
                        <span className={`input-state-text success-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.successMessage}
                        </span> : <></>
                }
            </CustomBlock>
        </>
    )
}

export default TextAreaBlock;