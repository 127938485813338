import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux.js";
import * as alert from "../redux/alertSnackBarRedux";
import * as confirmModal from "../redux/confirmModalRedux"; 
import * as cartCounter from "../redux/cartCounterRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  alert: alert.reducer,
  cartCounter: cartCounter.reducer,
  confirmModal: confirmModal.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
