// DEPENDENCIES
import React from 'react';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const TextHeader = (props) => {

    const textSize = {
        lg: "--lg",
        md: "--md",
        mdlg: "--mdlg",
        sm: "--sm",
    };

    return (
        <>
            <CustomBlock>
                <h4 className={`text-header${props.size ? textSize[props.size] : ""} ${props.className ? props.className : ""} ${props.centered ? "centered" : ""}`}>
                    {
                        props.iconLeft &&
                        <CustomBlock className={`text-header-icon-left ${props.iconLeftClassName ? props.iconLeftClassName : ""}`}>
                            {props.iconLeft}
                        </CustomBlock>
                    }
                    {props.title}
                </h4>
                {
                    props.subtitle ? <p className={`text-header-subtitle${props.size ? textSize[props.size] : ""} ${props.subtitleClassName ? props.subtitleClassName : ""}`}>{props.subtitle}</p> : <></>
                }
            </CustomBlock>
        </>
    );
}

export default TextHeader;