// DEPENDENCIES
import React from 'react';

const CustomBlock = (props) => {

    return (
        <div
            className={`${props.className ? props.className : ""}`}
            style={props.style}
        >
            {props.children}
        </div>
    );
}

export default CustomBlock;