// DEPENDENCIES
import React, { useState } from 'react';

const FormBlock = (props) => {
    return (
        <>
            <form 
                className={`form-block-container ${props.className ? props.className : ""}`}
                onSubmit={props.onSubmit}
            >
                {props.children}
            </form>
        </>
    )
}

export default FormBlock;