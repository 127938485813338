// DEPENDENCIES
import React from 'react';
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from "redux-persist/integration/react";

// COMPONENTS
import Routes from './Routes';

// STYLESHEETS
// import 'swiper/swiper.min.css';
import './assets/css/main.css';
import './assets/custom/custom.css';

const App = ({ store, persistor, basename }) => {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}> */}

      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      {/* <React.Suspense fallback={<LayoutSplashScreen />}> */}
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      
      <PersistGate persistor={persistor} >
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          {/* Provide `react-intl` context synchronized with Redux state.  */}
          {/* Render routes with provided `Layout`. */}
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;