// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import swal from 'sweetalert';
import { connect } from 'react-redux';
// COMPONENTS
import { Link } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
// ICONS
import { FiInfo, FiBox } from 'react-icons/fi';
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import SelectBlock from '../../components/SelectBlock';
import TextAreaBlock from '../../components/TextAreaBlock';
import Image from '../../components/Image';
import TotalsBlock from '../../components/TotalsBlock';
import ListItem from '../../components/ListItem';
import InputBlock from '../../components/InputBlock';
import Button from '../../components/Button';
//SERVICES AND HELPERS
import * as cartService from '../../../services/ecommerce/cartService';
import constants from '../../../constants/constants';
import * as cartCounter from '../../../redux/cartCounterRedux';
import * as orderService from '../../../services/ecommerce/orderService';
// ASSETS
import SampleImage from '../../assets/img/content/banner-1.jpg';
import SampleImageTwo from '../../assets/img/content/banner-2.jpg';


const CartPage = (props) => {
    const { cartCounter, setCounter, history, resetCounter } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [cartItems, setCartItems] = useState([])
    const body = document.querySelector('body');
    const [activeTextAreaIndex, setActiveTextAreaIndex] = useState(-1);
    const [promoCode, setPromoCode] = useState(null);
    const [savedPromoCode, setSavedPromoCode] = useState(null);
    const [discountApplied, setDiscountApplied] = useState(0);
    const [invalidOrderFlag, setInValidOrderFlag] = useState(false);

    useEffect(() => {
        if (isLoading) {
            disableBodyScroll(body);
        } else {
            enableBodyScroll(body);
        }

        getCartItems()
    }, [])

    const getCartItems = () => {
        setIsLoading(true);

        cartService.getCart().then(res => {
            setDiscountApplied(res.discountApplied);
            setSavedPromoCode(res.promoCode);
            setPromoCode(res.promoCode);
            setCartItems(res.productCartItems ? res.productCartItems : []);
            setInValidOrderFlag(false);

            for (let index in res.productCartItems) {
                let item = res.productCartItems[index];
                if ((!item.product.isAvailable || !item.product.isActive)) {
                    setInValidOrderFlag(true);
                    break;
                }
            }
        }).finally(x => {
            setIsLoading(false)
        });
    }

    const askRemoveConfirmation = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, your item will be removed from the cart!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    removeCartItem(id);
                } else {
                }
            });
    }

    const removeCartItem = (id) => {
        cartService.removeItem(id).then(res => {
            setCounter(cartCounter - res.removedQuantity);
            swal("Success!", res.message, "success");
            getCartItems();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        })
    }

    const saveInstructions = (id, text) => {
        cartService.saveSpecialInstructions(id, text).then(res => {
            setActiveTextAreaIndex(-1);
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        })
    }

    const applyPromoCode = () => {
        cartService.applyPromoCode(promoCode).then(res => {
            swal("Success", res.message, "success");
            getCartItems();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        })
    }

    const removePromoCode = () => {
        cartService.clearPromoCode().then(res => {
            swal("Success", res.message, "success");
            getCartItems();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        })
    }

    const placeOrder = (order) => {
        setIsLoading(true);
        console.log("Place order", {
            cartId: 0,
            orderPlace: new Date(),
            payerEmailAddress: order.payer.email_address,
            payerName: order.payer.name.given_name + ' ' + order.payer.name.surname,
            totalAmountPaid: order.purchase_units.amount.value,
            facilitatorAccessToken: "-",
            orderId: order.id,
            payerId: order.payer.payer_id
        })

    }

    return (
        <>
            {
                isLoading ? <LoadingScreen /> : cartItems.length > 0 ?
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock>
                                <ContentHeader
                                    title="Shopping Cart"
                                    className="cart-header pb-0"
                                />
                            </CustomBlock>

                            {
                                cartItems.length > 0 &&
                                cartItems.map((item, index) => (
                                    <CustomBlock key={item.id} className="content-container--card-style mt-4">

                                        {
                                            (!item.product.isAvailable || !item.product.isActive) &&
                                            <ListItem
                                                title={"Product has been modified or unavailable. Please remove the product from your cart"}
                                                className="mini-action-block lighter--bg"
                                                style={{ justifyContent: 'flex-start' }}
                                                iconleft={
                                                    // <FiInfo size={35} className="mid-grey--clr mr-3" />
                                                    <FiInfo size={30} className="danger--clr mr-4" />
                                                }
                                            />
                                        }
                                        <CustomBlock className="cart-item-container">

                                            {/* ITEM DETAILS */}
                                            <CustomBlock className="item-details-block">

                                                <CustomBlock className="image-block">
                                                    <Image
                                                        source={constants.API_URL + 'Attachments' + item.frameUrl}
                                                    />
                                                </CustomBlock>

                                                <CustomBlock className="item-details">
                                                    <CustomBlock>
                                                        <ContentHeader
                                                            title={item.name}
                                                            headerSize="md"
                                                            className="pb-2"
                                                        />
                                                    </CustomBlock>

                                                    <CustomBlock>
                                                        <ContentHeader
                                                            title={'$' + item.price}
                                                            headerSize="md"
                                                        />
                                                    </CustomBlock>

                                                    <CustomBlock className="mb-3">
                                                        <ContentHeader
                                                            title="Mat Style"
                                                            headerSize="sm"
                                                            subtitle={item.matColor}
                                                            subtitleClassName="pt-2"
                                                        />
                                                    </CustomBlock>

                                                    <CustomBlock>
                                                        <ContentHeader
                                                            title="Frame Size"
                                                            headerSize="sm"
                                                            subtitle={item.size}
                                                            subtitleClassName="pt-2"
                                                        />
                                                    </CustomBlock>
                                                </CustomBlock>

                                                <CustomBlock className="actions-block">
                                                    <a className="remove-action action dark--clr">
                                                        {item.quantity}
                                                    </a>

                                                    <button onClick={() => askRemoveConfirmation(item.id)} className="remove-action action white--bg">
                                                        Remove
                                                    </button>

                                                    {/* <Link to="#" className="edit-action action">
                                                Edit
                                            </Link> */}
                                                </CustomBlock>
                                            </CustomBlock>

                                            {/* ADDITIONAL DETAILS */}
                                            <CustomBlock className="additional-details-block">
                                                <p><Link to="#" onClick={() => setActiveTextAreaIndex(index)}>Add Special Instructions</Link></p>

                                                {
                                                    activeTextAreaIndex === index &&
                                                    <CustomBlock className="mt-4">
                                                        <TextAreaBlock
                                                            placeholder="Your special instructions..."
                                                            value={item.specialInstructions}
                                                            onChange={e => {
                                                                let tempCartItems = [...cartItems];
                                                                let tempItem = tempCartItems.find(x => x.id === item.id);
                                                                tempItem.specialInstructions = e.target.value;

                                                                setCartItems(tempCartItems);
                                                            }}
                                                        />

                                                        <CustomBlock className="button-container--flex-end pt-0">
                                                            <Button
                                                                text="Save & Close"
                                                                className="light-purple--bg mr-1"
                                                                size="xs"
                                                                onClick={() => {
                                                                    saveInstructions(item.id, item.specialInstructions);
                                                                }}
                                                            />
                                                        </CustomBlock>
                                                    </CustomBlock>
                                                }
                                            </CustomBlock>
                                        </CustomBlock>

                                    </CustomBlock>
                                ))
                            }



                            <CustomBlock className="content-container--actions">
                                <InputBlock
                                    backgroundColor="white--bg"
                                    placeholder="Enter Promo Code"
                                    value={promoCode}
                                    disabled={savedPromoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                />
                                {
                                    savedPromoCode ?
                                        <Button
                                            text="Remove Code"
                                            className="danger--bg"
                                            onClick={removePromoCode}
                                        /> :
                                        <Button
                                            text="Apply Code"
                                            onClick={applyPromoCode}
                                            className={`${!promoCode ? "grey--bg" : "primary--bg"} `}
                                            disabled={!promoCode}
                                        />


                                }

                            </CustomBlock>
                            {/* CART ACTIONS AND TOTALS */}
                            <TotalsBlock
                                lineOneCost={cartItems.reduce((a, v) => a + v.quantity, 0)}
                                lineTwoCost={"$" + cartItems.reduce((a, v) => a + v.quantity * v.price, 0).toFixed(2)}
                                lineThreeTitle={"Discounts"}
                                lineThreeCost={discountApplied ? " - $" + ((discountApplied / 100) * cartItems.reduce((a, v) => a + v.quantity * v.price, 0)).toFixed(2) : "-"}
                                mainLineCost={discountApplied ? (((100 - discountApplied) / 100) * cartItems.reduce((a, v) => a + v.quantity * v.price, 0)).toFixed(2) : cartItems.reduce((a, v) => a + v.quantity * v.price, 0).toFixed(2)}
                                primaryButtonText="Keep Shopping"
                                primaryButtonClassName="light-purple--bg"
                                primaryButtonTo="/frames"
                            />

                            {!invalidOrderFlag &&
                                <PayPalButton
                                    amount={discountApplied ? (((100 - discountApplied) / 100) * cartItems.reduce((a, v) => a + v.quantity * v.price, 0)).toFixed(2) : cartItems.reduce((a, v) => a + v.quantity * v.price, 0).toFixed(2)}
                                    shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                    // onSuccess={(details, data) => {
                                    //     placeOrder(details, data);
                                    // }}
                                    createOrder={(data, actions) => {
                                        let discountAppliedToOrder = discountApplied ? ((discountApplied / 100) * cartItems.reduce((a, v) => a + v.quantity * v.price, 0)).toFixed(2) : 0;
                                        let total = cartItems.reduce((a, v) => a + v.quantity * v.price, 0).toFixed(2)

                                        return actions.order.create({
                                            purchase_units: [{
                                                amount: {
                                                    currency_code: "USD",
                                                    value: total - discountAppliedToOrder,
                                                    breakdown: {
                                                        item_total: {
                                                            currency_code: "USD",
                                                            value: total
                                                        },
                                                        discount: {
                                                            "currency_code": "USD",
                                                            "value": discountAppliedToOrder
                                                        }
                                                    }
                                                },
                                                items: cartItems.map(item => {
                                                    return {
                                                        name: item.name,
                                                        description: item.name + " Size: " + item.size + ". Special Instructions: " + item.specialInstructions ? item.specialInstructions : "-",
                                                        quantity: item.quantity,
                                                        unit_amount: {
                                                            currency_code: "USD",
                                                            value: item.price
                                                        },
                                                    }
                                                }),
                                            }],
                                            discount: discountApplied,
                                            application_context: {
                                                shipping_preference: 'NO_SHIPPING'
                                            }
                                        });
                                    }}
                                    onApprove={(data, actions) => {
                                        // Capture the funds from the transaction
                                        return actions.order.capture().then(function (order) {
                                            // Show a success message to your buyer
                                            setIsLoading(true);
                                            orderService.placeOrder({
                                                cartId: 0,
                                                orderPlace: new Date(),
                                                payerEmailAddress: order.payer.email_address,
                                                payerName: order.payer.name.given_name + ' ' + order.payer.name.surname,
                                                totalAmountPaid: order.purchase_units[0].amount.value,
                                                facilitatorAccessToken: "-",
                                                orderId: order.id,
                                                payerId: order.payer.payer_id
                                            }).then(res => {
                                                resetCounter();
                                                swal({
                                                    title: "Order Placed",
                                                    text: "Thank you, your order #" + res.orderNumber + " has been placed",
                                                    icon: "success",
                                                    buttons: ["Shop More", "View Order"],
                                                    dangerMode: false,
                                                })
                                                    .then((viewOrder) => {
                                                        if (viewOrder) {
                                                            history.push('/my-orders');
                                                        } else {
                                                            history.push('/frames');
                                                        }
                                                    });
                                            }).catch(ex => {
                                                console.log("Exception is ", ex);
                                                swal('Error!', "Something went wrong on the server. Your payment has been processed and will be refunded", 'error')
                                            }).finally(() => {
                                                setIsLoading(false);
                                            })
                                        });
                                    }}
                                    onError={(ex) => {
                                        swal('Error!', "Something went wrong while processing the payment. Please try again", 'error')
                                    }}
                                    options={{
                                        clientId: constants.PAYPAL_CLIENTID,
                                    }}
                                />}
                        </Section>
                    </CustomBlock>
                    : <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock>
                                <ContentHeader
                                    title="Cart Empty "
                                    className="cart-header pb-0"
                                />
                            </CustomBlock>
                        </Section>
                        {/* <h4>Your cart is empty</h4>
                        <h6>Add Products to it. Checkout our wide range of products!</h6>
                        <Button
                            text="Continue Shopping"
                            to="/frames"
                            className={`btn-info`}
                        /> */}
                    </CustomBlock>
            }
        </>
    );
}

const mapStateToProps = (state) => ({
    cartCounter: state.cartCounter
})

export default connect(mapStateToProps, { ...cartCounter.actions })(CartPage);