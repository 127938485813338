// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../components/CustomBlock';
import TextHeader from '../../components/TextHeader';
import Logo from '../../assets/svg/logo.svg'
import Image from '../../components/Image';
import FormBlock from '../../components/FormBlock';
import InputBlock from '../../components/InputBlock';
import Button from '../../components/Button';

const ForgotPasswordPage = (props) => {

    return (
        <CustomBlock className="authenticate-page">
            <CustomBlock className="form-container">
                <Fade>
                    <CustomBlock className="logo-container">
                        <Link to="/">
                            <Image
                                source={Logo}
                            />
                        </Link>

                        <TextHeader 
                            title="Recover Your Account"
                            centered
                            className="light--clr"
                        />
                    </CustomBlock>
                </Fade>
                <FormBlock>
                    <Fade>
                        <InputBlock
                            id="email"
                            // inputLabel="Email Address"
                            placeholder="Email Address"
                        />

                        <CustomBlock className="button-container">
                            <Button
                                text="Submit"
                                type="submit"
                                className="secondary--bg w-100"
                                size="sm"
                            />
                        </CustomBlock>
                    </Fade>
                </FormBlock>

                <Fade>
                    <CustomBlock className="links-container forgot-password">
                        <Link to="/login">
                            Back to Login
                    </Link>
                    </CustomBlock>
                </Fade>
            </CustomBlock>
        </CustomBlock>
    );
}

export default ForgotPasswordPage;