// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { CgClose } from "react-icons/cg";
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';
import Image from "./Image";
import LogoTextAlt from '../assets/svg/logo-text-alt.svg';

const Drawer = (props) => {

    const drawerPosition = {
        left: "left",
        right: "right",
    }

    return (
        <>
            {
                props.open ?
                    <Overlay
                        onClick={props.closeOnMaskPress ? props.onClick : () => { }}
                        overlayDarkness="dark"
                    /> : <></>
            }
            <CustomBlock className={`drawer ${props.open ? "open" : ""} ${props.position ? drawerPosition[props.position] : "right"}`}>
                <CustomBlock className="drawer-header">
                    <Link
                        to={props.to ? props.to : "/"}
                        onClick={props.onClick}
                        className="drawer-logo-block"
                    >
                        <Image
                            source={LogoTextAlt}
                        />
                    </Link>

                    <CustomBlock className="drawer-close-action-block">
                        <Link to="#" onClick={props.onClick}>
                            <CgClose size={25} className="secondary--clr" />
                        </Link>
                    </CustomBlock>
                </CustomBlock>

                <CustomBlock>
                    {props.children}
                </CustomBlock>
            </CustomBlock>
        </>
    );
}

export default Drawer;