// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { connect } from 'react-redux';
import { useFormik } from "formik";
// COMPONENTS
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
// ICONS
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import Button from '../../components/Button';
import SelectBlock from '../../components/SelectBlock';
import InputBlock from '../../components/InputBlock';
import Image from '../../components/Image';
import AlertModal from '../../components/AlertModal';
import FormBlock from '../../components/FormBlock';
import ModalBlock from '../../components/ModalBlock';
// ASSETS
import SampleImage from '../../assets/img/content/banner-1.jpg';
import SampleImageTwo from '../../assets/img/content/banner-2.jpg';
import SampleImageThree from '../../assets/img/content/banner-3.jpg';
// SERVICES
import * as productService from '../../../services/product/productService';
import * as cartService from '../../../services/ecommerce/cartService';
// HELPERS
import * as cartCounter from '../../../redux/cartCounterRedux';
import constants from '../../../constants/constants';
import AddToCartValidator from '../../../helpers/validators/AddToCartValidator';
import * as helper from '../../../helpers/helper';
import FramePreviewModule from '../../components/FramePreviewModule';

var initialProduct = {
    id: 0,
    name: '',
    description: ''
}

var initialAddToCartModel = {
    productId: 0,
    frameId: 0,
    sizeId: null,
    quantity: 1,
    matStyleId: null
}

const ProductDetailsPage = (props) => {
    const { id } = useParams();
    const { setCounter } = props;
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [product, setProduct] = useState(initialProduct);
    const [isLoading, setIsLoading] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [usedemoImages, setUsedemoImages] = useState(false);
    const [matOptions, setMatOptions] = useState([]);
    const [sizeOptions, setSizeOptions] = useState(null);
    const [addToCartModel, setAddToCartModel] = useState(initialAddToCartModel);
    const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
    const [sizePriceModel, setSizePriceModel] = useState({});
    const [frameOptionsPreview, setFrameOptionsPreview] = useState([]);
    const [matColorOptionsPreview, setMatColorOptionsPreview] = useState([]);
    const [isAdditionalInfoVisible, setIsAdditionalInfoVisible] = useState(false);
    const [selectedMatStyle, setSelectedMatStyle] = useState(null);
    const [selectedSize, setSelectedSize] = useState(null);

    const demoImages = [
        { id: 0, image: SampleImage },
        { id: 1, image: SampleImageTwo },
        { id: 2, image: SampleImageThree },
        { id: 4, image: SampleImage },
        { id: 5, image: SampleImageThree },
    ];

    useEffect(() => {
        setIsLoading(true);
        getProductDetail(id);
    }, [id])

    const getProductDetail = (id) => {
        setIsLoading(true);
        productService.getProductById(id).then(res => {
            setProduct(res);
            setOptionsForDropDown(res);
            setOptionsForPreview(res);
            if (res.productImages.length == 0) {
                setUsedemoImages(true);
            }
            setProductImages(res.productImages)
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const setOptionsForDropDown = (model) => {
        setMatOptions(model.productMatColors.map(x => {
            return {
                label: x.matColor.number + " - " + x.matColor.name,
                value: x.matColor.id
            }
        }))
        let tempSizePriceModel = {};

        setSizeOptions(model.productPricingBlocks.map(x => {
            return {
                label: x.name,
                options: x.productBlockSizes.map(y => {
                    tempSizePriceModel[y.id] = x.price;
                    return {
                        label: y.height + " in x " + y.width + " in",
                        value: y.id
                    }
                })
            }
        }))
        setSizePriceModel(tempSizePriceModel);
    }

    const setOptionsForPreview = (model) => {
        if (model.frame) {
            setFrameOptionsPreview([{ label: model.frame.name, value: constants.API_URL + 'Attachments' + model.frame.frameUrl }]);
        }

        if (model.productMatColors) {
            setMatColorOptionsPreview(model.productMatColors.map(x => {
                return {
                    label: x.matColor.number + " - " + x.matColor.name,
                    value: x.matColor.color
                }
            }))
        }
    }

    const formik = useFormik({
        initialValues: addToCartModel,
        enableReinitialize: true,
        validationSchema: AddToCartValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            addItemsToCart(values);
        },
    });

    const addItemsToCart = (model) => {
        setIsLoading(true);
        cartService.addToCart({ ...model, frameId: product.frameId, productId: product.id }).then(res => {
            setCounter(res.items)
            formik.resetForm();
            setAddToCartModel(initialAddToCartModel);
            setModalVisible(true);
            setSelectedMatStyle(null);
            setSelectedSize(null);
            setModalVisible(true);
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">

                        <CustomBlock className="product-details-container">
                            <CustomBlock className="product-image-container">
                                <CustomBlock className="thumbnails-block">
                                    {productImages.map((thumbnail, index) =>
                                        <CustomBlock key={index} className="thumbnail">
                                            <Image
                                                source={constants.API_URL + 'Attachments' + thumbnail.filePath}
                                                onClick={() => setSelectedImageIndex(index)}
                                            />
                                        </CustomBlock>
                                    )}
                                </CustomBlock>

                                <CustomBlock className="main-image-block">
                                    <CustomBlock className="image">
                                        {
                                            productImages.length > 0 && <Image
                                                source={!usedemoImages ? constants.API_URL + 'Attachments' + productImages[selectedImageIndex].filePath : demoImages[selectedImageIndex].image}
                                            />
                                        }
                                    </CustomBlock>
                                </CustomBlock>
                            </CustomBlock>

                            <CustomBlock className="product-options-container">
                                <CustomBlock className="header-block">
                                    <ContentHeader
                                        title={product.name}
                                        subtitle={product.description}
                                    />
                                </CustomBlock>

                                <CustomBlock className="price-block">
                                    {
                                        formik.values.quantity && formik.values.sizeId &&
                                        <>
                                            <p className="dollar-symbol">$</p>
                                            <p className="amount">{formik.values.quantity && formik.values.sizeId ? (sizePriceModel[formik.values.sizeId] * formik.values.quantity).toFixed(2) : "__.__"}</p>
                                        </>
                                    }
                                </CustomBlock>

                                <FormBlock onSubmit={formik.handleSubmit}>
                                    <p>What is the estimated size of your item?</p>
                                    <Section removeContainer>
                                        <ContentBlock cols={12}>
                                            <SelectBlock
                                                inputLabel="Mat Style"
                                                placeholder="Select Mat Style"
                                                options={matOptions}
                                                inputState={`${helper.getInputClasses(formik, 'matStyleId')}`}
                                                onChange={option => {
                                                    setSelectedMatStyle(option)
                                                    formik.setFieldValue("matStyleId", option.value)
                                                }}
                                            />
                                        </ContentBlock>
                                        <ContentBlock cols={6}>
                                            <SelectBlock
                                                inputLabel="Size"
                                                placeholder="Select Size"
                                                options={sizeOptions}
                                                inputState={`${helper.getInputClasses(formik, 'sizeId')}`}
                                                onChange={option => {
                                                    setSelectedSize(option)
                                                    formik.setFieldValue("sizeId", option.value)
                                                }}
                                            />
                                        </ContentBlock>
                                        <ContentBlock cols={6}>
                                            <InputBlock
                                                inputLabel="Quantity"
                                                placeholder="Enter Quantity"
                                                type="number"
                                                inputState={`${helper.getInputClasses(formik, 'quantity')}`}
                                                {...formik.getFieldProps("quantity")}
                                            />
                                        </ContentBlock>
                                    </Section>

                                    <CustomBlock className="w-100">
                                        <Button
                                            text="Preview With Your Image"
                                            className="secondary--bg ml-1 mr-1 mt-4 w-100"
                                            onClick={() => setIsPreviewModalVisible(true)}
                                            size="sm"
                                        />

                                        <Button
                                            loading={isLoading}
                                            text="Add to Cart"
                                            className={`${formik.isSubmitting || (formik.touched && !formik.isValid) ? "grey--bg w-100" : "primary--bg"} ml-1 mr-1 mt-4 w-100`}
                                            size="sm"
                                            disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                                            type="submit"
                                        />
                                    </CustomBlock>
                                </FormBlock>
                                <CustomBlock className="flex-center mt-4">
                                    <button className="link white--bg" onClick={() => setIsAdditionalInfoVisible(true)}>
                                        View Additional Product Info and Disclaimers
                                    </button>
                                </CustomBlock>
                            </CustomBlock>
                        </CustomBlock>

                        {/* SECTIONS */}
                        <CustomBlock>

                        </CustomBlock>
                    </CustomBlock>
            }
            {/* MODALS */}
            <AlertModal
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                contentHeader="Item Added to Cart"
                centered
                // centeredTitle
                closeAction
                closeActionOnClick={() => setModalVisible(false)}
            >
                <CustomBlock className="mt-0">
                    <Button
                        to="/cart"
                        text="Checkout"
                        className="primary--bg ml-1 mr-1 mt-4 w-100"
                        size="sm"
                    />
                    <Button
                        text="Continue Shopping"
                        className="secondary--bg ml-1 mr-1 mt-4 w-100"
                        onClick={() => setModalVisible(false)}
                        size="sm"
                    />
                </CustomBlock>
            </AlertModal>
            <ModalBlock
                show={isPreviewModalVisible}
                size="xl"
                onHide={() => setIsPreviewModalVisible(false)}
                contentHeader="Frame preview"
                closeAction
                centered
            >
                <CustomBlock className="mt-0">
                    <FramePreviewModule
                        frameOptions={frameOptionsPreview}
                        matOptions={matColorOptionsPreview}
                    />
                </CustomBlock>
            </ModalBlock>

            <ModalBlock
                show={isAdditionalInfoVisible}
                size="lg"
                onHide={() => setIsAdditionalInfoVisible(false)}
                contentHeader="Product Info and Disclaimers"
                closeAction
                centered
                contentDescription={product.additionalInfo}
            />

        </>

    );
}

export default connect(null, { ...cartCounter.actions })(ProductDetailsPage);
