// DEPDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';


const TotalsBlock = (props) => {

    const blockSizes = {
        default: 'half',
        full: 'full'
    }

    const blockPositions = {
        default: '',
        center: 'center'
    }

    return (
        <CustomBlock className={`totals-block-container ${props.position ? blockPositions[props.position] : ""}${(props.primaryButtonText || props.secondaryButtonText) == null ? "padded" : ""}`}>
            <CustomBlock className={`totals-block ${props.size ? blockSizes[props.size] : "half"}`}>
                {
                    props.topNote &&
                    <CustomBlock className="note">
                        <p>{props.topNote}</p>
                    </CustomBlock>
                }

                <CustomBlock className="subtotals">
                    <CustomBlock>
                        {
                            props.lineOneCost &&
                            <p>{props.lineOneTitle ? props.lineOneTitle : "Total Products"}</p>
                        }
                        {
                            props.lineTwoCost &&
                            <p>{props.lineTwoTitle ? props.lineTwoTitle : "Subtotal"}</p>
                        }
                        {
                            props.lineThreeTitle &&
                            <p>{props.lineThreeTitle ? props.lineThreeTitle : "Shipping"}</p>
                        }
                                               {
                            props.lineFourCost &&
                            <p>{props.lineFourTitle ? props.lineFourTitle : "Tax"}</p>
                        }
                    </CustomBlock>
                    <CustomBlock className="right-line">
                        {
                            props.lineOneCost &&
                            <p>{props.lineOneCost}</p>
                        }
                        {
                            props.lineTwoCost &&
                            <p>{props.lineTwoCost}</p>
                        }
                        {
                            props.lineThreeCost &&
                            <p>{props.lineThreeCost}</p>
                        }
                        {
                            props.lineFourCost &&
                            <p>{props.lineFourCost}</p>
                        }
                    </CustomBlock>
                </CustomBlock>

                <CustomBlock className="total">
                    <CustomBlock>
                        <p>{props.mainLineTitle ? props.mainLineTitle : "Total"}</p>
                    </CustomBlock>
                    <CustomBlock className="right-line">
                        <p className="symbol">{props.mainLineSymbol ? props.mainLineSymbol : "$"}</p>
                        <p>{props.mainLineCost ? props.mainLineCost : 0}</p>
                    </CustomBlock>
                </CustomBlock>

                {
                    props.bottomNote &&
                    <CustomBlock className="note">
                        <p>{props.bottomNote}</p>
                    </CustomBlock>
                }

                {
                    (props.primaryButtonText || props.secondaryButtonText) != null &&
                    <CustomBlock className={`content-container--actions mt-5 pr-0 totals-block-actions ${props.position ? blockPositions[props.position] : ""}`}>
                        {
                            props.secondaryButtonText &&
                            <Button
                                to={props.secondaryButtonTo ? props.secondaryButtonTo : "#"}
                                onClick={props.secondaryButtonOnClick ? props.secondaryButtonOnClick : () => { }}
                                text={props.secondaryButtonText}
                                className={props.secondaryButtonClassName ? props.secondaryButtonClassName : ""}
                                size={props.secondaryButtonSize}
                            />
                        }

                        {
                            props.primaryButtonText &&
                            <Button
                                to={props.primaryButtonTo ? props.primaryButtonTo : "#"}
                                onClick={props.primaryButtonOnClick ? props.primaryButtonOnClick : () => { }}
                                text={props.primaryButtonText}
                                className={props.primaryButtonClassName ? props.primaryButtonClassName : ""}
                                size={props.primaryButtonSize}
                            />
                        }
                    </CustomBlock>
                }
            </CustomBlock>
        </CustomBlock>
    );
}

export default TotalsBlock;