// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import TextHeader from '../../components/TextHeader';
import QAndABlock from '../../components/QAndBlock';

const FAQPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const body = document.querySelector('body');
    const questions = [
        {
            id: 1,
            question: 'Return Policy',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.',
            buttonText: 'Contact Us',
            action: '/contact', 
        },
        {
            id: 2,
            question: 'Signing Up',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.',
            buttonText: 'Get Started Here',
            action: '/register', 
        },
        {
            id: 3,
            question: 'What do I need to sign up?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.',
            buttonText: '',
            action: '', 
        },
        {
            id: 4,
            question: 'Recovering Your Account',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.',
            buttonText: 'Forgot Password',
            action: '/forgot-password', 
        },
        {
            id: 5,
            question: 'What services do you offer?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque laoreet suspendisse interdum consectetur libero id faucibus nisl tincidunt.',
            buttonText: 'Our Services',
            action: '/services', 
        },
    ]

    useEffect(() => {
        if (isLoading) {
            disableBodyScroll(body);
        } else {
            enableBodyScroll(body);
        }

    }, [])

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-padding light--bg">
                        <Section>
                            <ContentBlock cols={7}>
                                <TextHeader 
                                    title="Frequently Asked Questions"
                                />
                                {
                                    questions.map((question, index) =>
                                        <QAndABlock
                                            questions={question}
                                            buttonClassName="primary--bg"
                                        />
                                    )
                                }
                            </ContentBlock>
                        </Section>
                    </CustomBlock>
            }
        </>
    );
}

export default FAQPage;