// DEPENDENCIES
import React from 'react';

const ContentBlock = (props) => {

    const columnSizes = {
        12: "col-lg-12 col-md-12",
        11: "col-lg-11 col-md-11",
        10: "col-lg-10 col-md-10",
        9: "col-lg-9 col-md-9",
        8: "col-lg-8 col-md-8",
        7: "col-lg-7 col-md-7",
        6: "col-lg-6 col-md-6",
        5: "col-lg-5 col-md-5",
        4: "col-lg-4 col-md-4",
        3: "col-lg-3 col-md-3",
        2: "col-lg-2 col-md-2",
    }

    return (
        <div
            className={`${props.className ? props.className : ""} ${props.cols ? columnSizes[props.cols] : "col-lg-12 col-md-12"}`}
            style={props.columnStyle}
        >
            {props.children}
        </div>
    );
}

export default ContentBlock;