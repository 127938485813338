// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import swal from "sweetalert";
import * as _ from 'lodash';
// COMPONENTS
// ICONS
import { AiFillPlusCircle, AiOutlineMinusCircle, AiFillMinusCircle, AiOutlineConsoleSql } from 'react-icons/ai';
import { FaDollarSign } from 'react-icons/fa';
import { FiInfo, FiBox } from 'react-icons/fi';
// CUSTOM COMPONENTS
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import FormBlock from '../../../components/FormBlock';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import TextAreaBlock from '../../../components/TextAreaBlock';
import SelectBlock from '../../../components/SelectBlock';
import CheckboxBlock from '../../../components/CheckboxBlock';
import FileUploadBlock from '../../../components/FileUploadBlock';
import Button from '../../../components/Button';
import ListContainer from '../../../components/ListContainer';
import ListItem from '../../../components/ListItem';
import ActionStrip from '../../../components/ActionStrip';
import ModalBlock from '../../../components/ModalBlock'
// SERVICES
import * as productService from '../../../../services/product/productService';
import * as productImageService from '../../../../services/product/productImageService';
// HELPERS & CONSTANTS
import * as helper from '../../../../helpers/helper';
import ProductValidator from '../../../../helpers/validators/ProductValidator';
import PricingBlockValidator from '../../../../helpers/validators/PricingBlockValidator';
import ProductBlockSizeValidator from '../../../../helpers/validators/ProductBlockSizeValidator';
import constants from '../../../../constants/constants';
import * as matColorService from '../../../../services/product/matColorService';
import * as frameService from '../../../../services/product/frameService';

const ProductManagementPage = (props) => {
    var initialProduct = {
        id: 0,
        frameId: null,
        name: '',
        description: '',
        additionalInfo: '',
        isAvailable: true,
        isActive: true,
        productImages: [],
        productMatColors: [],
        productPricingBlocks: []
    }

    var initialPricingBlock = {
        id: 0,
        name: '',
        price: ''
    }

    var initialProductBlockSize = {
        id: 0,
        productPricingBlockId: 0,
        height: '',
        width: ''
    }

    const { id } = useParams();
    const history = useHistory();
    const [isChecked, setIsChecked] = useState(true);
    const [product, setProduct] = useState(initialProduct);
    const [isLoading, setIsLoading] = useState(false)
    const [modalTitle, setModalTitle] = useState(null);
    const [files, setFiles] = useState(null);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [refresh, setRefresh] = useState(false);
    const [addPriceBlockModalVisible, setAddPriceBlockModalVisible] = useState(false);
    const [addSizeBlockModalVisible, setAddSizeBlockModalVisible] = useState(false);
    const [matColorOptions, setMatColorOptions] = useState([]);
    const [selectedMatColors, setSelectedMatColors] = useState(null);
    const [frameOptions, setFrameOptions] = useState([]);
    const [selectedFrameOption, setSelectedFrameOption] = useState(null);
    const [pricingBlockModel, setPricingBlockModel] = useState(initialPricingBlock);
    const [productBlockSizeModel, setProductBlockSizeModel] = useState(initialProductBlockSize);
    const [blockSizeIdToDelete, setBlockSizeIdToDelete] = useState(null);

    useEffect(() => {
        populateFrameOptions().then(frameOptions => {
            populateMatColorOptions().then(matColorOptions => {
                if (id != 0) {
                    getProductDetail(id, frameOptions, matColorOptions);
                }
            })
        })

    }, [files, refresh])

    const populateFrameOptions = () => {
        return frameService.getFrames().then(res => {
            if (res) {
                let options = res.map(x => {
                    return {
                        label: x.name,
                        id: x.id
                    }
                });

                setFrameOptions(options);
                return options;
            }

            return [];
        })
    }

    const populateMatColorOptions = () => {
        return matColorService.getMatColors().then(res => {
            let options = res.map(x => {
                return {
                    label: x.number + " - " + x.name,
                    value: x.id
                }
            });

            setMatColorOptions(options);
            return options;
        })
    }

    const getProductDetail = (id, frameOptions, matColorOptions) => {
        productService.getProductById(id).then(res => {
            if (res.productMatColors) {
                let productMatColorIds = res.productMatColors.map(x => x.matColorId);
                setSelectedMatColors(matColorOptions.filter(x => productMatColorIds.includes(x.value)))
            }
            if (res.frameId) {
                setSelectedFrameOption(frameOptions.find(x => x.id == res.frameId));
            }
            setProduct(res)
            setIsChecked(res.isAvailable)
        })
    }

    const saveProduct = (values, setSubmitting) => {
        setSubmitting(true);
        setIsLoading(true);
        let modelToSend = {
            ...values, productMatColors: selectedMatColors.map(x => {
                return {
                    productId: parseInt(id),
                    matColorId: x.value
                }
            }),
            productImages: null,
            productPricingBlocks: null
        }

        productService.saveProduct(modelToSend)
            .then(res => {
                swal("Success!", res.message, "success");
                if (values.id === 0) {
                    history.push('/product-management/' + res.productId)
                }
            }).catch(ex => {
                swal("Error!", ex.message, "error");
            }).finally(() => {
                setIsLoading(false);
                setSubmitting(false);
            });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: ProductValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProduct({ ...values }, setSubmitting)
        }
    });

    const pricingBlock_formik = useFormik({
        enableReinitialize: true,
        initialValues: pricingBlockModel,
        validationSchema: PricingBlockValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            savePricingBlock(values);
        }
    })


    const productBlockSize_formik = useFormik({
        enableReinitialize: true,
        initialValues: productBlockSizeModel,
        validationSchema: ProductBlockSizeValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveProductBlockSize(values);
        }
    })

    const saveProductBlockSize = (values) => {
        productService.saveProductBlockSize(values).then(res => {
            let copyPricingBlock = [...product.productPricingBlocks];
            var blockToAdd = copyPricingBlock.find(x => x.id == values.productPricingBlockId);
            blockToAdd.productBlockSizes.push({
                ...values,
                id: res.id
            })
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(x => {
            handleCloseSizeBlockModal();
        });
    }

    const handleCloseSizeBlockModal = () => {
        setProductBlockSizeModel(initialProductBlockSize);
        productBlockSize_formik.resetForm();
        setAddSizeBlockModalVisible(false);
    }

    const savePricingBlock = (values) => {
        productService.savePricingBlock({ ...values, productId: id }).then(res => {
            let indexOfPricingBlock = product.productPricingBlocks ? product.productPricingBlocks.indexOf(x => x.id == res.id) : -1;
            let pricingBlockCopy = product.productPricingBlocks ? [...product.productPricingBlocks] : [];

            if (indexOfPricingBlock > -1) {
                pricingBlockCopy[indexOfPricingBlock] = { ...pricingBlockCopy[indexOfPricingBlock], name: values.name, price: values.price }
            } else {
                pricingBlockCopy.push({
                    id: res.id,
                    name: values.name,
                    price: values.price,
                    productBlockSizes: []
                });
            }
            setProduct({ ...product, productPricingBlocks: pricingBlockCopy })

            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(x => {
            handleAddPriceBlockModalClose();
        });
    }

    const fetchPricingBlockModel = (model) => {
        setPricingBlockModel(model);
        setAddPriceBlockModalVisible(true)
    }

    const handleProductImageModalClose = () => {
        setModalTitle(null);
        setFiles(null);
        setRefresh(!refresh);
    }

    const handleModalClose = () => {
        setDeleteModalVisible(false);
    }

    const deleteImage = () => {
        productImageService.deleteProductImage(selectedImage.id)
            .then(res => {
                getProductDetail(id, frameOptions, matColorOptions);
                swal("Success!", res.message, "success");
            }).catch(ex => {
                swal("Error!", ex.message, "error");
            });
    }

    const handleProductImageSubmission = () => {
        if (files) {
            productImageService.saveProductImages(helper.convertJsonToFormData({
                productId: id,
                images: files.map(x => x.file)
            })).then(res => {
                getProductDetail(id, frameOptions, matColorOptions);
                swal("Success!", res.message, "success");
                handleProductImageModalClose();
            }).catch(ex => {
                swal("Error!", ex.message, "error");
            }).finally(handleProductImageModalClose());
        }
        else {
            handleProductImageModalClose();
            swal("Error!", "Please select images to upload", "error");
        }
    }

    const handleAddPriceBlockModalClose = () => {
        setAddPriceBlockModalVisible(false);
        setPricingBlockModel(initialPricingBlock);
        pricingBlock_formik.resetForm();
    }

    const deletePricingBlock = () => {
        productService.deletePricingBlock(pricingBlockModel.id).then(res => {
            let copyPricingBlock = [...product.productPricingBlocks];
            copyPricingBlock.splice(copyPricingBlock.findIndex(x => x.id == pricingBlockModel.id), 1);
            setProduct({ ...product, productPricingBlocks: copyPricingBlock });
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(handleAddPriceBlockModalClose());
    }

    const deleteBlockSize = (id, parentId) => {
        productService.deleteProductBlockSize(id).then(res => {
            let copyPricingBlock = [...product.productPricingBlocks];
            var blockToRemove = copyPricingBlock.find(x => x.id == parentId);
            blockToRemove.productBlockSizes.splice(blockToRemove.productBlockSizes.findIndex(x => x.id == id), 1);

            setProduct({ ...product, productPricingBlocks: copyPricingBlock });
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        })
    }

    const promptBlockRemoveConfirmation = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, the pricing block along with all the sizes will be removed!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deletePricingBlock();
                }
            });
    }

    const promptSizeRemoveConfirmation = (id, parentId) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, this size will be removed from the product!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteBlockSize(id, parentId);
                }
            });
    }

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-padding light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title={id == 0 ? "Add Product" : "Edit Product"}
                                    headerSize="lg"
                                />
                                <ModalBlock
                                    onHide={handleProductImageModalClose}
                                    show={modalTitle}
                                    contentHeader={modalTitle}
                                    closeAction
                                    centered
                                >
                                    <CustomBlock className="content mt-0">
                                        <ContentBlock>
                                            <FileUploadBlock
                                                allowMultiple
                                                labelIdle={'Drag & Drop Images' + ' or <span class="filepond--label-action">Browse</span> '}
                                                onupdatefiles={setFiles}
                                            />
                                        </ContentBlock>

                                        <ListItem
                                            title={"Total of 5 sample images can be uploaded."}
                                            className="mini-action-block lighter--bg"
                                            iconleft={
                                                // <FiInfo size={35} className="mid-grey--clr mr-3" />
                                                <FiInfo size={30} className="info--clr mr-2" />
                                            }
                                        />
                                    </CustomBlock>
                                    <CustomBlock className="content-container--actions mt-5">
                                        <Button
                                            text="Cancel"
                                            className="danger--bg"
                                            onClick={handleProductImageModalClose}
                                        />
                                        <Button
                                            type="button"
                                            text="Upload"
                                            onClick={handleProductImageSubmission}
                                            className={"primary--bg"}
                                        />
                                    </CustomBlock>
                                </ModalBlock>

                                <ModalBlock
                                    show={deleteModalVisible}
                                    onHide={handleModalClose}
                                    contentHeader="Delete Image"
                                    closeAction
                                    centered
                                    contentDescription="Are you sure you want to remove this Image?"

                                >
                                    <CustomBlock className="content-container--actions mt-0">
                                        <Button
                                            text="Cancel"
                                            className="grey--bg"
                                            onClick={handleModalClose}
                                        />

                                        <Button
                                            type="submit"
                                            text="Delete"
                                            className="danger--bg"
                                            onClick={() => {
                                                setDeleteModalVisible(false)
                                                deleteImage()
                                            }}
                                        />
                                    </CustomBlock>
                                </ModalBlock>

                                <FormBlock onSubmit={formik.handleSubmit}>
                                    <Section removeContainer>
                                        <ContentBlock cols={id > 0 ? 6 : 12}>
                                            <ContentBlock>
                                                <ContentHeader
                                                    title="Product Information"
                                                    headerSize="md"
                                                    className="pb-2"

                                                />
                                            </ContentBlock>

                                            <Section removeContainer>
                                                <ContentBlock cols={12}>
                                                    <InputBlock
                                                        id="name"
                                                        inputLabel="Product Name"
                                                        inputState={`${helper.getInputClasses(formik, 'name')}`}
                                                        {...formik.getFieldProps("name")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <SelectBlock
                                                        inputLabel="Mat Colors"
                                                        isMulti
                                                        options={matColorOptions}
                                                        value={selectedMatColors}
                                                        closeMenuOnSelect={false}
                                                        onChange={option => {
                                                            setSelectedMatColors(option);
                                                            formik.setFieldValue("productMatColors", option ? option.map(x => x.value) : [])
                                                        }}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <SelectBlock
                                                        inputLabel="Frame"
                                                        options={frameOptions}
                                                        value={selectedFrameOption}
                                                        onChange={selectedOption => {
                                                            selectedOption = selectedOption === null ? [] : selectedOption;
                                                            setSelectedFrameOption(selectedOption);
                                                            formik.setFieldValue("frameId", selectedOption.id);
                                                        }}
                                                        placeholder="Select a Frame"
                                                    />
                                                </ContentBlock>


                                                <ContentBlock>
                                                    <TextAreaBlock
                                                        id="description"
                                                        inputLabel="Product Description"
                                                        inputState={`${helper.getInputClasses(formik, 'description')}`}
                                                        {...formik.getFieldProps("description")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock>
                                                    <TextAreaBlock
                                                        id="additionalInfo"
                                                        inputLabel="Additional Info/Disclaimers"
                                                        inputState={`${helper.getInputClasses(formik, 'additionalInfo')}`}
                                                        {...formik.getFieldProps("additionalInfo")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock>
                                                    <CheckboxBlock
                                                        id="isAvailable"
                                                        label="Is Available?"
                                                        inputState={`${helper.getInputClasses(formik, 'isAvailable')}`}
                                                        {...formik.getFieldProps("isAvailable")}
                                                        checked={formik.values.isAvailable}
                                                    />
                                                </ContentBlock>
                                            </Section>
                                        </ContentBlock>
                                        {
                                            id > 0 &&
                                            <ContentBlock cols={6}>
                                                <Section removeContainer>
                                                    <ContentBlock>
                                                        <ContentHeader
                                                            title="Product Pricing Blocks"
                                                            headerSize="md"
                                                            className="pb-2"
                                                            primaryButtonText="Add Price Group"
                                                            primaryButtonOnClick={() => {
                                                                setAddPriceBlockModalVisible(true)
                                                            }}
                                                            buttonSize="xs"
                                                        />
                                                    </ContentBlock>

                                                    <CustomBlock className="price-block-container">
                                                        {
                                                            product.productPricingBlocks &&
                                                            product.productPricingBlocks.map(x => (
                                                                <CustomBlock className="price-block light--bg" key={x.id}>
                                                                    <ContentHeader
                                                                        title={`${x.name} - $${x.price}`}
                                                                        headerSize="md"
                                                                        primaryButtonText="Edit Block"
                                                                        primaryButtonBackgroundColor="info--bg"
                                                                        primaryButtonOnClick={() => fetchPricingBlockModel(x)}
                                                                        secondaryButtonText="Add Sizes"
                                                                        secondaryButtonBackgroundColor="success--bg"
                                                                        secondaryButtonOnClick={() => {
                                                                            setProductBlockSizeModel({ ...productBlockSizeModel, productPricingBlockId: x.id })
                                                                            setAddSizeBlockModalVisible(true);
                                                                        }}
                                                                        buttonSize="xs"
                                                                    />

                                                                    <CustomBlock className="sizes-block">
                                                                        {
                                                                            x.productBlockSizes &&
                                                                            x.productBlockSizes.map((sample, index) =>
                                                                                <ListItem
                                                                                    key={sample.id}
                                                                                    title={sample.height + "in x " + sample.width + "in"}
                                                                                    className="mini-action-block white--bg"
                                                                                    icon={
                                                                                        <AiFillMinusCircle size={20} className="danger--clr" onClick={() => {
                                                                                            promptSizeRemoveConfirmation(sample.id, x.id)
                                                                                        }} />
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    </CustomBlock>
                                                                </CustomBlock>
                                                            ))
                                                        }
                                                    </CustomBlock>

                                                </Section>

                                            </ContentBlock>
                                        }
                                        <ContentBlock>
                                            {
                                                id > 0 &&
                                                <CustomBlock>
                                                    <CustomBlock className="mt-5 mb-4 pb-3 border-bottom">
                                                        <ContentHeader
                                                            title="Sample Images"
                                                            headerSize="lg"
                                                            primaryButtonText={"Upload Sample Images"}
                                                            primaryButtonBackgroundColor="info--bg"
                                                            primaryButtonOnClick={() => setModalTitle("Upload Sample Image")}
                                                        />
                                                    </CustomBlock>
                                                    {
                                                        product.productImages.length > 0 &&
                                                        <CustomBlock className="mt-2">
                                                            {/* IMAGE THUMBNAILS */}
                                                            <Section removeContainer>
                                                                {
                                                                    product.productImages && product.productImages.map(x => (
                                                                        <ContentBlock key={x.id} cols={4} className="mt-3">
                                                                            <FileUploadBlock
                                                                                key={x.id}
                                                                                clearActionOnClick={() => {
                                                                                    setSelectedImage(x);
                                                                                    setDeleteModalVisible(true)
                                                                                }}
                                                                                path={constants.API_URL + 'Attachments' + x.filePath}
                                                                            />
                                                                            <CheckboxBlock
                                                                                label="Set as preview image"
                                                                                checked={x.isPreview}
                                                                                onClick={(e) => {
                                                                                    productImageService.toggleImagePreview(x.id);
                                                                                    let tempProductImages = [...product.productImages];
                                                                                    let selectedImage = tempProductImages.find(a => a.id === x.id);
                                                                                    selectedImage.isPreview = !selectedImage.isPreview;

                                                                                    setProduct({ ...product, productImages: tempProductImages })
                                                                                }}
                                                                            />

                                                                        </ContentBlock>
                                                                    ))
                                                                }
                                                            </Section>
                                                        </CustomBlock>
                                                    }
                                                </CustomBlock>
                                            }
                                        </ContentBlock>

                                        <ContentBlock>
                                            <CustomBlock className="content-container--actions mt-5">
                                                <Button
                                                    text="Cancel"
                                                    className="danger--bg"
                                                    to="/product-listing"
                                                />

                                                <Button
                                                    type="submit"
                                                    text="Save"
                                                    disabled={formik.isSubmitting || (formik.touched && !formik.isValid)}
                                                    className={formik.isSubmitting || (formik.touched && !formik.isValid) ? "grey--bg" : "primary--bg"}
                                                />
                                            </CustomBlock>
                                        </ContentBlock>
                                    </Section>
                                </FormBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
            {/* ADD NEW PRICE GROUP MODAL */}
            <ModalBlock
                show={addPriceBlockModalVisible}
                contentHeader={(pricingBlockModel.id > 0 ? "Edit" : "Add New") + " Price Block"}
                onHide={handleAddPriceBlockModalClose}
                backdrop="static"
                size="md"
                closeAction
                centered
            >
                <FormBlock onSubmit={pricingBlock_formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock cols={6}>
                            <InputBlock
                                inputLabel="Block Name"
                                placeholder="e.g. Small"
                                iconLeft={
                                    <FiBox size={18} className="grey--clr" />
                                }
                                inputState={`${helper.getInputClasses(pricingBlock_formik, 'name')}`}
                                {...pricingBlock_formik.getFieldProps("name")}
                            />
                        </ContentBlock>

                        <ContentBlock cols={6}>
                            <InputBlock
                                inputLabel="Block Price"
                                placeholder="e.g. 12"
                                iconLeft={
                                    <FaDollarSign size={18} className="grey--clr" />
                                }
                                inputState={`${helper.getInputClasses(pricingBlock_formik, 'price')}`}
                                {...pricingBlock_formik.getFieldProps("price")}
                            />
                        </ContentBlock>

                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={handleAddPriceBlockModalClose}
                            />
                            {
                                pricingBlockModel.id > 0 &&
                                <Button
                                    text="Delete"
                                    onClick={promptBlockRemoveConfirmation}
                                    className="danger--bg"
                                />
                            }
                            <Button
                                type="submit"
                                text={pricingBlockModel.id > 0 ? "Save Changes" : "Add"}
                                disabled={pricingBlock_formik.isSubmitting || (pricingBlock_formik.touched && !pricingBlock_formik.isValid)}
                                className={pricingBlock_formik.isSubmitting || (pricingBlock_formik.touched && !pricingBlock_formik.isValid) ? "grey--bg" : "primary--bg"}
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>

            {/* ADD NEW SIZES */}
            <ModalBlock
                show={addSizeBlockModalVisible}
                contentHeader="Add New Size Block"
                onHide={handleCloseSizeBlockModal}
                backdrop="static"
                size="md"
                closeAction
                centered
            >
                <FormBlock onSubmit={productBlockSize_formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock cols={6}>
                            <InputBlock
                                type="number"
                                inputLabel="Height"
                                iconRight={
                                    <p className="grey--clr mr-5">inches</p>
                                }
                                inputState={`${helper.getInputClasses(productBlockSize_formik, 'height')}`}
                                {...productBlockSize_formik.getFieldProps("height")}
                            />
                        </ContentBlock>

                        <ContentBlock cols={6}>
                            <InputBlock
                                type="number"
                                inputLabel="Width"
                                iconRight={
                                    <p className="grey--clr mr-5">inches</p>
                                }
                                inputState={`${helper.getInputClasses(productBlockSize_formik, 'width')}`}
                                {...productBlockSize_formik.getFieldProps("width")}
                            />
                        </ContentBlock>

                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={handleCloseSizeBlockModal}
                            />

                            <Button
                                type="submit"
                                text="Add"
                                className="primary--bg"
                                disabled={productBlockSize_formik.isSubmitting || (productBlockSize_formik.touched && !productBlockSize_formik.isValid)}
                                className={productBlockSize_formik.isSubmitting || (productBlockSize_formik.touched && !productBlockSize_formik.isValid) ? "grey--bg" : "primary--bg"}
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
        </>
    )
}

export default ProductManagementPage;