// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import { useParams } from "react-router";
import swal from "sweetalert";
import jwtDecode from 'jwt-decode';
import * as auth from '../../modules/Auth/_redux/authRedux';
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import FormBlock from '../../components/FormBlock';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import InputBlock from '../../components/InputBlock';
import SelectBlock from '../../components/SelectBlock';
import CheckboxBlock from '../../components/CheckboxBlock';
import Button from '../../components/Button';
// SERVICES AND HELPERS
import RegistrationValidator from '../../../helpers/validators/RegistrationValidator';
import * as userService from '../../../services/management/userService';
import UpdateAdminValidator from "../../../helpers/validators/UpdateAdminValidator";
// ASSETS

const MyAccountPage = (props) => {
    const { isAdmin, isAuthorized } = useSelector(
        ({ auth }) => ({
            isAdmin: auth.isAdmin,
            isAuthorized: auth.user ? true : false
        }),
        shallowEqual
    );

    const { id } = useParams();
    const { history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [userModel, setUserModel] = useState({});
    const [userId, setUserId] = useState({});

    const options = [
        { value: 'Georgia', label: 'Georgia' },
        { value: 'Louisiana', label: 'Louisiana' },
    ]

    useEffect(() => {
        setUserId(id);
        userService.getUserById(id).then(res => {
            delete res.password;
            delete res.passwordSalt;
            delete res.userName;
            setUserModel(res);
        }).catch(err => {
        })
    }, []);

    const updateUser = (model, setSubmitting) => {
        userService.updateUser(model).then(res => {
            swal("Success!", res.message, "success");
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }
    const updateAdmin = (model, setSubmitting) => {
        userService.updateAdmin(model).then(res => {
            swal("Success!", res.message, "success");
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: userModel,
        validationSchema: UpdateAdminValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            if (userModel.isAdmin) {
                updateAdmin({ ...values, isApproved: true, isAdmin: true }, setSubmitting);
            }
            else {
                updateUser({ ...values, isApproved: true, isAdmin: false }, setSubmitting);
            }
        }
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "error";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "success";
        }

        return "";
    };
    return (
        <>

            {
                isLoading ? <LoadingScreen /> :
                    isAuthorized &&
                    <CustomBlock className="content-padding light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title={!userModel.isApproved ? isAdmin ? 'Account Information' : 'Approve User' : 'Account Information'}
                                    headerSize="md"
                                />

                                <FormBlock onSubmit={formik.handleSubmit}>
                                    <Section removeContainer>
                                        {
                                            !userModel.isAdmin &&
                                            <>
                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        id="businessName"
                                                        inputLabel="Business Name"
                                                        errorMessage={formik.errors.businessName}
                                                        // inputState={!isAdmin ? "disabled" : ""}
                                                        className="mid-grey--clr"
                                                        {...formik.getFieldProps("businessName")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        id="ein"
                                                        inputLabel="EIN Number"
                                                        mask="99-9999999"
                                                        errorMessage={formik.errors.ein}
                                                        // inputState={!isAdmin ? "disabled" : ""}
                                                        className="mid-grey--clr"
                                                        {...formik.getFieldProps("ein")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={3}>
                                                    <InputBlock
                                                        id="primaryContactName"
                                                        inputLabel="Primary Contact Name"
                                                        errorMessage={formik.errors.primaryContactName}
                                                        inputState={`${getInputClasses('primaryContactName')}`}
                                                        {...formik.getFieldProps("primaryContactName")}
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={3}>
                                                    <InputBlock
                                                        id="secondaryContactName"
                                                        inputLabel="Secondary Contact Name"
                                                        placeholder="Optional"
                                                        errorMessage={formik.errors.secondaryContactName}
                                                        inputState={`${getInputClasses('secondaryContactName')}`}
                                                        {...formik.getFieldProps("secondaryContactName")}

                                                    />
                                                </ContentBlock>
                                            </>
                                        }

                                        <ContentBlock cols={userModel.isAdmin ? 6 : 3}>
                                            <InputBlock
                                                id="emailAddress"
                                                inputLabel="Email Address"
                                                errorMessage={formik.errors.emailAddress}
                                                inputState={`${getInputClasses('emailAddress')}`}
                                                {...formik.getFieldProps("emailAddress")}
                                            />
                                        </ContentBlock>

                                        {
                                            !userModel.isAdmin &&
                                            <ContentBlock cols={3}>
                                                <InputBlock
                                                    id="phoneNumber"
                                                    inputLabel="Phone Number"
                                                    mask="(999) 999-9999"
                                                    errorMessage={formik.errors.phoneNumber}
                                                    inputState={`${getInputClasses('phoneNumber')}`}
                                                    {...formik.getFieldProps("phoneNumber")}
                                                />
                                            </ContentBlock>
                                        }

                                        {
                                            !userModel.isAdmin &&
                                            <>
                                                <ContentBlock>
                                                    <ContentHeader
                                                        title="Shipping Information"
                                                        headerSize="md"
                                                        className="mt-5"
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        inputLabel="Address 1"
                                                        placeholder="e.g. 100 Street Name"
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={6}>
                                                    <InputBlock
                                                        inputLabel="Address 2"
                                                        placeholder="e.g. Suite A"
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={4}>
                                                    <InputBlock
                                                        inputLabel="City"
                                                        placeholder="e.g. Baton Rouge"
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={4}>
                                                    <InputBlock
                                                        inputLabel="Zip Code"
                                                        placeholder="e.g. 70810"
                                                        mask="99999"
                                                    />
                                                </ContentBlock>

                                                <ContentBlock cols={4}>
                                                    <SelectBlock
                                                        // altSelect
                                                        inputLabel="State"
                                                        options={options}
                                                    />
                                                </ContentBlock>
                                            </>
                                        }

                                        <CustomBlock className="button-container--flex-end w-100">
                                            <Button
                                                text='Cancel'
                                                onClick={() => history.goBack()}
                                                className="danger--bg fit-content"
                                            />
                                            <Button
                                                type="submit"
                                                text='Save Changes'
                                                className="primary--bg mr-1"
                                            />

                                        </CustomBlock>
                                    </Section>
                                </FormBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
        </>
    );
}

export default connect(null, { ...auth.actions })(MyAccountPage);