// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../components/CustomBlock';
import Button from '../components/Button';
// ASSETS

const Banner = (props) => {

    const bannerContentXPosition = {
        left: "left-content",
        center: "center-content",
        right: "right-content"
    }

    const titleSize = {
        md: "--md",
        lg: "--lg",
        xl: "--xl"
    }

    return (
        <>
            <CustomBlock
                className={`banner ${props.contentXPosition ? bannerContentXPosition[props.contentXPosition] : "center-content"}`}
                style={{ background: `url(/content/${props.image})` }}
            >
                <Fade

                    triggerOnce
                    delay={1400}
                    duration={800}
                >
                    <CustomBlock className="banner-content-container">
                        <CustomBlock className={`banner-content ${props.bannerContentClassName ? props.bannerContentClassName : ""}`}>
                            <h3 className={`banner-title${props.titleSize ? titleSize[props.titleSize] : "--md"}`}>
                                {props.title}
                            </h3>
                            {
                                props.description ?
                                    <h4 className="banner-description">
                                        {props.description}
                                    </h4> : <></>
                            }
                        </CustomBlock>
                        <CustomBlock className={`banner-content-button-container ${props.contentXPosition ? bannerContentXPosition[props.contentXPosition] : "center-content"}`}>
                            {
                                props.buttonOneText ?
                                    <Button
                                        text={props.buttonOneText}
                                        className={props.buttonOneClassName}
                                        to={props.buttonOneTo ? props.buttonOneTo : "/"}
                                    /> : <></>
                            }
                            {
                                props.buttonTwoText ?
                                    <Button
                                        text={props.buttonTwoText}
                                        className={props.buttonTwoClassName}
                                        to={props.buttonTwoTo ? props.buttonTwoTo : "/"}
                                    /> : <></>
                            }
                        </CustomBlock>
                    </CustomBlock>
                </Fade>
            </CustomBlock>
        </>
    );
}

export default Banner;