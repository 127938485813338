// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentBanner from '../../components/ContentBanner';
import FloatingCard from '../../components/FloatingCard';
import IFrame from '../../components/IFrame';
import Image from '../../components/Image';
import Button from '../../components/Button';
// ASSETS
import StaffPhoto from '../../assets/img/content/photo_daryl-and-sons.jpg';
import TigersSeasonPhoto from '../../assets/img/content/photo_tigers-season-2020.jpg';
import FramedShelfPhoto from '../../assets/img/content/photo_framed-shelf.jpg';
import ShopDisplayFramesPhoto from '../../assets/img/content/photo_shop-display-frames.jpg';

const AboutPage = (props) => {

    const sampleFramesVideo = 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F161657321840173%2F&show_text=false&width=560';
    const frameMagicVideo = 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F982917512125393%2F&show_text=false&width=560';
    const shopPreviewVideo = "https://www.facebook.com/plugins/video.php?height=420&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F924675037962183%2F&show_text=false&width=560";
    const facebookImageLinkStaff = 'https://www.facebook.com/darylandsons/photos/a.294831944308/10158549169599309/?type=3&theater';
    const facebookImageLinkTigers = 'https://www.facebook.com/darylandsons/photos/a.10152834110734309/10158118702179309/?type=3&theater';

    const iframeLinks = [
        { id: 1, url: 'https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F688863458322798%2F&show_text=false&width=560' },
        { id: 2, url: 'https://www.facebook.com/plugins/video.php?height=317&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F521931021829635%2F&show_text=false&width=560' },
        { id: 3, url: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F536883203639570%2F&show_text=false&width=560' },
        { id: 4, url: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F576077059676302%2F&show_text=false&width=560' },
        { id: 5, url: 'https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fdarylandsons%2Fvideos%2F982917512125393%2F&show_text=false&width=560' },
    ]

    return (
        <CustomBlock className="">

            <ContentBanner
                title="Daryl and Sons—Your Affordable Frame Shop"
                description="We have been providing the Greater Baton Rouge area with custom framing options for over 30 years. We are a family owned and operated buisness serving you with the highest quality framing to display your most treasured keepsakes and artwork. Our staff will help you find the perfect frame for your item, whether you wish to display an American Flag, or sports memorabilia, we have an option that will work perfectly in your home. All of our custom frames are made with the highest quality materials and expert craftsmanship."
                image="group-pic.jpg"
                contentXPosition="right"
                backgroundPosition="top"
                buttonOneText="Contact Us"
                buttonOneClassName="primary--bg"
                buttonOneTo="/contact"
                buttonTwoText="Get Started"
                buttonTwoClassName="secondary--bg"
                buttonTwoTo="/get-started"
            />

            {/* <CustomBlock className="frame-container">
                <CustomBlock className="inner-frame">
                    <Section>
                        <Fade

                            triggerOnce
                            duration={800}
                            delay={400}
                            className="w-100"
                        >
                            <CustomBlock className="section-text-container--padded">
                                <h3 className="section-text--lg header">
                                    About Us
                                </h3>
                            </CustomBlock>
                        </Fade>
                        <Fade
                            triggerOnce
                            direction="up"
                            delay={600}
                            className="w-100"
                        >
                            <h3 className="section-text--md">
                                We offer a variety of products and services to help you create the perfect display for your item. From mat cutting, and ready made frames, to custom frames&mdash;at Daryl & Sons Picture Framing, we have your framing solution
                            </h3>
                        </Fade>
                    </Section>
                </CustomBlock>
            </CustomBlock> */}

            <CustomBlock className="content-section-padding pb-0">
                <Section className="iframe-section">
                    <ContentBlock cols={6}>
                        <Fade
                            triggerOnce
                            duration={800}
                            delay={1000}
                        >
                            <IFrame
                                source={sampleFramesVideo}
                                style={{ marginRight: 30 }}
                            />
                        </Fade>
                    </ContentBlock>

                    <ContentBlock cols={6}>
                        <Fade
                            triggerOnce
                            duration={800}
                            delay={1000}
                        >
                            <IFrame
                                source={shopPreviewVideo}
                                style={{ marginLeft: 30 }}
                            />
                        </Fade>
                    </ContentBlock>
                </Section>
            </CustomBlock>

            <CustomBlock className="frame-container iframe-text-container">
                <CustomBlock className="inner-frame">
                    <Section>
                        <Fade
                            triggerOnce
                            duration={800}
                            delay={1400}
                            className="w-100"
                        >
                            <h3 className="section-text--md">
                                We offer a variety of products and services to help you create the perfect display for your item. From mat cutting, and ready made frames, to custom frames&mdash;at Daryl & Sons Picture Framing, we have your framing solution.
                            </h3>
                        </Fade>

                        {/* <Fade
                            triggerOnce
                            duration={600}
                            delay={1000}
                            className="w-100"
                        >
                            <CustomBlock className="button-container">
                                <Button
                                    text="Shop Frames"
                                    to="/frames"
                                    className="primary--bg"
                                    size="sm"
                                />
                            </CustomBlock>
                        </Fade> */}
                    </Section>
                </CustomBlock>
            </CustomBlock>

            <CustomBlock className="">
                <Section>
                    <ContentBlock cols={5}>
                        {/* <Image
                            source={StaffPhoto}
                            roundCorners
                            to={{ pathname: facebookImageLinkStaff }}
                            target="_blank"
                        /> */}
                    </ContentBlock>

                    <ContentBlock cols={7}>
                        {/* <CustomBlock>
                            <Image
                                source={TigersSeasonPhoto}
                                roundCorners
                                to={{ pathname: facebookImageLinkTigers }}
                                target="_blank"
                            />
                        </CustomBlock> */}

                        {/* <CustomBlock>
                            <Image
                                source={FramedShelfPhoto}
                                roundCorners
                                to={{ pathname: facebookImageLinkTigers }}
                                target="_blank"
                            />

                            <Image
                                source={ShopDisplayFramesPhoto}
                                roundCorners
                                to={{ pathname: facebookImageLinkTigers }}
                                target="_blank"
                            />
                        </CustomBlock> */}
                    </ContentBlock>
                </Section>
            </CustomBlock>

            <FloatingCard />
        </CustomBlock>
    );
}

export default AboutPage;