// DEPENDENCIES
import React, { useEffect, useState } from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import ContentHeader from '../../components/ContentHeader';
import InputBlock from '../../components/InputBlock';
import SelectBlock from '../../components/SelectBlock';
import TotalsBlock from '../../components/TotalsBlock';
import Button from '../../components/Button';
// PAGES
import ShippingInfoPage from './checkout/ShippingInfoPage';
import ShippingSpeedPage from './checkout/ShippingSpeedPage';


const CheckOutPage = (props) => {
    const [proceed, setProceed] = useState(false);
    const [showShippingSpeed, setShowShippingSpeed] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <CustomBlock className="content-container--padded light--bg">
                <Section className="x-small pl-0 pr-0">

                    {/* SHIPPING */}
                    <CustomBlock className="content-container--card-style mt-4">
                        {
                            showShippingSpeed ?
                                <ShippingSpeedPage /> : <ShippingInfoPage />
                        }
                    </CustomBlock>

                    {/* CART ACTIONS AND TOTALS */}
                    <TotalsBlock
                        position="center"
                        size="full"
                        lineOneCost={2}
                        lineTwoCost={"$180.00"}
                        lineThreeCost={"$22.50"}
                        lineFourCost={"$18.00"}
                        mainLineCost={"180"}
                        primaryButtonText="Continue"
                        primaryButtonClassName="light-purple--bg"
                        primaryButtonSize="sm"
                        primaryButtonOnClick={() => setShowShippingSpeed(true)}
                    />
                </Section>
            </CustomBlock>
        </>
    );
}

export default CheckOutPage;