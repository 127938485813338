// DEPENDENCIES
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
// ICONS
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import FormBlock from '../../components/FormBlock';
import ContentBanner from '../../components/ContentBanner';
import FloatingCard from '../../components/FloatingCard';
import InputBlock from '../../components/InputBlock';
import TextAreaBlock from '../../components/TextAreaBlock';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Overlay from '../../components/Overlay';
// SERVICES AND HELPERS
import constants from '../../../constants/constants';
import * as helper from '../../../helpers/helper';
// ASSETS
import * as emailService from '../../../services/email/emailService';
import LoadingScreen from '../../components/LoadingScreen';

const initialContactForm = {
  name: '',
  phoneNumber: '',
  emailAddress: '',
  subject: '',
  message: ''
};

const ContactPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [contactForm, setContactForm] = useState(initialContactForm);

  const formik = useFormik({
    initialValues: contactForm,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required').max(250),
      phoneNumber: Yup.string().required('Phone Number is required').max(20),
      emailAddress: Yup.string().email().required('Email is required').max(250),
      subject: Yup.string().required('Subject is required').max(500),
      message: Yup.string().required('Message is required').max(5000),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      sendEmail(values);
    },
  });

  const sendEmail = (model) => {
    setIsLoading(true);

    emailService.sendContactEmail(model).then((res) => {
      setContactForm(initialContactForm);
      formik.resetForm();
      swal('Sent!', "Your message has been successfully sent!", 'success');
    }).catch((ex) => {
      swal('Error!', "Something went wrong. Please try again.", 'error');
    }).finally(() => {
      setIsLoading(false);
    });
  };


  return (
    <CustomBlock className="">
   {isLoading && <Overlay hasLoader />}
      <ContentBanner
        title="Contact Us"
        description="We offer a variety of products and services to help you create the perfect display for your item. From mat cutting, and ready made frames, to custom frames&mdash;at Daryl & Sons Picture Framing, we have your framing solution."
        image="photo_tigers-season-2020.jpg"
        contentXPosition="right"
        buttonOneText="Give Us A Call"
        buttonOneClassName="info--bg"
        // buttonOneHref={`tel:${constants.PHONE_NUMBER}`}
        buttonTwoText="Vist Us Today"
        buttonTwoClassName="success--bg"
        // buttonTwoTo={{ pathname: 'https://www.google.com/maps/dir/30.4676864,-90.9574144/30.39507,-91.10853/@30.4327073,-91.1051443,12z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0' }}
        // buttonTwoTarget="_blank"
        buttonOneOnClick={(e) => {
          window.location.href = `tel:${constants.PHONE_NUMBER}`;
          e.preventDefault();
        }}
        buttonTwoOnClick={() => {
          window.open('https://www.google.com/maps/dir/30.4676864,-90.9574144/30.39507,-91.10853/@30.4327073,-91.1051443,12z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0', '_blank');
        }}
      />

      <CustomBlock className="contact-form-container">
        <Fade
          triggerOnce
          direction="up"
          duration={800}
          className="contact-form-block"
        >
          <CustomBlock className="">
            <h2 className="form-header">
              Send us a message
            </h2>
            <FormBlock>
              <CustomBlock className="contact-input-group">
                <InputBlock
                  inputLabel="Full Name"
                  placeholder="Enter Your Name"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.name}
                  inputState={helper.getInputClasses(formik, 'name')}
                  {...formik.getFieldProps('name')}
                />
                <InputBlock
                  inputLabel="Phone Number"
                  placeholder="Enter Your Phone Number"
                  mask="(999) 999-9999"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.phoneNumber}
                  inputState={helper.getInputClasses(formik, 'phoneNumber')}
                  {...formik.getFieldProps('phoneNumber')}
                />
              </CustomBlock>

              <CustomBlock className="contact-input-group">
                <InputBlock
                  inputLabel="Email Address"
                  placeholder="Enter Your Email Address"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.emailAddress}
                  inputState={helper.getInputClasses(formik, 'emailAddress')}
                  {...formik.getFieldProps('emailAddress')}
                />
                <InputBlock
                  inputLabel="Subject"
                  placeholder="Enter Your Subject"
                  containerClassName="input"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.subject}
                  inputState={helper.getInputClasses(formik, 'subject')}
                  {...formik.getFieldProps('subject')}
                />
              </CustomBlock>


              <CustomBlock className="contact-input-group">
                <TextAreaBlock
                  inputLabel="Message"
                  id="message"
                  placeholder="Enter Your Message"
                  containerClassName="textarea"
                  backgroundColor="lighter--bg"
                  bordered
                  errorMessage={formik.errors.message}
                  inputState={`${helper.getInputClasses(formik, 'message')}`}
                  {...formik.getFieldProps("message")}
                />
              </CustomBlock>

              <CustomBlock className="button-container--flex-end">
                <Button
                  text="Send Message"
                  className="secondary--bg mr-1"
                  size="xs"
                  onClick={formik.handleSubmit}
                />
              </CustomBlock>
            </FormBlock>
          </CustomBlock>
        </Fade>
      </CustomBlock>
    </CustomBlock>
  );
}

export default ContactPage;