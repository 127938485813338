// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';

const CardBlock = (props) => {

    const cardSizes = {
        small: "--sm",
        large: "--lg",
        default: ""
    }

    return (
        <Link
            onClick={props.onClick ? props.onClick : () => { }}
            to={props.to ? props.to : ""}
            className={`card-block${props.size ? cardSizes[props.size] : cardSizes.default}`}
        >
            <CustomBlock className="image-container">
                <Link {...props}>
                    <Image
                        source={props.imageSource}
                    />
                </Link>
            </CustomBlock>

            <CustomBlock className="text-container">
                <CustomBlock className="header">
                    <h4 className={`title${props.size ? cardSizes[props.size] : cardSizes.default}`}>
                        {props.title} 
                    </h4>

                    <h4 className={`title${props.size ? cardSizes[props.size] : cardSizes.default}`}>
                        {props.additionalText}
                    </h4>
                </CustomBlock>
            </CustomBlock>
        </Link>
    );
}

export default CardBlock;