// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { useFormik } from 'formik';
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
import { HiCheck, HiDesktopComputer, HiOutlineMail } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
// CUSTOM COMPONENTS
import * as auth from '../../../modules/Auth/_redux/authRedux';
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import Button from '../../../components/Button';
import ModalBlock from '../../../components/ModalBlock';
import InputBlock from '../../../components/InputBlock';
// SERVICES 
import * as settingService from '../../../../services/setting/settingService';
//HELPERS
import SettingValidator from '../../../../helpers/validators/SettingValidator';
import * as helper from '../../../../helpers/helper';

var initialSetting = {
    id: 0,
    key: '',
    value: ''
}

const SettingsPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [settings, setSettings] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [settingsModel, setSettingsModel] = useState(initialSetting);

    useEffect(() => {
        getAllSettings();
    }, [refresh]);

    const getAllSettings = () => {
        setIsLoading(true);
        settingService.getSettings().then(res => {
            setSettings(res);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: settingsModel,
        validationSchema: SettingValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            //setIsLoading(true);
            saveInfo(values, setSubmitting);
        }
    });

    const handleModalClose = () => {
        setModalVisible(false);
        setDeleteModalVisible(false);
        setSettingsModel(initialSetting);
        formik.resetForm();
    }

    const saveInfo = (model, setSubmitting) => {
        settingService.saveSetting({...model}).then(res => {
            setRefresh(!refresh);
            swal("Success!", res.message, "success");
            handleModalClose();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const editSetting = (settingId) => {
        setModalVisible(true);

        settingService.getSettingById(settingId).then(res => {
            setSettingsModel({ ...settingsModel, id: res.id, key: res.key, value: res.value });
        })
    }

    const deleteSetting = (settingId) => {
        settingService.deleteSetting(settingId).then(res => {
            setRefresh(!refresh);
            handleModalClose();
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const columns = [
        {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                className="light-purple--bg fit-content ml-0"
                                text="Edit"
                                size="xs"
                                onClick={() => editSetting(settings[dataIndex].id)}
                            />
                            <Button
                                className="danger--bg fit-content"
                                text="Delete"
                                size="xs"
                                onClick={() => {
                                    setDeleteModalVisible(true);
                                    setSettingsModel(settings[dataIndex]);
                                }}
                            />
                        </CustomBlock>
                    );
                }
            }
        },
        {
            name: "key",
            label: "Key",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "value",
            label: "Value",
            options: {
                filter: true,
                sort: true
            }
        },
    ]

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Settings"
                                    headerSize="mdlg"
                                    primaryButtonText="Add Settings"
                                    primaryButtonOnClick={() => {
                                        setModalVisible(true);
                                    }}
                                />
                                <ContentBlock>
                                    <MUIDataTable
                                        data={settings}
                                        columns={columns}
                                        options={{
                                            selectableRows: "none"
                                        }}
                                    />
                                </ContentBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
            <ModalBlock
                show={modalVisible}
                onHide={handleModalClose}
                contentHeader="Edit Settings"
                closeAction
                centered
                size="md"
            >
                <FormBlock onSubmit={formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock>
                            <InputBlock
                                id="key"
                                inputLabel="Key"
                                errorMessage={formik.errors.emailAddress}
                                inputState={`${helper.getInputClasses(formik, 'key')}`}
                                {...formik.getFieldProps("key")}
                            />
                            <InputBlock
                                id="value"
                                inputLabel="Value"
                                errorMessage={formik.errors.emailAddress}
                                inputState={`${helper.getInputClasses(formik, 'value')}`}
                                {...formik.getFieldProps("value")}
                            />
                        </ContentBlock>

                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={() => {
                                    handleModalClose();
                                }}
                            />

                            <Button
                                type="submit"
                                text="Save"
                                className="primary--bg"
                                disabled={formik.isSubmitting}
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
            <ModalBlock
                show={deleteModalVisible}
                onHide={handleModalClose}
                contentHeader="Delete Setting"
                closeAction
                centered
                contentDescription="Are you sure you want to remove this setting?"

            >
                <CustomBlock className="content-container--actions mt-0">
                    <Button
                        text="Cancel"
                        className="grey--bg"
                        onClick={handleModalClose}
                    />
                    <Button
                        type="submit"
                        text="Delete"
                        className="danger--bg"
                        onClick={() => {
                            setIsLoading(true)
                            setDeleteModalVisible(false)
                            deleteSetting(settingsModel.id)
                        }}
                    />
                </CustomBlock>
            </ModalBlock>
        </>
    );
}

export default connect(null, { ...alert.actions })(SettingsPage);