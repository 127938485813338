// DEPENDECIES
import React, { useState } from 'react';
// COMPONENTS
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ContentHeader from '../../../components/ContentHeader';
import SelectBlock from '../../../components/SelectBlock';
import RadioButton from '../../../components/RadioButton';

const ShippingInfoPage = (props) => {

    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [isSelected, setIsSelected] = useState(false);
    const shippingOptions = [
        { id: 0, label: 'Standard: FREE' },
        { id: 1, label: 'Expedited: $22.50' },
    ]

    return (
        <Section removeContainer>
            <ContentHeader
                title="Shipping Speed"
            />

            <ContentBlock>
                {
                    shippingOptions.map((option, index) =>
                        <RadioButton
                            onClick={() => setSelectedOptionIndex(index)}
                            label={option.label}
                            selected={index == selectedOptionIndex}
                        />
                    )
                }
            </ContentBlock>
        </Section>
    );
}

export default ShippingInfoPage;