import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Cart/";

const getCart = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetCart', constants.REQUIRE_INTERCEPTORS);

const getNumberOfItemsInCart = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetNumberOfItemsInCart', constants.REQUIRE_INTERCEPTORS);

const addToCart = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'AddToCart', model, constants.REQUIRE_INTERCEPTORS);

const removeItem = async (itemId) => await axiosInstance.delete(ENDPOINTORIGIN + 'RemoveItem?itemId=' + itemId, constants.REQUIRE_INTERCEPTORS);

const saveSpecialInstructions = async (itemId, text) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveSpecialInstructions?itemId=' + itemId + '&instructions=' + text, {}, constants.REQUIRE_INTERCEPTORS);

const applyPromoCode = async (code) => await axiosInstance.post(ENDPOINTORIGIN + 'ApplyPromoCode?code=' + code, {}, constants.REQUIRE_INTERCEPTORS);

const clearPromoCode = async () => await axiosInstance.post(ENDPOINTORIGIN + 'ClearPromoCode', {}, constants.REQUIRE_INTERCEPTORS);

export {
    getCart,
    getNumberOfItemsInCart,
    addToCart,
    removeItem,
    saveSpecialInstructions,
    applyPromoCode,
    clearPromoCode
}