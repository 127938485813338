// DEPENDENCIES
import React, { useEffect, useState } from 'react';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import ModalBlock from '../../../components/ModalBlock';
import ContentHeader from '../../../components/ContentHeader';
import InputBlock from '../../../components/InputBlock';
import Button from '../../../components/Button';
// SERVICES AND HELPERS

const ProductOptions = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);

    const columnOptions = {
        filter: true,
        sort: true,
        print: false,
        download: false
    };

    const options = {
        selectableRows: "none",
        download: false,
        print: false,
    };

    const columns = [
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                text="View"
                                className="light-purple--bg fit-content ml-0"
                                size="xs"
                                to={data[dataIndex].link}
                            />
                        </CustomBlock>
                    )
                }
            }

        },
        {
            name: "name",
            label: "Option Name",
            options: columnOptions
        }
    ];

    const data = [
        {
            name: "Mat Colors",
            link: '/mat-color-options'
        },
        {
            name: "Frames",
            link: '/frame-options'
        },
        {
            name: "Promo Codes",
            link: '/promo-codes'
        }
    ];

    return (
        <>
            <CustomBlock className="content-container--padded light--bg">
                <Section>
                    <CustomBlock className="content-container--card-style">
                        <ContentHeader
                            title="Product Options"
                            headerSize="lg"
                        />
                        <CustomBlock>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </CustomBlock>
                    </CustomBlock>
                </Section>
            </CustomBlock>

            {/* CONFIRM DELETE MODAL */}
            <ModalBlock
                show={confirmDeleteModalVisible}
                contentHeader="Delete Setting"
                onHide={() => setConfirmDeleteModalVisible(false)}
                closeAction
                centered
                contentDescription="Are you sure you want to delete this setting?"
                primaryModalActionOnClick={() => console.log("Deleting...")}
            />

            {/* CREATE NEW ITEM MODAL */}
            <ModalBlock
                show={modalVisible}
                contentHeader="Create Setting"
                onHide={() => setModalVisible(false)}
                backdrop="static"
                size="lg"
                closeAction
                centered
            >
                <Section removeContainer>
                    <ContentBlock cols={6}>
                        <InputBlock
                            inputLabel="Setting Name"
                        />
                    </ContentBlock>

                    <ContentBlock cols={6}>
                        <InputBlock
                            inputLabel="Value"
                        />
                    </ContentBlock>

                    <CustomBlock className="content-container--actions">
                        <Button
                            text="Cancel"
                            className="danger--bg"
                            onClick={() => setModalVisible(false)}
                        />

                        <Button
                            type="submit"
                            text="Create"
                            className="primary--bg"
                        />
                    </CustomBlock>
                </Section>
            </ModalBlock>
        </>
    );
}

export default ProductOptions;
