import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "PromoCode/";

const getPromoCodes = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetPromoCodes', constants.REQUIRE_INTERCEPTORS);

const getPromoCodeById = async (id) => await axiosInstance.get(ENDPOINTORIGIN +'GetPromoCodeById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const savePromoCode = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePromoCode', model, constants.REQUIRE_INTERCEPTORS);

const deletePromoCode = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeletePromoCode?id='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    getPromoCodes,
    getPromoCodeById,
    savePromoCode,
    deletePromoCode
}