// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { IoClose } from 'react-icons/io5';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import TextHeader from './TextHeader';
import Button from './Button';

const ContentHeader = (props) => {

    return (
        <>
            <CustomBlock className={`content-header ${props.centeredTitle ? "flex-center" : ""} ${props.containerClassName ? props.containerClassName : ""}`}>
                <TextHeader
                    // {...props}
                    title={props.title}
                    subtitle={props.subtitle}
                    size={props.headerSize}
                />
                <CustomBlock className="content-header-button-actions">
                    {
                        props.secondaryButtonText &&
                        <Button
                            text={props.secondaryButtonText}
                            to={props.secondaryButtonTo}
                            onClick={props.secondaryButtonOnClick ? props.secondaryButtonOnClick : () => { }}
                            size={props.buttonSize}
                            openInNewTab={props.openSecondaryLinkInNewTab}
                            className={props.secondaryButtonBackgroundColor ? props.secondaryButtonBackgroundColor : "secondary--bg"}
                            uppercaseText={props.uppercaseButtonText}
                            loading={props.secondaryButtonLoading}
                            iconLeft={props.secondaryButtonIconLeft}
                        />
                    }

                    {
                        props.primaryButtonText &&
                        <Button
                            text={props.primaryButtonText}
                            to={props.primaryButtonTo}
                            onClick={props.primaryButtonOnClick ? props.primaryButtonOnClick : () => { }}
                            openInNewTab={props.openPrimaryLinkInNewTab}
                            className={props.primaryButtonBackgroundColor ? props.primaryButtonBackgroundColor : "primary--bg"}
                            size={props.buttonSize}
                            uppercaseText={props.uppercaseButtonText}
                            loading={props.primaryButtonLoading}
                            iconLeft={props.primaryButtonIconLeft}
                        />
                    }
                </CustomBlock>
                {
                    props.headerIcon &&
                    <CustomBlock className={`content-header-icon-actions ${props.headerIconContainerClass ? props.headerIconContainerClass : ""}`}>
                        {
                            props.headerIconTo ?
                                <Link
                                    to={props.headerIconTo ? props.headerIconTo : "#"}
                                    onClick={props.headerIconOnClick ? props.headerIconOnClick : () => { }}
                                >
                                    {props.headerIcon}
                                </Link>
                                :
                                <button
                                    onClick={props.headerIconOnClick ? props.headerIconOnClick : () => { }}
                                >
                                    {props.headerIcon}
                                </button>
                        }
                    </CustomBlock>
                }
            </CustomBlock>
        </>
    );
}

export default ContentHeader;