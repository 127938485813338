import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Order/";

const getOrders = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetOrders', constants.REQUIRE_INTERCEPTORS);

const placeOrder = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'PlaceOrder', model, constants.REQUIRE_INTERCEPTORS);

const getOrderDetails = async (orderNumber) => await axiosInstance.get(ENDPOINTORIGIN + 'GetOrderDetails?orderNumber=' + orderNumber, constants.REQUIRE_INTERCEPTORS);

const assignTrackingNumber = async (orderNumber, trackingNumber) => await axiosInstance.post(ENDPOINTORIGIN + 'AssignTrackingNumber?orderNumber=' + orderNumber + "&trackingNumber="  + trackingNumber, {}, constants.REQUIRE_INTERCEPTORS);

export {
    getOrders,
    placeOrder,
    getOrderDetails,
    assignTrackingNumber
}