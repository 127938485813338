// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useFormik } from "formik";
import swal from 'sweetalert';
import { useLocation } from "react-router";
import jwtDecode from 'jwt-decode';
// COMPONENTS
// ICONS
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
import { HiOutlineMail } from 'react-icons/hi';
// CUSTOM COMPONENTS
import Section from '../../components/Section';
import ContentHeader from '../../components/ContentHeader';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import FormBlock from '../../components/FormBlock';
import InputBlock from '../../components/InputBlock';
import Button from '../../components/Button';
// SERVICES AND HELPERS
import * as helper from '../../../helpers/helper';
import * as userService from '../../../services/management/userService';
import SetupPasswordValidator from '../../../helpers/validators/SetupPasswordValidator';
import * as alert from '../../../redux/alertSnackBarRedux';


const SetupUserPasswordPage = (props) => {
    const useQuery = () => new URLSearchParams(useLocation().search);
    const { match, history } = props;
    const [id, setId] = useState(useQuery().get('id'));
    const [token, setToken] = useState(useQuery().get('token'));
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userToSetPassword, setUserToSetPassword] = useState({});
    const [setupPasswordModel, setSetupPasswordModel] = useState({
        emailAddress: '',
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        if(token)
        {
            let localUserId = jwtDecode(token).UserId;
            userService.getUserById(localUserId).then(res => {
                setUserToSetPassword(res);
            });
        }
        if(id)
        {
            userService.getUserById(id).then(res => {
                setUserToSetPassword(res);
            });
        }
    }, [])

    const setPasswordForUser = (model, setSubmitting) => {
        userService.setupPassword(model).then(res => {
            swal("Success!", res.message, "success");
            history.push('/login');
        }).catch((ex) => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
            setSubmitting(false);
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...setupPasswordModel, emailAddress: userToSetPassword.emailAddress },
        validationSchema: SetupPasswordValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setIsLoading(true);
            setPasswordForUser({ ...userToSetPassword, password: values.password }, setSubmitting)
        },
    });

    return (
        <CustomBlock className="authenticate-page">
            <Section rowClassName="flex-center">
                <CustomBlock className="content-container--card-style width-50">
                    <ContentHeader
                        title="Setup Account"
                        headerSize="md"
                    />

                    <FormBlock onSubmit={formik.handleSubmit}>
                        <Section removeContainer>
                            <ContentBlock>
                                <InputBlock
                                    id="email"
                                    inputLabel="Email Address"
                                    errorMessage={formik.errors.emailAddress}
                                    inputState="disabled"
                                    className="mid-grey--clr"
                                    {...formik.getFieldProps("emailAddress")}
                                    iconLeft={
                                        <HiOutlineMail size={20} className="mid-grey--clr" />
                                    }
                                />
                            </ContentBlock>

                            <ContentBlock>
                                <InputBlock
                                    id="password"
                                    type={passwordVisible ? "text" : "password"}
                                    inputLabel="Password"
                                    iconLeft={
                                        <FiLock size={20} className="mid-grey--clr" />
                                    }
                                    iconRight={
                                        passwordVisible ? <FiEye className="light-purple--clr" /> : <FiEyeOff className="light-purple--clr" />
                                    }
                                    iconRightOnClick={() => setPasswordVisible(!passwordVisible)}
                                    errorMessage={formik.errors.password}
                                    inputState={`${helper.getInputClasses(formik, 'password')}`}
                                    {...formik.getFieldProps("password")}
                                />
                            </ContentBlock>

                            <ContentBlock>
                                <InputBlock
                                    id="confirmPassword"
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    inputLabel="Confirm Password"
                                    iconLeft={
                                        <FiLock size={20} className="mid-grey--clr" />
                                    }
                                    iconRight={
                                        confirmPasswordVisible ? <FiEye className="light-purple--clr" /> : <FiEyeOff className="light-purple--clr" />
                                    }
                                    iconRightOnClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    errorMessage={formik.errors.confirmPassword}
                                    inputState={`${helper.getInputClasses(formik, 'confirmPassword')}`}
                                    {...formik.getFieldProps("confirmPassword")}

                                />
                            </ContentBlock>

                            <CustomBlock className="content-container--actions">
                                <Button
                                    type="submit"
                                    text="Submit"
                                    className="primary--bg"
                                />
                            </CustomBlock>
                        </Section>
                    </FormBlock>
                </CustomBlock>
            </Section>
        </CustomBlock>
    );
}

export default connect(null, null)(SetupUserPasswordPage);