// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Fade } from 'react-awesome-reveal';
// CUSTOM COMPONENTS
import CustomBlock from '../components/CustomBlock';
import Button from '../components/Button';

const FloatingCard = (props) => {

    return (
        <CustomBlock className="floating-card-container">
            <Fade
                triggerOnce
                direction="up"
                duration={800}
                delay={600}
                className="animated-container"
            >
                <CustomBlock className="floating-card">
                    <h3 className="title">
                        Ready to Start Framing?
                    </h3>

                    <h4 className="description">
                        Frame your most treasured memories, artworks, paintings, posters and more today.
                    </h4>

                    <Button
                        text="Get Started"
                        className="secondary--bg"
                        to="/get-started"
                        size="sm"
                    />
                </CustomBlock>
            </Fade>
        </CustomBlock>
    );
}

export default FloatingCard;