// DEPENDENCIES
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
import { HiCheck, HiDesktopComputer, HiOutlineMail } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi';
// CUSTOM COMPONENTS
import * as auth from '../../../modules/Auth/_redux/authRedux';
import LoadingScreen from '../../../components/LoadingScreen';
import Section from '../../../components/Section';
import ContentBlock from '../../../components/ContentBlock';
import CustomBlock from '../../../components/CustomBlock';
import FormBlock from '../../../components/FormBlock';
import ContentHeader from '../../../components/ContentHeader';
import Button from '../../../components/Button';
import ModalBlock from '../../../components/ModalBlock';
import InputBlock from '../../../components/InputBlock';
// SERVICES 
import * as productService from '../../../../services/product/productService';
//HELPERS
import * as helper from '../../../../helpers/helper';

const ProductListingPage = (props) => {
    const { showAlert, history } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [productModel, setProductModel] = useState({
        id: 0,
        name: '',
    });

    const deleteProduct = (id) => {
        productService.deleteProduct(id).then(res => {
            swal("Success!", res.message, "success");
            getProducts();
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getProducts();
    }, [])

    const getProducts = () => {
        setIsLoading(true);
        productService.getAllProducts().then(res => {
            setProducts(res);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const columns = [
        {
            name: 'id',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                text="View"
                                size="xs"
                                className="light-purple--bg fit-content ml-0"
                                to={"/product-management/" + products[dataIndex].id}
                            />
                            <Button
                                text="Delete"
                                className="danger--bg fit-content"
                                size="xs"
                                type="button"
                                onClick={() => {
                                    setModalVisible(true);
                                    setSelectedProduct(products[dataIndex]);    
                                }}
                            />
                        </CustomBlock>
                    );
                }
            }
        },
        {
            name: "name",
            label: "Product Name",
            options: {
                filter: true,
                sort: true
            }
        }
    ]

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Products"
                                    headerSize="lg"
                                    primaryButtonText="Add Product"
                                    primaryButtonTo={"/product-management/0"}
                                />
                                <ContentBlock>
                                    <MUIDataTable
                                        data={products}
                                        columns={columns}
                                        options={{
                                            selectableRows: "none"
                                        }}
                                    />
                                </ContentBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
            <ModalBlock
                show={modalVisible}
                onHide={() => setModalVisible(false)}
                contentHeader="Delete Product"
                closeAction
                centered
                contentDescription="Are you sure you want to remove this product?"

            >
                <CustomBlock className="content-container--actions mt-0">
                    <Button
                        text="Cancel"
                        className="grey--bg"
                        onClick={() => setModalVisible(false)}
                    />
                    <Button
                        type="submit"
                        text="Delete"
                        className="danger--bg"
                        onClick={() => {
                            setIsLoading(true)
                            setModalVisible(false)
                            deleteProduct(selectedProduct.id)
                        }}
                    />
                </CustomBlock>
            </ModalBlock>
        </>
    )
}

export default ProductListingPage;