// DEPENDENCIES
import React from 'react';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const CheckboxBlock = (props) => {

    return (
        <>
            <CustomBlock className="checkbox-block-container">
                <input
                    {...props}
                    className="checkbox-input"
                    type="checkbox"
                />

                <label
                    {...props}
                    className={`checkbox-label ${props.labelClassName ? props.labelClassName : ""}`}
                    for={props.id}
                >
                    {props.label}
                </label>
            </CustomBlock>
        </>
    );
}

export default CheckboxBlock;