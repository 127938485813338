// DEPENDENCIES
import React from 'react';
// COMPONENTS   
import { Fade } from 'react-awesome-reveal';
import {useHistory} from 'react-router-dom';
// ICONS
import { AiOutlineLoading } from 'react-icons/ai'
// CUSTOM COMPONENTS
import CustomBlock from '../components/CustomBlock';
import LogoBlock from './LogoBlock';
import Button from './Button';


const LoadingScreen = (props) => {

    const history = useHistory();

    return (
        <>
            {
                props.isComingSoon ?
                    <CustomBlock className="loading-screen-container">
                        <Fade>
                            <LogoBlock
                                height={360}
                            />
                            <h2 className="coming-soon-title">Page Coming Soon</h2>
                            {/* <Button 
                                text="Back to Previous Page"
                                className="secondary--bg"
                                size="xs"
                                onClick={() => history.goBack()}

                            /> */}
                        </Fade>
                    </CustomBlock>
                    :
                    <CustomBlock className="loading-screen-container">
                        <Fade>
                            <LogoBlock
                                height={300}
                            />
                            <AiOutlineLoading className="loading-indicator rotating secondary--clr" size={45} />
                        </Fade>
                    </CustomBlock>
            }
        </>
    );
}

export default LoadingScreen;