// DEPENDECIES
import React, { useState } from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const RadioButton = (props) => {

    const [radioButtonHoverColor, setRadioHoverButtonColor] = useState('light-grey--bg')

    return (
        <>
            <Link
                onClick={props.onClick ? props.onClick : () => {}}
                onMouseEnter={() => setRadioHoverButtonColor('selected')}
                onMouseLeave={() => setRadioHoverButtonColor('light-grey--bg')}
                className="radio-button-container"
            >
                <CustomBlock className={`radio-button ${props.selected ? "selected" : ""} ${radioButtonHoverColor}`}>
                    {
                        props.selected &&
                        <CustomBlock className="selected-indicator" />
                    }
                </CustomBlock>
                <CustomBlock className="label">
                    <p>{props.label}</p>
                </CustomBlock>
            </Link>
        </>
    );
}

export default RadioButton;