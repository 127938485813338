import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Products/";

const getAllProducts = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetAllProducts', constants.REQUIRE_INTERCEPTORS);

const getAll = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetAll', constants.REQUIRE_INTERCEPTORS);

const getProductById = async (id) => await axiosInstance.get(ENDPOINTORIGIN +'GetProductById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const saveProduct = async (product) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProduct', product, constants.REQUIRE_INTERCEPTORS);

const deleteProduct = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeleteProduct?id='+ id, constants.REQUIRE_INTERCEPTORS)

const savePricingBlock = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SavePricingBlock', model, constants.REQUIRE_INTERCEPTORS);

const deletePricingBlock = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeletePricingBlock?id='+ id, constants.REQUIRE_INTERCEPTORS)

const saveProductBlockSize = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveProductBlockSize', model, constants.REQUIRE_INTERCEPTORS);

const deleteProductBlockSize = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeleteProductBlockSize?id='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    getAllProducts,
    getAll,
    getProductById,
    saveProduct,
    deleteProduct,
    savePricingBlock,
    deletePricingBlock,
    saveProductBlockSize,
    deleteProductBlockSize
}