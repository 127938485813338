import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = "Frame/";

const getFrames = async () => await axiosInstance.get(ENDPOINTORIGIN +'GetFrames', constants.REQUIRE_INTERCEPTORS);

const getFrameById = async (id) => await axiosInstance.get(ENDPOINTORIGIN +'GetFrameById?id=' + id, constants.REQUIRE_INTERCEPTORS);

const saveFrame = async (model) => await axiosInstance.post(ENDPOINTORIGIN + 'SaveFrame', model, constants.REQUIRE_INTERCEPTORS);

const deleteFrame = async (id) => await axiosInstance.delete(ENDPOINTORIGIN + 'DeleteFrame?id='+ id, constants.REQUIRE_INTERCEPTORS)

export {
    getFrames,
    getFrameById,
    saveFrame,
    deleteFrame
}