// DEPENDENCIES
import React from 'react';
import { useSpring, animated } from 'react-spring';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
// COMPONENTS
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
// ICONS
import { AiOutlineLoading, AiOutlineBarcode } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';
import { CgClose } from 'react-icons/cg';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const InputBlock = (props) => {
    const errorMessageSuffix = "is Required";
    const inputStates = {
        error: "error",
        warning: "warning",
        success: "success",
        disabled: "disabled"
    }

    return (
        <>
            <CustomBlock className={`input-block-container ${props.containerClassName ? props.containerClassName : ""}`}>
                {
                    props.inputLabel ?
                        <span className={`static-floating-label ${props.inputState}`}>
                            {props.inputLabel}
                        </span> : <></>
                }
                <CustomBlock>
                    <CustomBlock className="icon-left-container" style={{ marginTop: props.inputLabel ? 25 : 10 }}>
                        <Link
                            tabIndex="-1"
                            onClick={props.iconLeftOnClick ? props.iconLeftOnClick : () => { }}
                        >
                            {props.iconLeft}
                        </Link>
                    </CustomBlock>

                    {
                        props.type == 'date' ?
                            <Flatpickr
                                {...props}
                                className={`input-block ${props.className ? props.className : ""} ${props.backgroundColor ? props.backgroundColor : "light--bg"} ${props.inputState ? inputStates[props.inputState] : ""} ${props.bordered ? "bordered" : ""} ${!props.inputLabel ? "padded" : ""}`}
                                type={props.type ? props.type : "text"}
                                autoFocus={props.autoFocus ? props.autoFocus : false}
                                disabled={props.disabled || props.inputState === "disabled" ? true : false}
                            /> :
                            <InputMask
                                {...props}
                                className={`input-block ${props.className ? props.className : ""} ${props.backgroundColor ? props.backgroundColor : "light--bg"} ${props.inputState ? inputStates[props.inputState] : ""} ${props.bordered ? "bordered" : ""} ${!props.inputLabel ? "padded" : ""}`}
                                type={props.type ? props.type : "text"}
                                autoFocus={props.autoFocus ? props.autoFocus : false}
                                disabled={props.disabled || props.inputState === "disabled" ? true : false}
                                style={{ paddingLeft: props.iconLeft ? 40 : 15 }}
                            />
                    }

                    <CustomBlock className="icon-right-container" style={{ marginTop: props.inputLabel ? 25 : 10 }}>
                        {
                            props.loading ?
                                <AiOutlineLoading
                                    tabIndex="-1"
                                    className="loading-indicator rotating success--clr"
                                    size={20}
                                />
                                :
                                <Link
                                    tabIndex="-1"
                                    to={props.to ? props.to : "#"}
                                    onClick={props.iconRightOnClick ? props.iconRightOnClick : () => { }}
                                >
                                    {props.iconRight}
                                </Link>
                        }
                        {
                            props.clearAction ?
                                <Link
                                    tabIndex="-1"
                                    to={props.to ? props.to : "#"}
                                    onClick={props.clearActionOnClick ? props.clearActionOnClick : () => { }}
                                >
                                    <CgClose className="mid-grey--clr" size={20} />
                                </Link> : <></>
                        }

                    </CustomBlock>
                </CustomBlock>
                {
                    props.inputState === 'error' ?
                        <span className={`input-state-text error-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.errorMessage ? props.errorMessage : props.inputLabel ? (props.inputLabel + " " + errorMessageSuffix) : "Error message undefined"}
                        </span> : <></>
                }
                {
                    props.inputState === 'warning' ?
                        <span className={`input-state-text warning-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.warningMessage}
                        </span> : <></>
                }
                {
                    props.inputState === 'success' ?
                        <span className={`input-state-text success-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.successMessage}
                        </span> : <></>
                }
            </CustomBlock>
        </>
    )
}

export default InputBlock;