import * as Yup from "yup";

export default Yup.object().shape({
    name: Yup.string()
        .required("Please enter a product name"),
    description: Yup.string()
        .nullable(),
    isAvailable: Yup.bool()
        .required("Is this product available?"),
    frameId: Yup.number().nullable(),
    productMatColors: Yup.array().nullable()    
});