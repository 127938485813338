// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from "react-redux";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// COMPONENTS
import { Link } from 'react-router-dom';
// ICONS
import { IoSearchSharp, IoClose } from 'react-icons/io5';
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import CustomBlock from '../../components/CustomBlock';
import TextHeader from '../../components/TextHeader';
import CardBlock from '../../components/CardBlock';
import InputBlock from '../../components/InputBlock';
import SelectBlock from '../../components/SelectBlock';
import Button from '../../components/Button';
import ColorDot from '../../components/ColorDot';
import ListContainer from '../../components/ListContainer';
import ListItem from '../../components/ListItem';
// SERVICES
import * as productService from '../../../services/product/productService'

import constants from '../../../constants/constants';


const ProductsPage = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [previewImageIndex, setPreviewImageIndex] = useState(-1);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const body = document.querySelector('body');
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const frameColors = [
        { id: 0, color: 'https://i2.wp.com/boingboing.net/wp-content/uploads/2020/06/IMG_20200602_082003_707.jpg?fit=1080%2C1080&ssl=1' },
        { id: 1, color: 'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/b6bdc544-9c2b-42dc-9e29-5e9508472b63/d5idnkk-885e95e6-550d-426d-8755-a91c974444e0.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2I2YmRjNTQ0LTljMmItNDJkYy05ZTI5LTVlOTUwODQ3MmI2M1wvZDVpZG5ray04ODVlOTVlNi01NTBkLTQyNmQtODc1NS1hOTFjOTc0NDQ0ZTAuanBnIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.Q-wDn02leAyiysuj4Yn3k_iWFfkJ2AbNuYhk1s3gTng' },
        { id: 2, color: 'https://images.fineartamerica.com/images/artworkimages/mediumlarge/1/1-white-square-valeriy-mavlo.jpg' },
        { id: 3, color: 'https://i.pinimg.com/originals/2c/5b/4e/2c5b4e43db6c570f39afa3d32dafa720.jpg' },
        { id: 4, color: 'https://img.favpng.com/10/7/13/color-wheel-complementary-colors-color-scheme-png-favpng-bKTeQu9z4rjk6VQjV9mWDPYCm.jpg' },
    ]

    const frameTypes = [
        { id: 1, type: 'Classic' },
        { id: 2, type: 'Clean' },
        { id: 3, type: 'Canvas' },
    ]

    const colors = [
        { value: 'White', label: 'White' },
        { value: 'Black', label: 'Black' },
        { value: 'Brown', label: 'Brown' },
        { value: 'Multicolor', label: 'Multicolor' },
      ]

    const { isAuthorized, isAdmin } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user ? true : false,
            isAdmin: auth.isAdmin
        }),
        shallowEqual
    );

    useEffect(() => {
        if (isLoading) {
            disableBodyScroll(body);
        } else {
            enableBodyScroll(body);
        }
        getAllProducts();
    }, [])

    useEffect(() => {
        if (searchTerm) {
            setFilteredProducts(products.filter(x => x.name.toLowerCase().includes(searchTerm.toLowerCase())));
        } else {
            setFilteredProducts(products);
        }
    }, [searchTerm])

    const checkAllProductsImage = (prds) => {
        prds.map(product => {
            if (product.productImages.length < 2) {
                product.productImages.push(
                    { id: 1, filePath: "/ProductImages/demoImage1.jpeg", isSampleImage: false },
                    { id: 2, filePath: "/ProductImages/demoImage2.jpeg", isSampleImage: true })
            }
        })
    }

    const getAllProducts = () => {
        setIsLoading(true);
        productService.getAll().then(res => {
            setProducts(res);
            setFilteredProducts(res);
            checkAllProductsImage(res)
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })
    }

    const selectOption = (index) => {
        if (selectedOptionIndex === -1) {
            setSelectedOptionIndex(index)
        } else {
            setSelectedOptionIndex(-1)
        }
    }

    const getPreviewImage = (index, product) => {
        let root = constants.API_URL + 'Attachments';
        let previewImages = product.productImages.sort((a, b) => b.isPreview - a.isPreview);

        if (index === previewImageIndex || previewImages.length === 1) {
            return root + previewImages[0].filePath
        }

        return root + previewImages[1].filePath
    }

    return (
        <selectedOptionIndex>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded with-sidebar light--bg">
                        <CustomBlock className="sidebar-container">
                            <CustomBlock className="sidebar">
                                <TextHeader
                                    title="Frames"
                                />

                                <CustomBlock>
                                    <InputBlock
                                        placeholder="Search Frames"
                                        backgroundColor="white--bg"
                                        clearAction
                                        iconLeft={
                                            <IoSearchSharp size={20} className="mid-grey--clr" />
                                        }
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        clearActionOnClick={() => {
                                            setSearchTerm('');
                                        }}
                                    />
                                </CustomBlock>

                                <CustomBlock className="color-selector-container">
                                    <TextHeader
                                        title="Frame Color"
                                        size="md"
                                    />
                                    <SelectBlock
                                        containerClassName="ml-0 mr-0"
                                        backgroundColor="white--bg"
                                        options={colors}
                                    />
                                    {/* {
                                        frameColors.map((frameColor, index) =>
                                            <ColorDot
                                                color={frameColor.color}
                                                onClick={() => {
                                                    setSelectedOptionIndex(index === selectedOptionIndex ? -1 : index);
                                                }}
                                                selected={index === selectedOptionIndex}
                                            />
                                        )
                                    } */}
                                </CustomBlock>
                            </CustomBlock>
                        </CustomBlock>
                        <CustomBlock className="sidebar-pair-container">
                            <CustomBlock>
                                <CustomBlock className="card-block-container--flex-start">
                                    {
                                        filteredProducts.filter(x => x.productImages).map((product, index) =>
                                            <>
                                                <CardBlock
                                                    size="small"
                                                    to={"/product/details/" + products[index].id}
                                                    title={product.name}
                                                    additionalText={'$' + product.startingPrice}
                                                    imageSource={getPreviewImage(index, product)}
                                                    onMouseEnter={() => setPreviewImageIndex(index)}
                                                    onMouseLeave={() => setPreviewImageIndex(-1)}
                                                />
                                            </>
                                        )
                                    }
                                </CustomBlock>
                            </CustomBlock>
                        </CustomBlock>
                    </CustomBlock>
            }
        </selectedOptionIndex>
    );
}

export default ProductsPage;