import { yupToFormErrors } from "formik";
import * as Yup from "yup";

export default Yup.object().shape({
    emailAddress: Yup.string()
    .email()
    .required("Please enter a valid email"),
    password: Yup.string().required("Please enter your password")
    .min(8, "Minimum 8 characters"),
    confirmPassword: Yup.string().required("Please confirm your password")
    .min(8, "Minimum 8 characters").oneOf([Yup.ref('password'), null], 'Passwords must match')
});