// DEPENDENCIES
import React from 'react';
import { useSpring, animated } from 'react-spring';
// COMPONENTS
import Select from 'react-select';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const SelectBlock = (props) => {

    const errorMessageSuffix = "is Required";
    const inputStates = {
        error: "error",
        warning: "warning",
        success: "success",
        disabled: "disabled"
    }

    return (
        <>
            <CustomBlock className={`input-block-container ${props.containerClassName ? props.containerClassName : ""}`}>
                {
                    props.inputLabel ?
                        <span className={`static-floating-label ${props.inputState}`}>
                            {props.inputLabel}
                        </span> : <></>
                }
                <Select
                    {...props}
                    placeholder={props.placeholder ? props.placeholder : (props.multiSelect ? "Select options" : "Select option")}
                    classNamePrefix={`${props.classNamePrefix ? props.classNamePrefix : ""} ${props.backgroundColor ? props.backgroundColor : "light--bg"} ${props.inputState ? inputStates[props.inputState] : ""} ${props.bordered ? "bordered" : ""} ${!props.inputLabel ? "padded" : ""}`}
                    isDisabled={props.disabled || props.inputState === "disabled" ? true : false}
                />
                {
                    props.inputState === 'error' ?
                        <span className={`input-state-text error-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.errorMessage ? props.errorMessage : props.inputLabel ? (props.inputLabel  + " " + errorMessageSuffix) : "Error message undefined"}
                        </span> : <></>
                }
                {
                    props.inputState === 'warning' ?
                        <span className={`input-state-text warning-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.warningMessage}
                        </span> : <></>
                }
                {
                    props.inputState === 'success' ?
                        <span className={`input-state-text success-message ${!props.inputLabel ? "unpadded" : ""}`}>
                            {props.successMessage}
                        </span> : <></>
                }
            </CustomBlock>
        </>
    )
}

export default SelectBlock;