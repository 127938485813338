// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
// COMPONENTS
import MUIDataTable from "mui-datatables";
// ICONS
// CUSTOM COMPONENTS
import LoadingScreen from '../../components/LoadingScreen';
import Section from '../../components/Section';
import ContentBlock from '../../components/ContentBlock';
import ContentHeader from '../../components/ContentHeader';
import Button from '../../components/Button';
import CustomBlock from '../../components/CustomBlock';
//SERVICES AND HELPERS
import * as orderService from '../../../services/ecommerce/orderService';

const MyOrdersPage = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([])
    const body = document.querySelector('body');

    useEffect(() => {
        if (isLoading) {
            disableBodyScroll(body);
        } else {
            enableBodyScroll(body);
        }
        fetchOrders();
    }, [])

    const fetchOrders = () => {
        orderService.getOrders().then(res => {
            if (res)
                setOrders(res.orders);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const columns = [
        {
            name: 'orderId',
            label: 'Action',
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            <Button
                                className="light-purple--bg fit-content ml-0"
                                text="View"
                                size="xs"
                                to={"/my-order/detail/" + orders[dataIndex].orderId}
                            />
                        </CustomBlock>

                    );
                }
            }
        },
        {
            name: "orderId",
            label: "Order #",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "orderPlaced",
            label: "Order Placed Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "noOfItems",
            label: "No. Of Items",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "totalAmountPaid",
            label: "Order Total",
            options: {
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <p>$ {orders[dataIndex].totalAmountPaid}</p>
                    )
                }
            }
        },
        {
            name: "trackingNumber",
            label: "TrackingNumber",
            options: {
                filter: true,
                sort: true
            }
        },
    ];

    return (
        <>
            {
                isLoading ? <LoadingScreen /> :
                    <CustomBlock className="content-container--padded light--bg">
                        <Section>
                            <CustomBlock className="content-container--card-style">
                                <ContentHeader
                                    title="Orders"
                                    headerSize="lg"
                                />
                                <ContentBlock>
                                    <MUIDataTable
                                        data={orders}
                                        columns={columns}
                                        options={{
                                            selectableRows: "none"
                                        }}
                                    />
                                </ContentBlock>
                            </CustomBlock>
                        </Section>
                    </CustomBlock>
            }
        </>
    );
}

export default MyOrdersPage;