// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
// COMPONENTS
import MUIDataTable from "mui-datatables";
import { useFormik } from "formik";
// ICONS
import { HiCheck } from 'react-icons/hi';
import { CgClose } from 'react-icons/cg';
// CUSTOM COMPONENTS
import MagnificentToast from '../../../../components/MagnificentToast';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ModalBlock from '../../../../components/ModalBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import Button from '../../../../components/Button';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import FormBlock from '../../../../components/FormBlock';
// SERVICES AND HELPERS
import PromoCodeValidator from '../../../../../helpers/validators/PromoCodeValidator';
import * as promoCodeService from '../../../../../services/product/promoCodeService';
import * as helper from '../../../../../helpers/helper';

const initialValues = {
    id: 0,
    code: '',
    discount: '',
    isActive: false
}

const PromoCodesPage = (props) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [promoCode, setPromoCode] = useState(initialValues);
    const [promoCodes, setPromoCodes] = useState([]);
    const [idToDelete, setIdToDelete] = useState(null);

    useEffect(() => {
        fetchPromoCodes();
    }, [])

    const fetchPromoCodes = () => {
        promoCodeService.getPromoCodes().then(res => {
            setPromoCodes(res);
        })
    }

    const showToast = () => {
        setTimeout(() => {
            setToastVisible(true);
        }, 250)
    }

    const hideToast = () => {
        setTimeout(() => {
            setToastVisible(false);
        }, 3000)
    }

    const toast = () => {
        showToast();
        hideToast();
    }

    const handleDelete = () => {
        promoCodeService.deletePromoCode(idToDelete).then(res => {
            fetchPromoCodes();
            swal("Success!", res.message, "success");
        }).catch(ex => {
            swal("Error!", ex.message, "error");
        }).finally(() => {
            closeConfirmModal();
        });
    }

    const closeConfirmModal = () => {
        setConfirmDeleteModalVisible(false);
        setIdToDelete(null)
    }

    const columns = [
        {
            name: "id",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <CustomBlock className="content-container--actions pl-0 mt-0" style={{ justifyContent: "flex-start" }}>
                            {/* <Button
                                text="Copy Code"
                                className="success--bg fit-content ml-0"
                                size="xs"
                                onClick={() => {
                                    var input = document.createElement("textarea");
                                    input.value = promoCodes[dataIndex].code;
                                    document.body.appendChild(input);
                                    input.select();
                                    document.execCommand("Copy");
                                    toast();
                                }}
                            /> */}

                            <Button
                                text="Edit"
                                className="light-purple--bg fit-content"
                                size="xs"
                                onClick={() => {
                                    fetchPromoCode(promoCodes[dataIndex].id)
                                }}
                            />

                            <Button
                                text="Delete"
                                className="danger--bg"
                                size="xs"
                                onClick={() => {
                                    setIdToDelete(promoCodes[dataIndex].id)
                                    setConfirmDeleteModalVisible(true);
                                }}
                            />
                        </CustomBlock>
                    )
                }
            }

        },
        {
            name: "code",
            label: "Promo Code",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false
            }
        },
        {
            name: "discount",
            label: "Discount %",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (<p>{promoCodes[dataIndex].discount}% Off</p>)
                }
            }
        }, {
            name: "isActive",
            label: "Is Active?",
            options: {
                filter: true,
                sort: true,
                print: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <>
                            {promoCodes[dataIndex].isActive ? <HiCheck size={25} className="success--clr" /> : <CgClose size={25} className="danger--clr" />}
                        </>
                    );
                }
            }
        }
    ];

    const fetchPromoCode = (id) => {
        promoCodeService.getPromoCodeById(id).then(res => {
            setPromoCode(res);
            setModalVisible(true);
        });
    }

    const formik = useFormik({
        initialValues: promoCode,
        enableReinitialize: true,
        validationSchema: PromoCodeValidator,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            promoCodeService.savePromoCode(values).then(res => {
                fetchPromoCodes();
                swal("Success!", res.message, "success");
            }).catch(ex => {
                swal("Error!", ex.message, "error");
            }).finally(() => {
                handleModalClose();
            })
        },
    });

    const handleModalClose = () => {
        setModalVisible(false);
        setPromoCode(initialValues);
        formik.resetForm();
    }

    return (
        <>
            <MagnificentToast
                visible={toastVisible}
                hasHeader
                text="Promo code copied!"
                state="success"
            // dismissable
            // onDismiss={() => hideToast()}
            />
            <CustomBlock className="content-container--padded light--bg">
                <Section>
                    <CustomBlock className="content-container--card-style">
                        <ContentHeader
                            title="Promo Codes"
                            headerSize="lg"
                            primaryButtonText="Add New"
                            primaryButtonOnClick={() => setModalVisible(true)}
                        />
                        <CustomBlock>
                            <MUIDataTable
                                data={promoCodes}
                                columns={columns}
                                options={{
                                    selectableRows: "none",
                                    download: false,
                                    print: false,
                                }}
                            />
                        </CustomBlock>
                    </CustomBlock>
                </Section>
            </CustomBlock>

            {/* CONFIRM DELETE MODAL */}
            <ModalBlock
                show={confirmDeleteModalVisible}
                contentHeader="Delete Promo Code"
                onHide={closeConfirmModal}
                closeAction
                centered
                contentDescription="Are you sure you want to delete this promo code?"
                primaryModalActionOnClick={() => handleDelete()}
            />

            {/* CREATE NEW ITEM MODAL */}
            <ModalBlock
                show={modalVisible}
                contentHeader="Add Promo Code"
                onHide={handleModalClose}
                backdrop="static"
                size="md"
                closeAction
                centered
            >
                <FormBlock className="test form" onSubmit={formik.handleSubmit}>
                    <Section removeContainer>
                        <ContentBlock>
                            <InputBlock
                                inputLabel="Promo Code"
                                errorMessage={formik.errors.code}
                                inputState={`${helper.getInputClasses(formik, 'code')}`}
                                {...formik.getFieldProps("code")}
                            />
                        </ContentBlock>
                        <ContentBlock>
                            <InputBlock
                                inputLabel="Discount %"
                                type="number"
                                errorMessage={formik.errors.discount}
                                inputState={`${helper.getInputClasses(formik, 'discount')}`}
                                {...formik.getFieldProps("discount")}
                            />
                        </ContentBlock>
                        <ContentBlock>
                            <CheckboxBlock
                                id="isActive"
                                label="Is Active?"
                                inputState={`${helper.getInputClasses(formik, 'isActive')}`}
                                {...formik.getFieldProps("isActive")}
                                checked={formik.values.isActive}
                            />
                        </ContentBlock>
                        <CustomBlock className="content-container--actions">
                            <Button
                                text="Cancel"
                                className="danger--bg"
                                onClick={handleModalClose}
                            />

                            <Button
                                type="submit"
                                text="Add"
                                className="primary--bg"
                            />
                        </CustomBlock>
                    </Section>
                </FormBlock>
            </ModalBlock>
        </>
    );
}

export default PromoCodesPage;
