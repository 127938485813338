// DEPENDENCIES
import React, { useEffect, useState } from 'react';
// COMPONENTS
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
// ICONS
import { IoClose } from 'react-icons/io5';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image'; 
// SERVICES AND HELPERS

const MediaBlock = (props) => {

    const [media, setMedia] = useState(props.source);
    const [videoUrl, setVideoUrl] = useState(props.videoUrl);
    
    return (
        <CustomBlock className={`media-block-container ${props.containerBackground ? props.containerBackground : "light--bg"} ${props.bordered ? "bordered" : ""} ${props.source || props.videoUrl ? "" : "no-media"}`}>
            {
                props.source || props.videoUrl ?
                    <>
                        {
                            props.onClick &&
                            <CustomBlock className="action-icon-container">
                                <Link
                                    onClick={props.onClick ? props.onClick : {}}
                                    className={`icon ${props.iconBackground ? props.iconBackground : "danger--bg"}`}
                                >
                                    {
                                        props.icon ? props.icon :
                                            <IoClose size={20} className={`white--clr ${props.closeActionBackground ? props.closeActionBackground : "lighter-red--clr"}`} />
                                    }
                                </Link>
                            </CustomBlock>
                        }
                        <CustomBlock className="content-block">
                            {
                                props.videoUrl ?
                                    <ReactPlayer
                                        {...props}
                                        // url={videoUrl ? (videoUrl.includes('http') ? videoUrl : `${constants.STORAGE_URL}${videoUrl}`) : null}
                                        url={props.videoUrl}
                                        controls
                                        width="100%"
                                        className="content-video"
                                    />
                                    :
                                    // <Image source={media && (constants.API_URL + 'Attachments' + ((typeof media) == 'string' ? media : media.imagePath))} roundCorners />
                                    <Image source={props.source} roundCorners />
                            }
                        </CustomBlock>
                    </>
                    :
                    <h6 className="no-media-tag">No Media Available</h6>
            }
        </CustomBlock>
    );
}

export default MediaBlock;
