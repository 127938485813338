// DEPENDENCIES
import React from 'react';
// COMPONENTS
import { Link } from 'react-router-dom';
// CUSTOM COMPONENTS

const Image = (props) => {

  return (
    <>
      {
        props.to || props.onClick ?
          <Link
            {...props}
            to={props.to ? props.to : "#"}
          // onClick={props.onClick ? props.onClick : () => {}}
          >
            <img
              className={`image${props.roundCorners ? "--rounded" : ""} ${props.className ? props.className : ""}`}
              src={props.source}
            />
          </Link>
          :
          <img
            className={`image${props.roundCorners ? "--rounded" : ""} ${props.className ? props.className : ""}`}
            src={props.source}
          />
      }
    </>
  );
}

export default Image;