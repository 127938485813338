// DEPENDENCIES
import React from 'react';
// COMPONENTS
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
// ICONS
import { IoClose } from 'react-icons/io5'
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import ContentHeader from './ContentHeader';
import Button from './Button';
// HELPERS

const ModalBlock = (props) => {

    return (
        <>
            <Modal
                {...props}
                show={props.show ? props.show : false}
                onHide={props.onHide ? props.onHide : () => { }}
            >
                {
                    props.closeAction &&
                    <CustomBlock className="close-action-container">
                        <Link
                            to={props.to ? props.to : "#"}
                            className="close-action"
                            onClick={props.closeActionOnClick ? props.closeActionOnClick : props.onHide}
                        >
                            <IoClose size={30} className={`${props.closeIconColor ? props.closeIconColor : "primary--clr"}`} />
                        </Link>
                    </CustomBlock>
                }

                <CustomBlock className={`content-container--card-style content mt-0 ${props.closeAction ? "pt-0" : ""}`}>
                    <ContentHeader
                        {...props}
                        title={props.contentHeader}
                        subtitle={props.subtitle}
                        primaryButtonText={props.primaryButtonText}
                        headerSize="mdlg"
                    />

                    {
                        props.contentDescription &&
                        <CustomBlock className={`content-block ml-2 mb-5 ${props.centeredTitle ? "text-center" : ""}`}>
                            <p>
                                {props.contentDescription}
                            </p>
                        </CustomBlock>
                    }
                    
                    {props.children}

                    {
                        props.primaryModalActionOnClick &&
                        <CustomBlock className="content-container--actions">
                            <Button
                                type={props.secondaryModalActionType}
                                text={props.secondaryModalActionText ? props.secondaryModalActionText : "Cancel"}
                                className={props.secondaryModalActionColor ? props.secondaryModalActionColor : "grey--bg"}
                                onClick={props.secondaryModalActionOnClick ? props.secondaryModalActionOnClick : props.onHide}
                            />

                            <Button
                                type={props.primaryModalActionType}
                                text={props.primaryModalActionText ? props.primaryModalActionText : "Delete"}
                                className={props.primaryModalActionColor ? props.primaryModalActionColor : "danger--bg"}
                                onClick={props.primaryModalActionOnClick ? props.primaryModalActionOnClick : {}}
                            />
                        </CustomBlock>
                    }
                </CustomBlock>
            </Modal>
        </>
    );
}

export default ModalBlock;